import { LoginMutation_login_preferences } from "graphql/generated";


type DefaultUserPreferences = {
  [key in keyof Omit<LoginMutation_login_preferences, "__typename">]-?: NonNullable<LoginMutation_login_preferences[key]>;
};


export const USER_PREFERENCE_DEFAULTS: DefaultUserPreferences = {
  ui__dark_mode: false,
  ui__locale: "en",
}
