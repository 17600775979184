import React, { FunctionComponent, useContext, SVGAttributes } from "react";
import { extent } from "d3";

import { ChartContext, makeScale } from "..";
import { ShapeBase } from "../base"
import { IDataProps, IPlotData, SvgShape } from "../types"


interface IProps extends IDataProps, SVGAttributes<SVGElement> {
  data: IPlotData[];
  shape?: SvgShape;
}


const Points: FunctionComponent<IProps> = ({
  data,
  domainX = extent<number>(data.map(d => d.x as number)) as number[],
  domainY = extent<number>(data.map(d => d.y as number)) as number[],
  rangeX,
  rangeY,
  offsetX,
  offsetY,
  shape = "circle",
  ...rest
}) => {
  const context = useContext(ChartContext);
  const scaleX = makeScale("x", context, domainX, rangeX, offsetX);
  const scaleY = makeScale("y", context, domainY, rangeY, offsetY);

  return (
    <>
      <ShapeBase
        className = "points"
        {...{
          shape,
          data,
          scaleX,
          scaleY,
        }}
        {...rest}
      />
    </>
  )
}

export default Points;
