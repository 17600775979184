import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";


interface IProps {
  data: any;
  baseUrl: string;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const PvCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__pv____title",
        defaultMessage: `{count, plural,
          one {Photovoltaics}
          other {Photovoltaics}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/fotovoltaicka-elektrarna.svg"}
      onClick={() => {
        navigate(`${baseUrl}/pv${config.id ? `/${config.id}` : ''}`);
      }}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__pv__volt",
                defaultMessage: "Voltage",
              }),
              unit: "V",
              decimals: 2,
              value: data?.voltage,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__pv__amp",
                defaultMessage: "Current",
              }),
              unit: "A",
              decimals: 2,
              value: data?.current,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__pv__kw",
                defaultMessage: "Active power",
              }),
              unit: "kW",
              decimals: 1,
              value: data?.active_power,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__pv__throttled_to",
                defaultMessage: "Throttled to",
              }),
              unit: "%",
              decimals: 1,
              value: data?.throttled_to,
            },
          ]}
        />
    </BaseCard>
  );
};

export default PvCard;
