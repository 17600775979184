import React, { FunctionComponent } from "react";
import { StyleProps } from "@chakra-ui/react";


interface IProps extends StyleProps {
  name: string;
  onClick?: () => void;
}


const SitePreview: FunctionComponent<IProps> = ({
  name,
  onClick,
}) => {
  return (
    <li
      className="honeycomb-cell"
      onClick={ onClick }
    >
      <div className="honeycomb-cell__title">{ name }</div>
    </li>
  );
};

export default SitePreview;
