import React, { FunctionComponent } from "react";
import { Flex } from "@chakra-ui/react"

import Navbar from "./Navbar";

const Layout: FunctionComponent<{}> = ({ children }) => {
  return (
    <Flex flexDirection={"column"} flexGrow={1}>
      <Navbar />
      <Flex flexDirection={"column"} flexGrow={1} p={8}>{children}</Flex>
    </Flex>
  );
};

export default Layout;
