import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { HStack, Image } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_hvac } from "graphql/generated";

interface IProps {
  // data: DashboardMeasurement_dashboardMeasurement_hvac | undefined;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const HVACCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__hvac____title",
        defaultMessage: `{count, plural,
          one {HVAC}
          other {HVACs}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
    >
      <HStack spacing={8} w="100%">
        <Image src="/images/svg/klimatizace-vypnuta.svg" w="120px" ml={4} />

        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__hvac__current_temp",
                defaultMessage: "Temperature",
              }),
              unit: "°C",
              decimals: 2,
              // type: "number",
              value: data?.current_temp,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__hvac__current_humidity",
                defaultMessage: "Humidity",
              }),
              unit: "g/kg",
              decimals: 2,
              // type: "number",
              value: data?.current_humidity,
            },
          ]}
        />
      </HStack>
    </BaseCard>
  );
};

export default HVACCard;
