import React, { FunctionComponent, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Flex,
  Heading,
  HStack,
} from "@chakra-ui/react";

import { envVars } from "common/env";
import { useToasts } from "common/toasts";
import { useMutation } from "@apollo/client";

import {
  SiteConfig_siteConfig_exportConf, ExportDataMutation, ExportDataMutationVariables,
} from "graphql/generated";
import {
  EXPORT_DATA_MUTATION,
} from "graphql/mutations";

import TimePeriodButton from "./TimePeriodButton";
import ResolutionButton from "./ResolutionButton";
import SubjectButton from "./SubjectButton";
import FormatButton from "./FormatButton";


interface IProps {
  siteId: string;
  exportConf: SiteConfig_siteConfig_exportConf;
}

export interface ExportSubjects {
  pv: boolean;
  grid: boolean;
}


const timePeriods = [
  "this week",
  "this month",
  "this quarter",
  "this year",
  "previous week",
  "previous month",
  "previous quarter",
  "previous year",
] as const;
type ExportTimePeriod = typeof timePeriods[number];

const timeResolutions = [
  "minutes",
  "15minutes",
  "hours",
  "days",
  "weeks",
  "months",
] as const;
type ExportTimeResolution = typeof timeResolutions[number];

const exportFormats = [
  "csv",
  "xls",
] as const;
type ExportFormat = typeof exportFormats[number];

const possiblePeriodResolutions: {[key: string]: ExportTimeResolution[]} = {
  "week": ["hours", "15minutes", "minutes"],
  "month": ["days", "hours", "15minutes"],
  "quarter": ["weeks", "days"],
  "year": ["months", "weeks"],
}


const Export: FunctionComponent<IProps> = ({
  siteId,
  exportConf,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const [ activeSubjects, setActiveSubjects ] = useState<boolean[]>(exportConf.exports.map(_ => false));
  const [ timePeriod, setTimePeriod ] = useState<ExportTimePeriod>("this week");
  const [ timeResolution, setTimeResolution ] = useState<ExportTimeResolution>("hours");
  const [ fileFormat, setFileFormat ] = useState<ExportFormat>("xls");

  const toggleActiveSubject = useCallback((index: number) => {
    const newState = [...activeSubjects];

    newState[index] = !activeSubjects[index];
    setActiveSubjects(newState);
  }, [activeSubjects]);

  const [ exportData, { loading: isExporting }] = useMutation<ExportDataMutation, ExportDataMutationVariables>(EXPORT_DATA_MUTATION);

  const isResolutionPossible = useCallback((resolution: ExportTimeResolution): boolean => {
    const timePeriodLength = timePeriod.split(/ /)[1];

    return possiblePeriodResolutions[timePeriodLength].includes(resolution);
  }, [timePeriod]);

  const isFormatPossible = useCallback((format: ExportFormat): boolean => {
    return format !== "csv" || activeSubjects.filter(_ => _).length <= 1;
  }, [activeSubjects]);

  return (
    <>
      <Heading size="md" mb={3}>
        {intl.formatMessage({
          id: "export__subject___title",
          defaultMessage: "Subject of export",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {exportConf.exports.map((config, i) => {
          return (
            <SubjectButton
              index={i}
              key={`exportSubject-${config.subject}${config.label}`}
              subject={config.subject}
              config={config}
              isActive={activeSubjects[i]}
              isDisabled={config.disabled}
              onClick={() => toggleActiveSubject(i)}
            />
          )
        })}
      </Flex>
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__time_period___title",
          defaultMessage: "Time period",
        })}
      </Heading>
      {[
        timePeriods.filter(_ => _.match("^this")),
        timePeriods.filter(_ => _.match("^previous")),
      ].map((group) => (
        <>
          <Flex flexWrap="wrap">
            {group.map((option, i) => {
              return (
                <TimePeriodButton
                  index={i}
                  key={`timePeriod-${option}`}
                  value={option}
                  isActive={option === timePeriod}
                  isDisabled={false}
                  onClick={() => setTimePeriod(option)}
                />
              )
            })}
          </Flex>
        </>
      ))}
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__resolution___title",
          defaultMessage: "Resolution",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {timeResolutions.map((option, i) => {
          return (
            <ResolutionButton
              index={i}
              key={`timeResolution-${option}`}
              value={option}
              isActive={option === timeResolution && isResolutionPossible(option)}
              isDisabled={!isResolutionPossible(option)}
              onClick={() => setTimeResolution(option)}
            />
          )
        })}
      </Flex>
      <Heading size="md" mb={3} mt={6}>
        {intl.formatMessage({
          id: "export__format___title",
          defaultMessage: "File format",
        })}
      </Heading>
      <Flex flexWrap="wrap">
        {exportFormats.map((option, i) => {
          return (
            <FormatButton
              index={i}
              key={`timeResolution-${option}`}
              value={option}
              isActive={option === fileFormat && isFormatPossible(option)}
              isDisabled={!isFormatPossible(option)}
              onClick={() => setFileFormat(option)}
            />
          )
        })}
      </Flex>
      <HStack mt={6}>
        <Button
          size="md"
          colorScheme="yellow"
          px={10}
          flexGrow={0}
          isLoading={isExporting}
          isDisabled={!(activeSubjects.some(_ => _) && isResolutionPossible(timeResolution) && isFormatPossible(fileFormat))}
          onClick={async (event) => {
            exportData({
              variables: {
                siteId,
                exports: activeSubjects,
                timePeriod: timePeriod,
                timeResolution: timeResolution,
                fileFormat: fileFormat,
              },
            })
              .then((response) => {
                pushSuccessToast(
                  intl.formatMessage({
                    defaultMessage: "Data exported",
                    id: "export___success",
                  })
                );
                const download_url = response.data?.exportData.url;

                if (download_url) window.location.href=`${envVars.DOWNLOAD_URL}${download_url}`;
              })
              .catch((reason) => {
                pushErrorToast(
                  intl.formatMessage({
                    defaultMessage: "Data export failed ({reason})",
                    id: "export___error",
                  }, {
                    reason: String(reason),
                  })
                );
              })
              ;
          }}
        >
          {intl.formatMessage({
            id: "export___export",
            defaultMessage: "Export",
          })}
        </Button>
      </HStack>
    </>
  );
};

export default Export;
