import React, { FunctionComponent, useContext, SVGAttributes } from "react";
import { extent } from "d3";

import { ChartContext, makeScale } from "..";
import { IDataProps, IPlotData } from "../types"
import Label from "../base/Label";


interface IProps extends IDataProps, SVGAttributes<SVGElement> {
  data: IPlotData[];
  radius?: number;
}


const PointLabels: FunctionComponent<IProps> = ({
  data,
  domainX = extent<number>(data.map(d => d.x as number)) as number[],
  domainY = extent<number>(data.map(d => d.y as number)) as number[],
  rangeX,
  rangeY,
  offsetX,
  offsetY,
  ...rest
}) => {
  const context = useContext(ChartContext);
  const scaleX = makeScale("x", context, domainX, rangeX, offsetX);
  const scaleY = makeScale("y", context, domainY, rangeY, offsetY);

  return (
    <>
      <Label
        className = "point-labels"
        {...{
          data,
          scaleX,
          scaleY,
        }}
        {...rest}
      />
    </>
  )
}

export default PointLabels;
