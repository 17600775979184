import React, { FunctionComponent, useMemo } from "react";
import { useMutation } from "@apollo/client";
import omit from "ramda/src/omit";
import { Stack, Box, Button } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useToasts } from "common/toasts";

import MarkdownGuide from "scenes/site/scenes/settings/components/MarkdownGuide";
import { IItem, TValue } from "components/settings-table/types";
import {
  useSettingsTable,
  SettingsTable,
} from "components/settings-table";

import { SET_SETTINGS_MUTATION } from "graphql/mutations";

interface IProps {
  siteId: string;
  guideId: string;
  settingsId: string;
  settings: any;
  isLoading: boolean;
  // saveMutation: DocumentNode;
  items: Array<IItem>;
  uiVersion?: string | null;
}

// // TODO develop this to replace the hack below
// const wipeOutTypenames = (value: TValue): any => {
//   console.log(typeof(value), value)
//   if (typeof(value) === "object") {
//     if (value.length !== undefined) {
//       return value.map(wipeOutTypenames);
//     } else {
//       return omit(["__typename"], value);
//     }
//   }
//   return value;
// }

const SettingsFormPage: FunctionComponent<IProps> = ({
  siteId,
  guideId,
  settingsId,
  settings,
  isLoading,
  items,
  uiVersion,
  children,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const { state, tableProps, renewState } = useSettingsTable(
    items,
    omit(["__typename"], settings)    
  );

  const [setSettings, { loading: isSaving }] = useMutation(SET_SETTINGS_MUTATION);

  const settingsMode = useMemo<string>(() => {
    switch (uiVersion) {
      case "neufe": return "neufe";
      default: return "plc";
    }
  }, [uiVersion])

  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <MarkdownGuide id={guideId} uiVersion={uiVersion} />
      </Box>
      <Box flex={1}>
        <SettingsTable {...tableProps} isDisabled={isSaving} />
        {children}

        <Box display="flex" mt={8}>
          <Box flex={2}></Box>
          <Button
            size="md"
            colorScheme="yellow"
            flex={1}
            isLoading={isLoading || isSaving}
            onClick={() => {
              // TODO quick and dirty hack to be removed
              if (state.consumers) state.consumers = (state.consumers as unknown as TValue[]).map(_ => omit(["__typename"], _)) as TValue[]
              setSettings({
                variables: {
                  siteId,
                  mode: settingsMode,
                  settings: { [settingsId]: state as any },
                },
              })
                .then(() => {
                  pushSuccessToast(
                    intl.formatMessage({
                      defaultMessage: "Successfully saved",
                      id: "site__settings__save_success",
                    })
                  );
                  renewState(state);
                })
                .catch((reason) => {
                  pushErrorToast(
                    intl.formatMessage({
                      defaultMessage: "Saving settings failed ({reason})",
                      id: "site__settings__save_error",
                    }, {
                      reason: String(reason),
                    })
                  );
                });
            }}
          >
            {intl.formatMessage({
              id: "settings___apply",
              defaultMessage: "Apply",
            })}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default SettingsFormPage;
