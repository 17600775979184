import React, { FunctionComponent } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Tooltip, Spinner, Badge, Code } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import NotificationIcon from "./NotificationIcon";

import { SiteNotifications_siteNotifications } from "graphql/generated";

interface IProps {
  items: Array<SiteNotifications_siteNotifications> | undefined;
  onlyActive: boolean;
  timezone: string;
}

const NotificationsTable: FunctionComponent<IProps> = ({ items, onlyActive, timezone }) => {
  const intl = useIntl();
  const formatId = (id: string) => id.slice(0, 8);
  const formatTimestamp = (timestamp: string | null) => {
    if (!timestamp) return "-";
    const utcTime = new Date(Date.parse(`${timestamp}+00:00`));

    return utcTime.toLocaleString(intl.locale, {
      timeZone: timezone,
    });
  }
  const formatConditionId = (id:string) => id.replace(/_/g, ' ');

  return (
    <>
    {items ? (
    <Table variant='striped' size={onlyActive ? 'md' : 'sm'}>
      <Thead>
        <Tr>
          <Th></Th>
          <Th>
            {intl.formatMessage({
              id: "notifications__column__alarm_id",
              defaultMessage: "Alarm Id",
            })}
          </Th>
          <Th>
            {intl.formatMessage({
              id: "notifications__column__condition_id",
              defaultMessage: "Alarm",
            })}
          </Th>
          <Th>
            {intl.formatMessage({
              id: "notifications__column__text",
              defaultMessage: "Text",
            })}
          </Th>
          <Th>
            {intl.formatMessage({
              id: "notifications__column__timestamp_creation",
              defaultMessage: "Creation time",
            })}
          </Th>
          {onlyActive || <Th>
            {intl.formatMessage({
              id: "notifications__column__timestamp_acknowledge",
              defaultMessage: "Acknowledge time",
            })}
          </Th>}
          {onlyActive || <Th>
            {intl.formatMessage({
              id: "notifications__column__timestamp_expiration",
              defaultMessage: "Expiration time",
            })}
          </Th>}
        </Tr>
      </Thead>

      <Tbody>
        {items.map((notification) => {
              return (
                <Tr key={notification.id}>
                  <Td>
                    <NotificationIcon alarmType={notification.alarmType} onlyActive={onlyActive} />
                  </Td>
                  <Td>
                    <Tooltip label={notification.id}>
                      <Code>{formatId(notification.id)}</Code>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Badge variant="outline">{formatConditionId(notification.conditionId)}</Badge>
                  </Td>
                  <Td>{notification.text}</Td>
                  <Td>{formatTimestamp(notification.createTimestamp)}</Td>
                  {onlyActive || <Td>{formatTimestamp(notification.acknowledgeTimestamp)}</Td>}
                  {onlyActive || <Td>{formatTimestamp(notification.expireTimestamp)}</Td>}
                </Tr>
              );
            })
        }
      </Tbody>
    </Table>
    ) : <Spinner size="lg" m="4" />
    }
    </>
  );
};

export default NotificationsTable;
