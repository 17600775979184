import React, { FunctionComponent } from "react";
import { Badge, Skeleton, Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";


interface IProps {
  state?: number;
}

const CHP_SWITCH_STATE = {
  NONE: 0,
  OFF: 1,
  AUTO: 2,
  MANUAL: 3,
}


const CHPSwitchState: FunctionComponent<IProps> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case CHP_SWITCH_STATE.NONE:
      return (
        <Badge variant={"outline"} colorScheme={"gray"} title={intl.formatMessage({
            id: "chp__switch_state__none",
            defaultMessage: "No status",
          })}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    case CHP_SWITCH_STATE.OFF:
      return (
        <Badge variant="solid" colorScheme={"gray"}>
          {intl.formatMessage({
            id: "chp__switch_state__off",
            defaultMessage: "OFF",
          })}
        </Badge>
      )
    case CHP_SWITCH_STATE.MANUAL:
      return (
        <Badge variant="solid" colorScheme={"blue"}>
          {intl.formatMessage({
            id: "chp__switch_state__manual",
            defaultMessage: "MANUAL",
          })}
        </Badge>
      )
    case CHP_SWITCH_STATE.AUTO:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          {intl.formatMessage({
            id: "chp__switch_state__auto",
            defaultMessage: "AUTO",
          })}
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default CHPSwitchState;
