import React, { FunctionComponent, useRef, createContext, DOMAttributes } from "react";
import { CurveFactory, curveBasis, curveMonotoneX } from "d3";

import { IDataStageProps, IDimensions, IChartContext } from "../types";


export const ChartContext = createContext<IChartContext>({
  width: 0,
  height: 0,
  offsetX: [0, 0],
  offsetY: [0, 0],
  rangeX: [0, 0],
  rangeY: [0, 0],
  curve: curveBasis,
});


interface IProps extends IDimensions, IDataStageProps, DOMAttributes<SVGSVGElement> {
  curve?: CurveFactory;
}


const Chart: FunctionComponent<IProps> = ({
  width,
  height,
  rangeX = [0, width],
  rangeY = [0, height],
  offsetX = [0, 0],
  offsetY = [0, 0],
  curve = curveMonotoneX,
  children,
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  return (
    <>
      <ChartContext.Provider value={{
        ref: svgRef,
        width,
        height,
        rangeX,
        rangeY,
        offsetX,
        offsetY,
        curve,
      }}>
        <svg
          ref={svgRef}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio={"none"}
          {...rest}
        >
          {children}
        </svg>
      </ChartContext.Provider>
    </>
  )
}

export default Chart;