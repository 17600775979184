import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  useColorModeValue,
  Stack,
  Button,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  Center,
  MenuDivider,
  MenuItem,
  Image,
} from "@chakra-ui/react";
import { useGlobalState } from "common/global-state";

import LanguageSwitcher from "./LanguageSwitcher";
import DarkModeSwitch from "./DarkModeSwitch";

const Navbar: FunctionComponent<{}> = () => {
  const { user, logout } = useGlobalState();
  const intl = useIntl();

  const bg = useColorModeValue("gray.100", "gray.800");
  const hoverBg = useColorModeValue("gray.200", "gray.700");
  const avatarStyle = "bottts-neutral"; // https://www.dicebear.com/styles/bottts-neutral/
  const avatarUrl = `https://api.dicebear.com/7.x/${avatarStyle}/svg?seed=${user?.username}`;

  return (
    <Box
      px={4}
      bg={useColorModeValue("gray.100", "gray.800")}
      zIndex={2}
      position="relative"
      sx={{
        boxShadow:
          "0 0 2px 0 rgb(0 0 0 / 10%), 0 8px 30px -12px rgb(0 0 0 / 12%)",
      }}
    >
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <Box>
          <Link to="/home">
            <Image src="/images/logo-enposol-symbol.svg" h={10} />
          </Link>
        </Box>

        {/* {user && (
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              {user.sites.map((x) => {
                return <Link key={x.id} to={`/site/${x.id}`}>{x.name}</Link>;
              })}
            </Stack>
          </Flex>
        )} */}

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            <LanguageSwitcher bg={bg} hoverBg={hoverBg} />
            <DarkModeSwitch bg={bg} hoverBg={hoverBg} />

            {user && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={avatarUrl}
                  />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={avatarUrl}
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{user.username}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  {/* <MenuItem>Your Servers</MenuItem>
                <MenuItem>Account Settings</MenuItem> */}
                  <MenuItem onClick={logout}>{intl.formatMessage({
                    id: "site_nav___logout",
                    defaultMessage: "Logout",
                  })}</MenuItem>
                </MenuList>
              </Menu>
            )}
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
