import React, { FunctionComponent } from "react";
import {
  Box,
  Flex,
  Stat,
  StatLabel,
  useColorModeValue,
} from "@chakra-ui/react";

import UpsState from "./UpsState";


interface IProps {
  id: string;
  name: string;
  state?: number;
  isSelected: boolean;
  basePath: string;
  onClick: () => void;
}


const UnitPreview: FunctionComponent<IProps> = ({
  id,
  name,
  state,
  isSelected,
  basePath,
  onClick,
}) => {
  return (
    <Stat
      w="full"
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"8px solid"}
      cursor="pointer"
      borderColor={useColorModeValue(
        isSelected ? "enposol.500" : "gray.400",
        isSelected ? "enposol.500" : "gray.600"
      )}
      rounded={"lg"}
      onClick={onClick}
    >
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <StatLabel fontWeight={"medium"} isTruncated>
            {name}
          </StatLabel>
        </Box>
        <UpsState state={state} />
      </Flex>
    </Stat>
  );
};

export default UnitPreview;
