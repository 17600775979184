import {
  ScaleContinuousNumeric,
  scaleLinear,
} from "d3";

import {
  IChartContext,
  IStageContext,
  ScaleAxis,
} from "./types";

export const makeScale = (axis: ScaleAxis, context: IChartContext | IStageContext, domain: number[], range?: number[], offset?: number[]): ScaleContinuousNumeric<number, number> => {
  const isX = axis === "x";
  const r = range || (isX ? context.rangeX : context.rangeY);
  const o = offset || (isX ? context.offsetX : context.offsetY);

  const offsetRange = [
    r[0] + o[0],
    r[1] - o[1],
  ];
  if (!isX) offsetRange.reverse();

  return scaleLinear<number, number>().domain(domain).rangeRound(offsetRange);
}
