import React, { FunctionComponent } from "react";
import { Stack, Box, Image, Center } from "@chakra-ui/react";

import { useLiveMeasurements } from "scenes/site/hooks";
import { SiteConfig_siteConfig_gridConf } from "graphql/generated";
import StatsTable from "./StatsTable";
import IframeChart from "components/iframe-chart";

interface IProps {
  siteId: string;
  gridConf: SiteConfig_siteConfig_gridConf;
}

const Grid: FunctionComponent<IProps> = ({ siteId, gridConf }) => {
  const { data } = useLiveMeasurements(siteId, "grid");

  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <Center>
          <Image src="/images/svg/grid.svg" boxSize="270px" mb={6} />
        </Center>

        <StatsTable data={data["grid"] || {}} />
      </Box>

      <Box flex={1}>
        <IframeChart chart={gridConf.chartA} title="voltage and current graph" />
        <IframeChart chart={gridConf.chartB} title="power consumption graph" />
      </Box>
    </Stack>
  );
};

export default Grid;
