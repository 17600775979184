import React, { useEffect, useState } from "react";

import {
  LayoutProps,
  SpaceProps,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";


interface BooleanInputProps extends SwitchProps, SpaceProps, LayoutProps {
  currentValue: boolean;
  originalValue: boolean;
  setValue: (newValue: boolean) => void;
}


const BooleanInput: React.FC<BooleanInputProps> = ({
  currentValue,
  originalValue,
  setValue,
  ...rest
}) => {
  const [displayState, setDisplayState] = useState<boolean>(false);

  useEffect(() => {
    setDisplayState(currentValue as boolean);
  }, [currentValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(!currentValue);
  }

  return (
    <Switch
      isChecked={displayState}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default BooleanInput;
