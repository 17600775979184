import React, { FunctionComponent, useRef } from "react";
import { Axis, select } from "d3";

import { ISvgStyleProps, D3AttrValue } from "../types"


interface IProps extends ISvgStyleProps{
  generator: Axis<number>;
  transform?: D3AttrValue;
}


const AxisBase: FunctionComponent<IProps> = ({
  generator,
  transform = null,
  className = "axis",
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const svgEl = select(svgRef.current);

  svgEl
    .selectAll<SVGGElement, number[]>(".axis-x")
    .data([0])
    .join("g")
      .attr("class", `${className} axis-x`)
      .attr("transform", transform)
      .call(generator)
      ;

  return (
    <>
      <g ref={svgRef} {...rest} />
    </>
  )
}

export default AxisBase;
