import React, { FunctionComponent } from "react";
// import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import GridState from "./GridState";

interface IProps {
  data: any;
}

const GridStatsTable: FunctionComponent<IProps> = ({ data }) => {
  const intl = useIntl();

  return (
    <StatsTable
      mb={8}
      rows={[
        {
          label: intl.formatMessage({
            id: "grid__state",
            defaultMessage: "Status of grid",
          }),
          unit: "",
          value: <GridState state={data.status_grid} />,
        },
        {
          label: intl.formatMessage({
            id: "grid__active_power",
            defaultMessage: "Active power",
          }),
          unit: "kW",
          value: data.active_power_consumption,
        },
        {
          label: intl.formatMessage({
            id: "grid__reactive_power",
            defaultMessage: "Reactive power",
          }),
          unit: "kVAR",
          value: data.reactive_power_consumption,
        },
        {
          label: intl.formatMessage({
            id: "grid__power_factor",
            defaultMessage: "Power factor",
          }),
          decimals: 2,
          unit: "",
          value: data.power_factor
        },
        {
          label: intl.formatMessage({
            id: "grid__l1_voltage",
            defaultMessage: "Grid voltage L1",
          }),
          unit: "V",
          value: data.grid_voltage_l1
        },
        {
          label: intl.formatMessage({
            id: "grid__l2_voltage",
            defaultMessage: "Grid voltage L2",
          }),
          unit: "V",
          value: data.grid_voltage_l2
        },
        {
          label: intl.formatMessage({
            id: "grid__l3_voltage",
            defaultMessage: "Grid voltage L3",
          }),
          unit: "V",
          value: data.grid_voltage_l3
        },
        {
          label: intl.formatMessage({
            id: "grid__l1_current",
            defaultMessage: "Grid current L1",
          }),
          unit: "A",
          value: data.grid_current_l1
        },
        {
          label: intl.formatMessage({
            id: "grid__l2_current",
            defaultMessage: "Grid current L2",
          }),
          unit: "A",
          value: data.grid_current_l2
        },
        {
          label: intl.formatMessage({
            id: "grid__l3_current",
            defaultMessage: "Grid current L3",
          }),
          unit: "A",
          value: data.grid_current_l3
        }
      ]}
    />
  );
};

export default GridStatsTable;
