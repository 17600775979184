import React, { FunctionComponent } from "react";
import BaseCard from "./BaseCard";

import IframeChart from "components/iframe-chart";
import { SiteConfig_siteConfig_dashboardConf_components_chartData } from "graphql/generated";

interface IProps {
  chart: SiteConfig_siteConfig_dashboardConf_components_chartData | undefined;
}


const AnalyticsCard: FunctionComponent<IProps> = ({ chart }) => {
  return (
    <BaseCard isWrappedInside={false}>
      <IframeChart title="Analytics" chart={chart} hasBorder={false} />
    </BaseCard>
  );
};

export default AnalyticsCard;
