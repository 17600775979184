import React, { FunctionComponent } from "react";

import {
  Heading,
  TableColumnHeaderProps,
  Th,
  Tr,
} from "@chakra-ui/react";


interface IProps extends TableColumnHeaderProps {
}


const GroupHeading: FunctionComponent<IProps> = ({
  children,
  ...rest
}) => {
  return (
    <>
      <Tr>
        <Th colspan={2 + 48} pt={5} {...rest}>
          <Heading size="md">
            {children}
          </Heading>
        </Th>
      </Tr>
    </>
  )
}

export default GroupHeading;
