import React, {
  createContext,
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from "react";
import { Center, Spinner } from "@chakra-ui/react"
import { LoginMutation_login, SessionState } from "graphql/generated";
import { useApolloClient } from "@apollo/client";
import { IntlProvider } from "react-intl";

import { AUTH_TOKEN_KEY_NAME } from "common/consts";
import { SESSION_STATE_QUERY } from "graphql/queries";
import { USER_PREFERENCE_DEFAULTS } from "common/user-preferences/defaults";

const DEFAULT_LOCALE = USER_PREFERENCE_DEFAULTS.ui__locale;

interface ILocaleData {
  locale: string;
  messages: Record<string, string>;
}

interface ILocaleDef {
  locale: string;
  label: string;
}

interface IGlobalState {
  user: LoginMutation_login | null;
  login: (user: LoginMutation_login) => void;
  logout: () => void;
  setLocale: (locale: string) => void;
  locale: string;
  locales: Array<ILocaleDef>;
  connected: boolean;
  setConnected: (status: boolean) => void;
}


const LOCALES: Array<ILocaleDef> = [
  { locale: "en", label: "English" },
  { locale: "cs", label: "Čeština" },
  // { locale: "pl", label: "Polski" },
];

const GlobalStateContext = createContext<IGlobalState>({} as IGlobalState);

export const GlobalStateProvider: FunctionComponent<{}> = ({ children }) => {
  const [isInit, setInitFlag] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [user, setUser] = useState<LoginMutation_login | null>(null);
  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);
  const [localeData, setLocaleData] = useState<ILocaleData>({
    locale: locale,
    messages: {},
  });
  const { query } = useApolloClient();

  useEffect(() => {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY_NAME);

    if (!authToken) {
      setInitFlag(true);
      return;
    }

    query<SessionState>({
      query: SESSION_STATE_QUERY,
    })
      .then((response) => {
        setUser(response.data.sessionState.user);
        setInitFlag(true);
      })
      .catch((error) => {
        console.log("error", error);

        setInitFlag(true);
      });
  }, [query]);

  useEffect(() => {
    fetch(`/lang/${locale}.json`)
      .then((response) => response.json())
      .then((response) => {
        setLocaleData({ locale, messages: response });
      })
      .catch(() => {
        alert(`Unable to load language "${locale}"`);
      });
  }, [user, locale]);

  const login = (loginUser: LoginMutation_login) => {
    localStorage.setItem(AUTH_TOKEN_KEY_NAME, loginUser.token);
    setUser(loginUser);
  };

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY_NAME);
    setLocale(DEFAULT_LOCALE);
    setUser(null);
  };

  useEffect(() => {
    if (user?.preferences?.ui__locale) {
      setLocale(user.preferences.ui__locale);
    }
  }, [user]);

  if (!isInit) {
    return (
      <Center mt={10}>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <GlobalStateContext.Provider
      value={{
        user,
        login,
        logout,
        setLocale,
        locale,
        locales: LOCALES,
        connected,
        setConnected,
      }}
    >
      <IntlProvider
        messages={localeData.messages}
        locale={localeData.locale}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (): IGlobalState => {
  return useContext(GlobalStateContext);
};
