import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Stack,
} from "@chakra-ui/react";

// import { detail } from "graphql/generated";
import StatsTable from "components/stats-table";
import ConverterState from "./ConverterState";

interface IProps {
  data: any;
}

const ConverterStats: FunctionComponent<IProps> = ({ data }) => {
  const intl = useIntl();

  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <StatsTable
          mb={8}
          rows={[
            {
              label: intl.formatMessage({
                id: "converter__state",
                defaultMessage: "Status",
              }),
              unit: "",
              value: <ConverterState state={data.status} />,
            },
            {
              label: intl.formatMessage({
                id: "converter__current_l1",
                defaultMessage: "Current L1",
              }),
              unit: "A",
              value: data.current_l1,
            },
            {
              label: intl.formatMessage({
                id: "converter__current_l2",
                defaultMessage: "Current L2",
              }),
              unit: "A",
              value: data.current_l2,
            },
            {
              label: intl.formatMessage({
                id: "converter__current_l3",
                defaultMessage: "Current L3",
              }),
              unit: "A",
              value: data.current_l3,
            },
            {
              label: intl.formatMessage({
                id: "converter__voltage_l1",
                defaultMessage: "Voltage L1",
              }),
              unit: "V",
              value: data.voltage_l1,
            },
            {
              label: intl.formatMessage({
                id: "converter__voltage_l2",
                defaultMessage: "Voltage L2",
              }),
              unit: "V",
              value: data.voltage_l2,
            },
            {
              label: intl.formatMessage({
                id: "converter__voltage_l3",
                defaultMessage: "Voltage L3",
              }),
              unit: "V",
              value: data.voltage_l3,
            },
          ]}
        />
      </Box>

      <Box flex={1}>
        <StatsTable
          rows={[
            {
              label: intl.formatMessage({
                id: "converter__power_factor",
                defaultMessage: "Power factor",
              }),
              unit: "",
              decimals: 2,
              value: data.power_factor,
            },
            {
              label: intl.formatMessage({
                id: "converter__frequency",
                defaultMessage: "Frequency",
              }),
              unit: "Hz",
              value: data.frequency,
            },
            {
              label: intl.formatMessage({
                id: "converter__input_temperature",
                defaultMessage: "Input temperature",
              }),
              unit: "°C",
              decimals: 1,
              value: data.input_temp && data.input_temp[0],
            },
            {
              label: intl.formatMessage({
                id: "converter__active_power",
                defaultMessage: "Active power",
              }),
              unit: "kW",
              value: data.active_power,
            },
            {
              label: intl.formatMessage({
                id: "converter__reactive_power",
                defaultMessage: "Reactive power",
              }),
              unit: "kVAR",
              value: data.reactive_power,
            },
            {
              label: intl.formatMessage({
                id: "converter__apparent_power",
                defaultMessage: "Apparent power",
              }),
              unit: "kVA",
              value: data.apparent_power,
            },
            {
              label: intl.formatMessage({
                id: "converter__dc_voltage",
                defaultMessage: "DC voltage",
              }),
              unit: "V",
              value: data.dc_voltage,
            },
          ]}
        />
      </Box>
    </Stack>
  );
};

export default ConverterStats;
