import React, { FunctionComponent, useEffect, useState } from "react";
import { Stack } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useApolloClient, useQuery } from "@apollo/client";
import omit from "ramda/src/omit";
import assert from "assert";

import EditModal from "./EditModal";
import DayLine from "./DayLine";
import { IDayDef, TState } from "./types";
import { GET_PEAK_SHAVING_TIME_TABLE } from "graphql/queries";
import { SET_PEAK_SHAVING_TIME_TABLE } from "graphql/mutations";
import {
  GetPeakShavingTimeTable,
  GetPeakShavingTimeTableVariables,
  GetPeakShavingTimeTable_getPeakShavingTimeTable,
  IPeakShavingTimeTableInput,
  SetPeakShavingTimeTable,
  SetPeakShavingTimeTableVariables,
} from "graphql/generated";
import { useToasts } from "common/toasts";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const WeekSchedule: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const daysDef: Array<IDayDef> = [
    {
      id: "mon",
      abbr: intl.formatMessage({
        id: "weekday__mon",
        defaultMessage: "Mon",
      }),
      label: intl.formatMessage({
        id: "weekday__monday",
        defaultMessage: "Monday",
      }),
    },
    {
      id: "tue",
      abbr: intl.formatMessage({
        id: "weekday__tue",
        defaultMessage: "Tue",
      }),
      label: intl.formatMessage({
        id: "weekday__tuesday",
        defaultMessage: "Tuesday",
      }),
    },
    {
      id: "wed",
      abbr: intl.formatMessage({
        id: "weekday__wed",
        defaultMessage: "Wed",
      }),
      label: intl.formatMessage({
        id: "weekday__wednesday",
        defaultMessage: "Wednesday",
      }),
    },
    {
      id: "thu",
      abbr: intl.formatMessage({
        id: "weekday__thu",
        defaultMessage: "Thu",
      }),
      label: intl.formatMessage({
        id: "weekday__thursday",
        defaultMessage: "Thursday",
      }),
    },
    {
      id: "fri",
      abbr: intl.formatMessage({
        id: "weekday__fri",
        defaultMessage: "Fri",
      }),
      label: intl.formatMessage({
        id: "weekday__friday",
        defaultMessage: "Friday",
      }),
    },
    {
      id: "sat",
      abbr: intl.formatMessage({
        id: "weekday__sat",
        defaultMessage: "Sat",
      }),
      label: intl.formatMessage({
        id: "weekday__saturday",
        defaultMessage: "Saturday",
      }),
    },
    {
      id: "sun",
      abbr: intl.formatMessage({
        id: "weekday__sun",
        defaultMessage: "Sun",
      }),
      label: intl.formatMessage({
        id: "weekday__sunday",
        defaultMessage: "Sunday",
      }),
    },
  ];

  const [state, setState] = useState<TState>(() => {
    return Object.fromEntries(daysDef.map((x) => [x.id, []]));
  });

  const [editedId, setEditedId] = useState<[string, number] | null>(null);
  const { pushSuccessToast, pushErrorToast } = useToasts();
  const client = useApolloClient();

  const { data, loading } = useQuery<
    GetPeakShavingTimeTable,
    GetPeakShavingTimeTableVariables
  >(GET_PEAK_SHAVING_TIME_TABLE, { variables: { siteId } });

  useEffect(() => {
    if (!data) {
      return;
    }

    setState(
      Object.fromEntries(
        daysDef.map((dayDef) => {
          assert(dayDef.id in data.getPeakShavingTimeTable);

          const key =
            dayDef.id as keyof GetPeakShavingTimeTable_getPeakShavingTimeTable;
          const frames = data.getPeakShavingTimeTable[key];

          assert(Array.isArray(frames));

          return [key, frames.map((x) => omit(["__typename"], x))];
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      {editedId && (
        <EditModal
          dayDef={daysDef.find((day) => day.id === editedId[0]) || daysDef[0]}
          dayState={state[editedId[0]]}
          frameIndex={editedId[1]}
          onSave={(dayId, newFrames) => {
            setState((s) => {
              const newState = { ...s, [dayId]: newFrames };

              client
                .mutate<
                  SetPeakShavingTimeTable,
                  SetPeakShavingTimeTableVariables
                >({
                  mutation: SET_PEAK_SHAVING_TIME_TABLE,
                  variables: {
                    siteId,
                    table: newState as unknown as IPeakShavingTimeTableInput,
                  },
                })
                .then((res) => {
                  pushSuccessToast(
                    intl.formatMessage({
                      id: "settings__timed_peak__save_success",
                      defaultMessage: "Timed peak has been successfully saved",
                    })
                  );
                })
                .catch((reason) => pushErrorToast(String(reason)));

              return newState;
            });

            setEditedId(null);
          }}
          onClose={() => {
            setEditedId(null);
          }}
        />
      )}

      <Stack direction="column" spacing={2}>
        {daysDef.map((day) => {
          return (
            <DayLine
              key={day.id}
              dayDef={day}
              dayState={state[day.id]}
              isLoading={loading}
              onAdd={(dayId) => {
                setEditedId([dayId, -1]);
              }}
              onEdit={(dayId, frameIndex) => {
                setEditedId([dayId, frameIndex]);
              }}
            />
          );
        })}
      </Stack>
    </div>
  );
};

export default WeekSchedule;
