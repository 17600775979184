import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { StyleProps } from "@chakra-ui/react";

import { ListSites_listSites } from "graphql/generated";

import SitePreview from "./SitePreview";


interface IProps extends StyleProps {
  sites: ListSites_listSites[];
}


const SitesOverview: FunctionComponent<IProps> = ({
  sites,
}) => {
  const navigate = useNavigate();

  return (
    <ul className="honeycomb">
      { sites.map((site) => (
        <SitePreview
          name={site.name}
          onClick={() => {
            navigate(`/site/${site.id}`);
          }}
        />
      )) }
      <li className="honeycomb-cell honeycomb__placeholder"></li>
    </ul>
  );
};

export default SitesOverview;
