import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import { Box, useColorModeValue } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { useGlobalState } from "common/global-state";


interface IProps {}


const ConnectionStatus: FunctionComponent<IProps> = () => {
  const intl = useIntl();
  const { connected } = useGlobalState();
  const iconColor = useColorModeValue("red.300", "red.600");

  return (
    connected ? (<Box/>) : (
      <Box
        px={2}
        title={intl.formatMessage({
          id: "site__disconnected",
          defaultMessage: "Connection to live data not established",
        })}
      >
        <WarningTwoIcon color={iconColor} />
      </Box>
    )
  );
};

export default ConnectionStatus;
