import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Image, Flex } from "@chakra-ui/react";

import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";


interface IProps {
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}


const AtsCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  const icon_url = (): string => {
    const path = "/images/svg";

    if (data?.is_primary_on){
      return `${path}/ats-${config.flip ? "bypass" : "primary"}.svg`;
    }
    else if (data?.is_bypass_on){
      return `${path}/ats-${config.flip ? "primary" : "bypass"}.svg`;
    }
    else if (data?.is_primary_on === false && data?.is_bypass_on === false){
      return `${path}/ats-off.svg`;
    }
    else {
      return `${path}/ats-none.svg`;
    }
  }

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__ats____title",
        defaultMessage: `{count, plural,
          one {ATS}
          other {ATSes}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
    >
      <Flex w={"full"} alignItems={"center"} justifyContent={"center"}>
        <Image
          src={icon_url()}
          boxSize={"150px"}
        />
      </Flex>

    </BaseCard>
  );
};

export default AtsCard;
