import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  Center,
  Skeleton,
  Tr,
  Td,
  useColorModeValue,
} from "@chakra-ui/react";

import { IItem, TValue } from "./types";

import NumberInput, { displayNumberDecimals } from "./NumberInput";
import BooleanInput from "./BooleanInput";
import ResetButton from "./ResetButton";


interface SettingsRowProps {
  item: IItem;
  currentValue: TValue;
  originalValue: TValue | null;
  setValue: <T>(value: T | ((value: T) => T)) => void;
  variant?: "compact" | undefined;
}


const SettingsRow: React.FC<SettingsRowProps> = ({
  item,
  currentValue,
  originalValue,
  setValue,
  variant,
}) => {
  const intl = useIntl();
  const [isOriginal, setIsOriginal] = useState(true);

  const padding = 2;
  const compactVariant = variant === "compact";
  const fontSize = compactVariant ? "xs" : "sm";
  const inputWidth = compactVariant ? 12 : 20;
  const inputBg = useColorModeValue("white", "gray.800");

  useEffect(() => {
    setIsOriginal(originalValue === null || originalValue === currentValue);
  }, [currentValue, originalValue]);

  const resetValue = () => {
    setValue(originalValue);
  }

  return (
    <Tr>
      <Td p={padding} fontSize={fontSize} w="80%">{item.name}</Td>
      <Td p={padding} fontSize={fontSize}>
        <ResetButton
          size="xs"
          ariaLabel="Reset"
          title={intl.formatMessage({
            id: "settings__reset_value",
            defaultMessage: "Restore `{value}`"
          }, {
            value: item.type === "float" ? displayNumberDecimals(originalValue as number) : originalValue?.toString(),
          })}
          showIf={!isOriginal}
          onClick={resetValue}
        />
      </Td>
      <Td py={padding} px={0}>
        {currentValue === undefined && (
          <Center>
            <Skeleton w="121px" h="32px" />
          </Center>
        )}
        {currentValue !== undefined && item.type === "float" && (
          <NumberInput
            currentValue={currentValue as number}
            originalValue={originalValue as number}
            setValue={setValue}
            unit={item.unit}
            step={item.step}
            decimals={item.decimals}
            w={inputWidth}
            mx="1"
            px="2"
            size="sm"
            fontSize={fontSize}
            bg={inputBg}
          />
        )}
        {currentValue !== undefined && item.type === "bool" && (
          <BooleanInput
            size="lg"
            currentValue={currentValue as boolean}
            originalValue={originalValue as boolean}
            setValue={setValue}
          />
        )}
      </Td>
      <Td p={padding} fontSize={fontSize} w="1%">{item.unit}</Td>
    </Tr>
  );
}

export default SettingsRow;
