import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button, LinkProps, useColorModeValue } from "@chakra-ui/react";


interface IProps extends LinkProps {
  siteId: string;
  label: string;
  slug: string;
  selected?: boolean;
}


const SiteMenuItem: FunctionComponent<IProps> = ({
  siteId,
  label,
  slug,
  selected = false,
  ...rest
}) => {
  const basePath = `/site/${siteId}`;
  const to = `${basePath}${slug ? "/" + slug : ""}`;
  const selectedColor = useColorModeValue("white", "black");
  const selectedBg = "enposol.500";

  return (
    <Link key={label} to={to}>
      <Button
        key={label}
        rounded="full"
        colorScheme="blue"
        variant="soft-rounded"
        _disabled={{ cursor: "not-allowed" }}
        { ...(
          selected
          ? { color: selectedColor, bg: selectedBg }
          : {}
        ) }
      >
        {label}
      </Button>
    </Link>
  );
};

export default SiteMenuItem;
