import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Flex,
  Stat,
  StatLabel,
  useColorModeValue,
} from "@chakra-ui/react";

import GensetState from "./GensetState";
import { displayId } from "common/stringUtils"


interface IProps {
  name: string;
  state?: number;
  isSelected: boolean;
  onClick: () => void;
}


const GensetPreview: FunctionComponent<IProps> = ({
  name,
  state,
  isSelected,
  onClick,
}) => {
  const intl = useIntl();

  return (
    <Stat
      w="full"
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"8px solid"}
      cursor="pointer"
      borderColor={useColorModeValue(
        isSelected ? "enposol.500" : "gray.400",
        isSelected ? "enposol.500" : "gray.600"
      )}
      rounded={"lg"}
      onClick={onClick}
    >
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <StatLabel fontWeight={"medium"} isTruncated>
            {intl.formatMessage({
              id: "genset___title",
              defaultMessage: "Genset {id}",
            }, {
              id: displayId(name),
            })}
          </StatLabel>
        </Box>
        <GensetState state={state} />
      </Flex>
    </Stat>
  );
};

export default GensetPreview;
