import React, { FunctionComponent } from "react";
import { Box, SpaceProps, useColorModeValue, useColorMode } from "@chakra-ui/react";

// FIXME: find more general type
import { SiteConfig_siteConfig_batteryConf_avgTmpChart } from "graphql/generated";
import { envVars } from "common/env";

interface IProps extends SpaceProps {
  title: string;
  chart: SiteConfig_siteConfig_batteryConf_avgTmpChart | undefined;
  hasBorder?: boolean;
}

const IframeChartBorder: FunctionComponent<{}> = ({ children, ...rest }) => {
  return (
    <Box
      w="full"
      h={325}
      mb={8}
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"xl"}
      rounded={"md"}
      overflow={"hidden"}
      border="3px solid"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      {...rest}
    >
      {children}
    </Box>
  );
};

const IframeChart: FunctionComponent<IProps> = ({
  title,
  chart,
  hasBorder = true,
  ...rest
}) => {
  const { colorMode } = useColorMode();
  const firstChart = chart?.sources[0];

  const content = chart ? (
    <iframe
      title={title}
      width="100%"
      // height="90%"
      height="100%"
      src={`${envVars.CHARTS_API_URL}${firstChart?.uri}&theme=${colorMode}&kiosk`}
    />
  ) : null;

  return hasBorder ? <IframeChartBorder {...rest}>{content}</IframeChartBorder> : content;
};

export default IframeChart;
