import React, { FunctionComponent, useMemo } from "react";
import { Stack, Box, VStack, Heading, Image, Center, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import ConverterPreview from "./ConverterPreview";
import ConverterStats from "./ConverterStats";
import {
  SiteConfig_siteConfig_converterConf,
  // ConverterMeasurementBoard,
  // ConverterMeasurementBoardVariables,
} from "graphql/generated";
// import { CONVERTER_MEASUREMENT_BOARD_SUBSCRIPTION } from "graphql/subscriptions";
import { useLiveMeasurements } from "scenes/site/hooks";
import IframeChart from "components/iframe-chart";
import { displayId } from "common/stringUtils"

interface IProps {
  siteId: string;
  basePath: string;
  converterConf: SiteConfig_siteConfig_converterConf;
}


const converterImageSrc = (uiVersion: string): URL => {
  switch (uiVersion) {
    case "sermatec":
      return new URL("/images/svg/menic-sermatec.svg", document.baseURI);
    case "megarevo":
      return new URL("/images/svg/menic-megarevo.svg", document.baseURI);
    case "aeg":
    default:
      return new URL("/images/svg/menic-aeg.svg", document.baseURI);
  }
}


const Converter: FunctionComponent<IProps> = ({
  siteId,
  basePath,
  converterConf,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const converterId = useMemo(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/converter\/([\w-]+)/);

    if (!m) {
      return converterConf.converters[0].id;
    }

    return m[1];
  }, [location, basePath, converterConf]);

  const { data } = useLiveMeasurements(siteId, `conv_${converterId}`);

  return (
    <Box>
      <Stack direction="row" spacing={12}>
        <Box flex={1}>
          <VStack spacing={2} mb={6}>
            {converterConf.converters.map((conf) => {
              const previewData = data[`conv_${conf.id}`];

              return (
                <ConverterPreview
                  key={conf.id}
                  name={conf.id}
                  state={previewData?.status}
                  isSelected={conf.id === converterId}
                  onClick={() => {
                    navigate(`${basePath}/converter/${conf.id}`, {
                      replace: true,
                    });
                  }}
                />
              );
            })}
          </VStack>
        </Box>

        <Box flex={2}>
          <Heading as="h3" size="lg" mb={8}>
            {intl.formatMessage({
              id: "converter___title",
              defaultMessage: "Converter {id}",
            }, {
              id: displayId(converterId),
            })}
          </Heading>

          <Box width="100%">
            <ConverterStats data={data[`conv_${converterId}`] || {}} />
          </Box>
        </Box>

        <Center flex={1}>
          <Image src={converterImageSrc(converterConf.uiVersion).href} boxSize={400} />
        </Center>
      </Stack>

      <Flex flex={1}>
        <IframeChart
          title={intl.formatMessage({
            id: "converter__chart__active_power",
            defaultMessage: "Converters active power chart",
          })}
          chart={converterConf.chartA}
          mr={8}
        />
        <IframeChart
          title={intl.formatMessage({
            id: "converter__chart__reactive_power",
            defaultMessage: "Converters reactive power chart",
          })}
          chart={converterConf.chartB}
        />
      </Flex>
    </Box>
  );
};

export default Converter;
