import React, { FunctionComponent } from "react";
import { Badge } from "@chakra-ui/react";
import { WarningTwoIcon, InfoIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { useIntl } from "react-intl";

interface IProps {
  alarmType: string;
  onlyActive: boolean;
}

const NotificationIcon: FunctionComponent<IProps> = ({ alarmType, onlyActive }) => {
  const intl = useIntl();
  const iconColor = (color: string) => ({
    color: color,
    opacity: onlyActive ? 1 : .5,
  });
  const alarmLabel = (alarmType: string) => {
    switch(alarmType) {
      case "T:error": return intl.formatMessage({
        id: "notifications__alarm_type__error",
        defaultMessage: "ERROR",
      });
      case "T:warning": return intl.formatMessage({
        id: "notifications__alarm_type__warning",
        defaultMessage: "WARNING",
      });
      case "T:info": return intl.formatMessage({
        id: "notifications__alarm_type__info",
        defaultMessage: "INFO",
      });
    }
  }

  const renderIcon = () => {
    switch (alarmType) {
      case 'T:error':
        return (<WarningTwoIcon {...iconColor('red.500')} />);
      case 'T:warning':
        return (<WarningTwoIcon {...iconColor('yellow.500')} />);
      case 'T:info':
        return (<InfoIcon {...iconColor('')} />);
      default:
        return (<QuestionOutlineIcon {...iconColor('')} />);
    }
  }

  return (
    <div>
      {renderIcon()}
      <Badge variant='subtle'>{alarmLabel(alarmType)}</Badge>
    </div>
  );
  
};

export default NotificationIcon;
