import React, { FunctionComponent, useState, MutableRefObject } from "react";
import {
  Box,
  Collapse,
} from "@chakra-ui/react";

import Keyboard, { KeyboardInput } from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";
import "./index.css";


interface IProps {
  showWhen: boolean;
  keyboardRef: MutableRefObject<any>;
  inputName: string;
  onChangeAll: (inputObj: KeyboardInput) => void;
}


const OnScreenKeyboard: FunctionComponent<IProps> = ({
  showWhen,
  keyboardRef,
  inputName,
  onChangeAll,
  ...props
}) => {
  const [layoutName, setLayoutName] = useState("default");

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
  }

  const layout = {
    'default': [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      '{lock} a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
    ],
    'shift': [
      '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M &lt; &gt; ? {shift}',
    ]
  }

  return (
    <>
      <Collapse in={showWhen}>
        <Box w={750}>
          <Keyboard
            keyboardRef={r => (keyboardRef.current = r)}
            layoutName={layoutName}
            theme={"hg-theme-default enposol-keyboard"}
            tabCharOnTab={false}
            inputName={inputName}
            onKeyPress={onKeyPress}
            onChangeAll={onChangeAll}
            layout={layout}
            {...props}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default OnScreenKeyboard;
