import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import { minutesToHhMm } from "common/stringUtils";
import UpsState from "./UpsState";

interface IProps {
  data: any;
}

const UpsStats: FunctionComponent<IProps> = ({ data }) => {
  const intl = useIntl();

  return (
    <StatsTable
      rows={[
        {
          label: intl.formatMessage({
            id: "ups__state",
            defaultMessage: "Status",
          }),
          unit: "",
          value: <UpsState state={data.status} />,
        },
        {
          label: intl.formatMessage({
            id: "ups__soc",
            defaultMessage: "SoC",
          }),
          unit: "%",
          value: data.soc,
        },
        {
          label: intl.formatMessage({
            id: "ups__remaining_time",
            defaultMessage: "Remaining time",
          }),
          unit: "h:m",
          value: data.remaining_time && minutesToHhMm(data.remaining_time),
        },
        {
          label: intl.formatMessage({
            id: "ups__input_voltage",
            defaultMessage: "Input voltage",
          }),
          unit: "V",
          value: data.input_voltage,
        },
        {
          label: intl.formatMessage({
            id: "ups__input_frequency",
            defaultMessage: "Input frequency",
          }),
          unit: "Hz",
          value: data.input_frequency,
        },
        {
          label: intl.formatMessage({
            id: "ups__output_voltage",
            defaultMessage: "Output voltage",
          }),
          unit: "V",
          value: data.output_voltage,
        },
        {
          label: intl.formatMessage({
            id: "ups__output_frequency",
            defaultMessage: "Output frequency",
          }),
          unit: "Hz",
          value: data.output_frequency,
        },
      ]}
    />
  );
};

export default UpsStats;
