import React, { FunctionComponent, useContext, SVGAttributes } from "react";
import { extent, line } from "d3";

import { ChartContext, makeScale } from "..";
import { PathBase } from "../base";
import { IDataProps, IPlotData, IShapeProps } from "../types";


interface IProps extends IDataProps, IShapeProps, SVGAttributes<SVGPathElement> {
  data: IPlotData[];
}


const Line: FunctionComponent<IProps> = ({
  data,
  domainX = extent<number>(data.map(d => d.x)) as number[],
  domainY = extent<number>(data.map(d => d.y as number)) as number[],
  rangeX,
  rangeY,
  offsetX,
  offsetY,
  curve,
  strokeWidth = 1,
  ...rest
}) => {
  const context = useContext(ChartContext);
  const scaleX = makeScale("x", context, domainX, rangeX, offsetX);
  const scaleY = makeScale("y", context, domainY, rangeY, offsetY);

  const generator = line<IPlotData>()
                    .defined(d => d.y !== null)
                    .x(d => scaleX(d.x as number))
                    .y(d => scaleY(d.y as number))
                    .curve(curve || context.curve)
                    ;
  return (
    <>
      <PathBase
        className = "line"
        {...{
          generator,
          data,
          scaleX,
          scaleY,
          strokeWidth,
        }}
        {...rest}
      />
    </>
  )
}

export default Line;
