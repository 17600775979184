import React, { FunctionComponent, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import assert from "assert";

import Rack from "./Rack";
import Summary from "./Summary";
import { SiteConfig_siteConfig_batteryConf } from "graphql/generated";

interface IProps {
  siteId: string;
  basePath: string;
  batteryConf: SiteConfig_siteConfig_batteryConf;
}

const Battery: FunctionComponent<IProps> = ({
  siteId,
  basePath,
  batteryConf,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [rackId, moduleId] = useMemo<[string | null, string | null]>(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/battery(\/(\w+))?(\/(\w+))?/);

    assert(m);

    let rackId = null;
    let moduleId = "0";

    if (m.length >= 3 && m[2]) {
      rackId = m[2];
    }

    if (m.length >= 5 && m[4]) {
      moduleId = m[4];
    }

    return [rackId, moduleId];
  }, [location, basePath]);

  if (rackId && moduleId) {
    const nRacks = batteryConf.racks.length;
    const rackIndex = batteryConf.racks.findIndex((rack) => rack.id === rackId);

    return (
      <Rack
        siteId={siteId}
        rackId={rackId}
        moduleId={moduleId}
        basePath={basePath}
        batteryConf={batteryConf}
        onPrevClick={() => {
          navigate(
            `${basePath}/battery/${
              rackIndex === 0
                ? batteryConf.racks[nRacks - 1].id
                : batteryConf.racks[rackIndex - 1].id
            }`
          );
        }}
        onNextClick={() => {
          navigate(
            `${basePath}/battery/${
              rackIndex === nRacks - 1
                ? batteryConf.racks[0].id
                : batteryConf.racks[rackIndex + 1].id
            }`
          );
        }}
      />
    );
  }

  return (
    <Summary basePath={basePath} batteryConf={batteryConf} siteId={siteId} />
  );
};

export default Battery;
