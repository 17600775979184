import React, { FunctionComponent, CSSProperties } from "react";
import { Tag } from "@chakra-ui/react"

import { envVars } from "common/env";


const style: CSSProperties = {
  position: "absolute",
  bottom: 0,
  left: 0,
}


const AppVersion: FunctionComponent<{}> = ({ children }) => {
  return (
    <Tag size={"sm"} colorScheme={"white"} opacity={0.3} style={style}>
      {`v${envVars.VERSION}`}
    </Tag>
  );
};

export default AppVersion;
