import React, { FunctionComponent } from "react";
import { useGlobalState } from "common/global-state";
import { useUserPreference } from "common/user-preferences";

import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import CountryFlag from "./CountryFlag";


interface IProps {
  bg: string;
  hoverBg: string;
}


const LanguageSwitcher: FunctionComponent<IProps> = ({
  bg,
  hoverBg,
}) => {
  const { locale, locales, setLocale } = useGlobalState();
  const flagBg = useColorModeValue("gray.200", "gray.700");
  const [ , setUserLocale ] = useUserPreference("ui__locale");

  const switchLocale = (locale: string) => {
    setLocale(locale);
    setUserLocale(locale);
  }

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            bg={ bg }
            _hover={{ bg: hoverBg }}
          >
            <CountryFlag code={locale} size={30} />
          </MenuButton>
          <MenuList>
            {locales.map((x) => (
              <MenuItem
                key={x.locale}
                minH="40px"
                onClick={() => switchLocale(x.locale)}
              >
                <HStack>
                  <CountryFlag code={x.locale} size={30} bg={flagBg} />
                  <Text fontSize={"md"}>{x.label}</Text>
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default LanguageSwitcher;
