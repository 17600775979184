import React, { useState } from "react";

import {
  Center,
  Skeleton,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

import { IItem, TValue, ResettableValue } from "../types";

import NumberInput from "./NumberInput";
import TextInput from "./TextInput";


interface SettingsValueProps {
  item: IItem;
  value: ResettableValue<TValue>;
  originalValue: TValue | null;
  setValue: (value: TValue) => void;
  variant?: "compact" | undefined;
}


const SettingsValue: React.FC<SettingsValueProps> = ({
  item,
  originalValue,
  setValue,
  variant,
}) => {
  const [currentValue, setCurrentValue] = useState(item.value);

  const compactVariant = variant === "compact";
  const fontSize = compactVariant ? "xs" : "sm";
  const inputWidth = compactVariant ? 12 : 20;
  const inputBg = useColorModeValue("white", "gray.800");

  // useEffect(() => {
  //   setIsOriginal(originalValue === null || originalValue === currentValue);
  // }, [currentValue, originalValue]);

  // const resetValue = () => {
  //   setValue(originalValue);
  // }

  // const setValue = (newValue: TValue) => {
  //   setCurrentValue(newValue);
  //   console.log("SET", newValue)
  // }

  return (
    <>
      {currentValue === undefined && (
        <Center>
          <Skeleton w="121px" h="32px" />
        </Center>
      )}
      {/* {currentValue !== undefined && (
        <ResetButton
          size="xs"
          ariaLabel="Reset"
          title={intl.formatMessage({
            id: "settings__reset_value",
            defaultMessage: "Restore `{value}`"
          }, {
            value: item.type === "float" ? displayNumberDecimals(originalValue as number) : originalValue?.toString(),
          })}
          showIf={!isOriginal}
          onClick={resetValue}
        />
      )} */}
      {currentValue !== undefined && item.type === "float" && (
        <NumberInput
          currentValue={currentValue.val as number}
          originalValue={originalValue as number}
          setValue={setValue}
          unit={item.unit}
          step={item.step}
          decimals={item.decimals}
          w={inputWidth}
          mx="1"
          px="2"
          size="sm"
          fontSize={fontSize}
          bg={inputBg}
        />
      )}
      {currentValue !== undefined && item.type === "string" && (
        <Text fontWeight="bold">
          {currentValue.val}
        </Text>
      )}
    </>
  );
}

export default SettingsValue;
