import React, { FunctionComponent } from "react";
import { Badge, Skeleton } from "@chakra-ui/react";
import { useIntl } from "react-intl";

interface IProps {
  state?: number;
}

const GRID_STATE = {
  NONE: 0,
  OK: 1,
  OFF: 3,
  ERROR: 4,
}

const GridState: FunctionComponent<IProps> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case GRID_STATE.NONE:
      return (
        <Badge colorScheme={"gray"}>
          {intl.formatMessage({
            id: "grid__state__none",
            defaultMessage: "-",
          })}
        </Badge>
      )
    case GRID_STATE.OK:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          {intl.formatMessage({
            id: "grid__state__ok",
            defaultMessage: "OK",
          })}
        </Badge>
      )
    case GRID_STATE.OFF:
      return (
        <Badge variant="solid" colorScheme={"blue"}>
          {intl.formatMessage({
            id: "grid__state__off",
            defaultMessage: "OFF",
          })}
        </Badge>
      )
    case GRID_STATE.ERROR:
      return (
        <Badge variant="solid" colorScheme={"red"}>
          {intl.formatMessage({
            id: "grid__state__error",
            defaultMessage: "ERROR",
          })}
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default GridState;
