import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

import omit from "ramda/src/omit";

import { useToasts } from "common/toasts";
import {
  useSettingsTable,
  SettingsTable,
} from "components/settings-table";
import { IItem } from "components/settings-table/types";

import { useQuery, useMutation } from "@apollo/client";
import { SET_HVAC_CONTROLS } from "graphql/mutations";
import { GET_HVAC_CONTROLS } from "graphql/queries";
import {
  GetHvacControls,
  GetHvacControlsVariables,
} from "graphql/generated";


interface IProps {
  siteId: string;
  hvacId: number;
  toggle: Function;
}


const HVACUnitControls: FunctionComponent<IProps> = ({
  siteId,
  hvacId,
  toggle,
}) => {
  const intl = useIntl();
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const items: Array<IItem> = [
    {
      id: "tempCooling",
      type: "float",
      unit: "°C",
      name: intl.formatMessage({
        id: "hvac__cooling_temp",
        defaultMessage: "Cooling temperature",
      })
    },
    {
      id: "tempHeating",
      type: "float",
      unit: "°C",
      name: intl.formatMessage({
        id: "hvac__heating_temp",
        defaultMessage: "Heating temperature",
      })
    },
    {
      id: "hysteresis",
      type: "float",
      unit: "°C",
      name: intl.formatMessage({
        id: "hvac__hysteresis",
        defaultMessage: "Hysteresis",
      })
    },
    {
      id: "switchOnOff",
      type: "bool",
      name: intl.formatMessage({
        id: "hvac__on_off",
        defaultMessage: "On/Off",
      })
    },
  ];

  const { data: controlsData, loading } = useQuery<GetHvacControls, GetHvacControlsVariables>(
    GET_HVAC_CONTROLS,
    {
      variables: { siteId, hvacId },
      errorPolicy: "all",
    }
  );

  const { state, tableProps } = useSettingsTable(
    items,
    omit(["__typename"], controlsData?.getHvacControls)
  );

  const [setControls, { loading: isSaving }] = useMutation(SET_HVAC_CONTROLS);

  return (
    <Box
      w={"full"}
      bg={useColorModeValue("gray.200", "gray.800")}
      overflow={"hidden"}
    >
      <SettingsTable {...tableProps} isDisabled={isSaving} variant="compact" />
      <Box p={1} textAlign="center">
        <Button
          size="sm"
          w={160}
          colorScheme="yellow"
          flex={1}
          isLoading={loading || isSaving}
          onClick={() => {
            setControls({
              variables: {
                siteId,
                hvacId,
                controls: state as any,
              },
            })
              .then(() => {
                toggle();
                pushSuccessToast(
                  intl.formatMessage({
                    defaultMessage: "Successfully saved",
                    id: "site__settings__save_success",
                  })
                );
              })
              .catch((reason) => {
                pushErrorToast(String(reason), null);
              });
          }}
        >
          {intl.formatMessage({
            id: "settings___apply",
            defaultMessage: "Apply",
          })}
        </Button>
      </Box>
    </Box>
  );
};

export default HVACUnitControls;
