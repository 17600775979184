export type TValue = boolean | string | TNumericValue;
export type TNumericValue = number | undefined;

export interface IItem {
  id: string;
  name: string;
  value: ResettableValue<TValue>;
  type: "float" | "string";
  unit?: string;
  step?: number;
  decimals?: number;
}

export class ResettableValue<T> {
  private original: T;
  private _value: T;

  constructor(value: T) {
    this.original = this._value = value;
  }
  get val(): T {
    return this._value;
  }
  set val(value: T) {
    this._value = value;
    console.log("SET", value)
  }
  reset(): T {
    console.log("RESET", this.original)
    return this.val = this.original;
  }
  get unoriginal(): boolean {
    return this.val !== this.original;
  }
}
