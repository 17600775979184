import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import {
  GetSecondaryRegulationSettings,
  GetSecondaryRegulationSettingsVariables,
} from "graphql/generated";
import { GET_SECONDARY_REGULATION_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const Secondary: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetSecondaryRegulationSettings, GetSecondaryRegulationSettingsVariables>(
    GET_SECONDARY_REGULATION_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      settingsId="secondaryRegulation"
      settings={data?.getSecondaryRegulationSettings}
      isLoading={loading}
      guideId="secondary"
      uiVersion={uiVersion}
      items={[
        {
          id: "reactivePowerMin",
          name: intl.formatMessage({
            id: "settings__secondary__reactive_power_min_value",
            defaultMessage: "Reactive power min value",
          }),
          type: "float",
          unit: "kVAR",
        },
        {
          id: "reactivePowerMax",
          name: intl.formatMessage({
            id: "settings__secondary__reactive_power_max_value",
            defaultMessage: "Reactive power max value",
          }),
          type: "float",
          unit: "kVAR",
        },
        {
          id: "baseVoltage",
          name: intl.formatMessage({
            id: "settings__secondary__base_voltage",
            defaultMessage: "Base voltage",
          }),
          type: "float",
          unit: "V",
        },
        {
          id: "minVoltage",
          name: intl.formatMessage({
            id: "settings__secondary__min_voltage",
            defaultMessage: "Min voltage",
          }),
          type: "float",
          unit: "V",
        },
        {
          id: "maxVoltage",
          name: intl.formatMessage({
            id: "settings__secondary__max_voltage",
            defaultMessage: "Max voltage",
          }),
          type: "float",
          unit: "V",
        },
        {
          id: "cosPhiRC",
          name: intl.formatMessage({
            id: "settings__secondary__cos_phi_rc",
            defaultMessage: "Power factor RC",
          }),
          type: "float",
          unit: "",
        },
        {
          id: "cosPhiRL",
          name: intl.formatMessage({
            id: "settings__secondary__cos_phi_rl",
            defaultMessage: "Power factor RL",
          }),
          type: "float",
          unit: "",
        },
        {
          id: "secondLevel",
          name: intl.formatMessage({
            id: "settings__secondary__second_level",
            defaultMessage: "Second level",
          }),
          type: "bool",
        },
      ]}
    />
  );
};

export default Secondary;
