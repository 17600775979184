import React, { FunctionComponent, useMemo } from "react";
import {
  Stack,
  Text,
  useColorModeValue,
  useTheme,
  Box,
  Image,
} from "@chakra-ui/react";

import { useIntl } from "react-intl";


interface IProps {
  scheduleId: string;
  isActive: boolean;
  index: number;
  onClick: () => void;
}


const ScheduleTab: FunctionComponent<IProps> = ({
  scheduleId,
  isActive,
  onClick,
  index,
  children,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const tc = theme.colors;

  const bgColor = useColorModeValue("gray.100", "gray.900");
  const fgColor = useColorModeValue("white", "black");

  const imageUrl = useMemo<string>(() => {
    switch (scheduleId) {
      case "factory": return "/images/svg/tovarna.svg";
      case "furnace": return "/images/svg/pec.svg";
      default: return "";
    }
  }, [scheduleId]);

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      justifyContent="flex-end"
      alignSelf={isActive ? "flex-end" : undefined}
      w={120}
      pb={3}
      bg={isActive ? "gray.500" : bgColor}
      borderColor={useColorModeValue(tc.white, tc.gray["950"])}
      color={isActive ? fgColor : undefined}
      fontSize="sm"
      cursor={isActive ? undefined : "pointer"}
      onClick={onClick}
    >
      <Box flex={1} minW={"40px"} p={3} pb={0}>
        <Image src={imageUrl} boxSize={"80px"} />
      </Box>
      <Text>
        {children}
      </Text>
    </Stack>
  );
};

export default ScheduleTab;
