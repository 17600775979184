import React, { FunctionComponent, useMemo, ReactNode } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Flex, Heading, Stack, HStack, Spacer } from "@chakra-ui/react";

import SiteMenuItem from "./SiteMenuItem"
import Dashboard from "./scenes/dashboard";
import Battery from "./scenes/battery";
import Converter from "./scenes/converter";
import Ups from "./scenes/ups";
import Grid from "./scenes/grid";
import Pv from "./scenes/pv";
import Supercharger from "./scenes/supercharger";
import Notifications from "./scenes/notifications";
import Settings from "./scenes/settings";
import HVAC from "./scenes/hvac";
import Genset from "./scenes/genset";
import Trading from "./scenes/trading";
import Export from "./scenes/export";
import AnalysisAndGraphs from "./scenes/charts";
import {
  SiteConfig_siteConfig,
  SiteConfig_siteConfig_dashboardConf,
  SiteConfig_siteConfig_batteryConf,
  SiteConfig_siteConfig_converterConf,
  SiteConfig_siteConfig_upsConf,
  SiteConfig_siteConfig_hvacConf,
  SiteConfig_siteConfig_chargersConf,
  SiteConfig_siteConfig_chartsConf,
  SiteConfig_siteConfig_notificationsConf,
  SiteConfig_siteConfig_pvConf,
  SiteConfig_siteConfig_gridConf,
  SiteConfig_siteConfig_settingsConf,
  SiteConfig_siteConfig_manualControlConf,
  SiteConfig_siteConfig_modesConf,
  SiteConfig_siteConfig_gensetsConf,
  SiteConfig_siteConfig_tradingConf,
  SiteConfig_siteConfig_exportConf,
} from "graphql/generated";
import ConnectionStatus from "components/layout/ConnectionStatus";

interface ITab {
  id: string;
  slug: string;
  label: string;
  render: () => ReactNode;
}

interface IProps {
  siteId: string;
  siteConfig: SiteConfig_siteConfig;
}

const SiteDetail: FunctionComponent<IProps> = ({ siteId, siteConfig }) => {
  const intl = useIntl();
  const basePath = `/site/${siteId}`;
  const location = useLocation();

  const tabs = useMemo<Array<ITab>>(() => {
    const availableTabs: Array<ITab> = [];

    const hasAnySettingsTabs = (): boolean => {
      if (!siteConfig.settingsConf) return false;
      return Object.entries(siteConfig.settingsConf).some(([ key, value ]: [ string, any ]) => {
        return /^.+UiVersion$/.test(key) && value !== null;
      });
    }

    if (siteConfig.dashboardConf) {
      availableTabs.push({
        id: "dashboard",
        slug: "",
        label: intl.formatMessage({
          id: "site_nav__dashboard",
          defaultMessage: "Dashboard",
        }),
        render: () => (
          <Dashboard
            siteId={siteId}
            manualControlConf={
              siteConfig.manualControlConf as SiteConfig_siteConfig_manualControlConf
            }
            modesConf={
              siteConfig.modesConf as SiteConfig_siteConfig_modesConf
            }
            dashboardConf={
              siteConfig.dashboardConf as SiteConfig_siteConfig_dashboardConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("battery")) {
      availableTabs.push({
        id: "battery",
        slug: "battery",
        label: intl.formatMessage({
          id: "site_nav__battery",
          defaultMessage: "Battery",
        }),
        render: () => (
          <Battery
            siteId={siteId}
            basePath={basePath}
            batteryConf={
              siteConfig.batteryConf as SiteConfig_siteConfig_batteryConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("trading")) {
      availableTabs.push({
        id: "trading",
        slug: "trading",
        label: intl.formatMessage({
          id: "site_nav__trading",
          defaultMessage: "Trading",
        }),
        render: () => (
          <Trading
            siteId={siteId}
            tradingConf={
              siteConfig.tradingConf as SiteConfig_siteConfig_tradingConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("converter")) {
      availableTabs.push({
        id: "converter",
        slug: "converter",
        label: intl.formatMessage({
          id: "site_nav__converter",
          defaultMessage: "Converters",
        }),
        render: () => (
          <Converter
            siteId={siteId}
            basePath={basePath}
            converterConf={
              siteConfig.converterConf as SiteConfig_siteConfig_converterConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("grid")) {
      availableTabs.push({
        id: "grid",
        slug: "grid",
        label: intl.formatMessage({
          id: "site_nav__grid",
          defaultMessage: "Grid",
        }),
        render: () => (
          <Grid
            siteId={siteId}
            gridConf={siteConfig.gridConf as SiteConfig_siteConfig_gridConf}
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("pv")) {
      availableTabs.push({
        id: "pv",
        slug: "pv",
        label: intl.formatMessage({
          id: "site_nav__pv",
          defaultMessage: "Photovoltaics",
        }),
        render: () => (
          <Pv
            siteId={siteId}
            basePath={basePath}
            pvConf={siteConfig.pvConf as SiteConfig_siteConfig_pvConf}
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("ups")) {
      availableTabs.push({
        id: "ups",
        slug: "ups",
        label: intl.formatMessage({
          id: "site_nav__ups",
          defaultMessage: "UPS",
        }),
        render: () => (
          <Ups
            siteId={siteId}
            basePath={basePath}
            upsConf={siteConfig.upsConf as SiteConfig_siteConfig_upsConf}
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("hvac")) {
      availableTabs.push({
        id: "hvac",
        slug: "hvac",
        label: intl.formatMessage({
          id: "site_nav__ac",
          defaultMessage: "HVAC",
        }),
        render: () => (
          <HVAC
            siteId={siteId}
            hvacConf={siteConfig.hvacConf as SiteConfig_siteConfig_hvacConf}
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("chargers")) {
      availableTabs.push({
        id: "supercharger",
        slug: "supercharger",
        label: intl.formatMessage({
          id: "site_nav__supercharger",
          defaultMessage: "Supercharger",
        }),
        render: () => (
          <Supercharger
            siteId={siteId}
            superchargerConf={
              siteConfig.chargersConf as SiteConfig_siteConfig_chargersConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("gensets")) {
      availableTabs.push({
        id: "genset",
        slug: "genset",
        label: intl.formatMessage({
          id: "site_nav__genset",
          defaultMessage: "Gensets",
        }),
        render: () => (
          <Genset
            siteId={siteId}
            basePath={basePath}
            gensetConf={
              siteConfig.gensetsConf as SiteConfig_siteConfig_gensetsConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("charts")) {
      availableTabs.push({
        id: "charts",
        slug: "charts",
        label: intl.formatMessage({
          id: "site_nav__charts",
          defaultMessage: "Charts",
        }),
        render: () => (
          <AnalysisAndGraphs
            chartsConf={
              siteConfig.chartsConf as SiteConfig_siteConfig_chartsConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("notifications")) {
      availableTabs.push({
        id: "notifications",
        slug: "notifications",
        label: intl.formatMessage({
          id: "site_nav__notifications",
          defaultMessage: "Notifications",
        }),
        render: () => (
          <Notifications
            siteId={siteId}
            timezone={siteConfig.timezone}
            notificationsConf={
              siteConfig.notificationsConf as SiteConfig_siteConfig_notificationsConf
            }
          />
        ),
      });
    }

    if (siteConfig.navTabPriority.includes("export")) {
      availableTabs.push({
        id: "export",
        slug: "export",
        label: intl.formatMessage({
          id: "site_nav__export",
          defaultMessage: "Export",
        }),
        render: () => (
          <Export
            siteId={siteId}
            exportConf={
              siteConfig.exportConf as SiteConfig_siteConfig_exportConf
            }
          />
        ),
      });
    }

    if (hasAnySettingsTabs()) {
      availableTabs.push({
        id: "settings",
        slug: "settings",
        label: intl.formatMessage({
          id: "site_nav__settings",
          defaultMessage: "Settings",
        }),
        render: () => (
          <Settings
            siteId={siteId}
            basePath={basePath}
            settingsConf={
              siteConfig.settingsConf as SiteConfig_siteConfig_settingsConf
            }
          />
        ),
      });
    }

    const dashboardTab = availableTabs.shift() as ITab;
    const priorityTabs = siteConfig.navTabPriority.reduce((acc, tabId) => {
      const tabIndex = availableTabs.findIndex((x) => x.id === tabId);

      if (tabIndex >= 0) {
        acc.push(availableTabs.splice(tabIndex, 1)[0]);
      }

      return acc;
    }, [] as Array<ITab>);

    return [dashboardTab, ...priorityTabs, ...availableTabs];
  }, [siteId, basePath, intl, siteConfig]);

  const currentTab = useMemo(() => {
    let slug = location.pathname.substring(basePath.length);

    if (slug[0] === "/") {
      slug = slug.substring(1);
    }

    let slashIdx = slug.indexOf("/");

    if (slashIdx > 0) {
      slug = slug.substring(0, slashIdx);
    }

    return tabs.find((tab) => tab.slug === slug) || tabs[0];
  }, [tabs, location, basePath]);

  return (
    <Flex flexDirection={"column"} flexGrow={1}>
      <HStack mb={8}>
        <Heading mr={10}>{siteConfig.name}</Heading>

        <Stack direction="row" spacing={2}>
          {tabs.map((tab) => (
            <SiteMenuItem
              siteId={siteId}
              label={tab.label}
              slug={tab.slug}
              selected={tab.slug === currentTab.slug}
            />
          )) }
        </Stack>
        <Spacer />
        <ConnectionStatus />
      </HStack>

      {currentTab.render()}
    </Flex>
  );
};

export default SiteDetail;
