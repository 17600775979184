import React, { FunctionComponent, Dispatch, SetStateAction } from "react";
import {
  HStack,
  Button,
  Slider,
  SliderMark,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
} from "@chakra-ui/react";

interface IProps {
  value: number;
  max: number;
  setValue: Dispatch<SetStateAction<number>>;
}

const PowerControlSlider: FunctionComponent<IProps> = ({
  max,
  value,
  setValue,
}) => {
  return (
    <HStack spacing={4}>
      <Button
        size="sm"
        onClick={() => setValue((s) => (s > -max ? s - 1 : s))}
      >
        -
      </Button>

      <Slider
        aria-label="active-power"
        value={value}
        min={-max}
        max={max}
        onChange={setValue}
      >
        <SliderMark value={-max} mt="1" ml="-2.5" fontSize="sm">
          {-max}
        </SliderMark>
        <SliderMark value={-max * 0.5} mt="1" ml="-2.5" fontSize="sm">
          {-max * 0.5}
        </SliderMark>
        <SliderMark value={0} mt="1" ml="-2.5" fontSize="sm">
          0
        </SliderMark>
        <SliderMark value={max * 0.5} mt="1" ml="-2.5" fontSize="sm">
          {max * 0.5}
        </SliderMark>
        <SliderMark value={max} mt="1" ml="-2.5" fontSize="sm">
          {max}
        </SliderMark>
        <SliderMark
          value={value}
          textAlign="center"
          bg="blue.500"
          color="white"
          mt="-10"
          ml="-5"
          w="12"
        >
          {value}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>

      <Button
        size="sm"
        onClick={() => setValue((s) => (s < max ? s + 1 : s))}
      >
        +
      </Button>
    </HStack>
  );
};

export default PowerControlSlider;
