import React, { FunctionComponent } from "react";
import {
  Box,
  Grid,
  GridItem,
  Stack,
} from "@chakra-ui/react";

import { SiteConfig_siteConfig_chargersConf } from "graphql/generated";
import ChargerPreview from "./ChargerPreview";
import { useLiveMeasurements } from "scenes/site/hooks";
import IframeChart from "components/iframe-chart";


interface IProps {
  siteId: string;
  superchargerConf: SiteConfig_siteConfig_chargersConf;
}

const Supercharger: FunctionComponent<IProps> = ({
  siteId,
  superchargerConf,
}) => {
  const { data } = useLiveMeasurements(siteId, "chargers");

  return (
    <Stack>
      <Grid templateColumns="repeat(3, 1fr)" gap={8} mb={8}>
        {superchargerConf.chargers.map((chargerConf) => {
          const chargerData = data[`charger_${chargerConf.id}`] || {};

          return (
            <GridItem key={chargerConf.id}>
              <ChargerPreview
                title={chargerConf.title}
                data={chargerData}
              />
            </GridItem>
          );
        })}
      </Grid>

      <Box w={"full"}>
        <IframeChart chart={superchargerConf.chart} title="Graph card" />
      </Box>
    </Stack>
  );
};

export default Supercharger;
