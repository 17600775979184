import React, { FunctionComponent, Fragment } from "react";
import {
  Flex,
  PositionProps,
  Skeleton,
  SpaceProps,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

// type TType = "number" | "boolean";
type TValue = string | number | boolean | undefined | Element | null | any;

interface IRow {
  label: string;
  // type: TType;
  unit?: string;
  decimals?: number;
  value: TValue;
}

interface IProps extends SpaceProps, PositionProps {
  inline?: boolean;
  rows: Array<IRow>;
}

function renderValue(row: IRow, inline: boolean) {
  let valueEl = null;
  let unitEl = null;

  if (typeof row.value === "undefined") {
    valueEl = <Skeleton w={10} h={3} />;
  } else if (row.value === null) {
    valueEl = "N/A";
    unitEl = row.unit;
  } else if (typeof row.value === "boolean") {
    valueEl = row.value ? (
      <CheckIcon color="green" />
    ) : (
      <CloseIcon color="red" />
    );
    unitEl = " ";
  } else if (typeof row.value === "number") {
    valueEl = row.value.toFixed(row.decimals === undefined ? decimalsByUnit(row.unit) : row.decimals);
    unitEl = row.unit;
  } else if (typeof row.value === "string") {
    valueEl = row.value;
    unitEl = row.unit || '';
  } else if (typeof row.value === "object") {
    valueEl = row.value;
    unitEl = row.unit;
  }

  return (
    <Fragment>
      <Td textAlign="right" {...resolveCellStyle(inline)}>
        <Flex justify={"flex-end"}>
          {valueEl}
        </Flex>
      </Td>
      <Td w="5%" whiteSpace="pre" {...resolveCellStyle(inline)}>{unitEl}</Td>
    </Fragment>
  );
}

function decimalsByUnit(unit?: string): number {
  switch (unit) {
    case '%': return 0;
    case '': return 0;
    default: return 1;
  }
}

function resolveCellStyle(inline: boolean) {
  return { px: inline ? 1 : 2, py: inline ? 2 : 4, fontSize: "xs" };
}

const StatsTable: FunctionComponent<IProps> = ({
  rows,
  inline = false,
  ...rest
}) => {
  return (
    <Table size="sm" w="full" {...rest}>
      <Tbody>
        {rows.map((row) => {
          return (
            <Tr key={row.label}>
              <Td {...resolveCellStyle(inline)}>{row.label}</Td>
              {renderValue(row, inline)}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default StatsTable;
