import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Image, Flex } from "@chakra-ui/react";

import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";

interface IProps {
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const SwitchCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  const icon_url = (): string => {
    const path = "/images/svg";

    if (data?.is_on === true){
      return `${path}/switch-on.svg`;
    }
    else if (data?.is_on === false){
      return `${path}/switch-off.svg`;
    }
    else {
      return `${path}/switch-none.svg`;
    }
  }

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__switch____title",
        defaultMessage: `{count, plural,
          one {Switch}
          other {Switches}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
    >
      <Flex w={"full"} alignItems={"center"} justifyContent={"center"}>
        <Image
          src={icon_url()}
          boxSize={"150px"}
        />
      </Flex>

    </BaseCard>
  );
};

export default SwitchCard;
