import React, { FunctionComponent } from "react";
import {
  Stack,
  Box,
  HStack,
  Heading,
  IconButton,
  Center,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import times from "ramda/src/times";
import mean from "ramda/src/mean";

import { SiteConfig_siteConfig_batteryConf } from "graphql/generated";
import ModulePreview from "./ModulePreview";
import { useLiveMeasurements } from "scenes/site/hooks";
import StatsTable from "components/stats-table";
import { displayId } from "common/stringUtils"

interface IProps {
  basePath: string;
  siteId: string;
  rackId: string;
  moduleId: string;
  batteryConf: SiteConfig_siteConfig_batteryConf;
  onPrevClick: () => void;
  onNextClick: () => void;
}


const rackImageSrc = (uiVersion: string): URL => {
  switch (uiVersion) {
    case "cabinet":
      return new URL("/images/svg/bateriovy-rack.svg", document.baseURI);
    case "container":
    default:
      return new URL("/images/svg/bateriovy-kontejner3.svg", document.baseURI);
  }
}


const Rack: FunctionComponent<IProps> = ({
  basePath,
  siteId,
  rackId,
  moduleId,
  batteryConf,
  onPrevClick,
  onNextClick,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data } = useLiveMeasurements(siteId, `rack_0_${rackId}`);
  // const moduleIds = times((x) => String(x + 1), batteryConf.modules.length);

  // const { data } = useLiveMeasurements<
  //   RackMeasurementDetail,
  //   RackMeasurementDetailVariables
  // >(
  //   RACK_MEASUREMENT_DETAIL_SUBSCRIPTION,
  //   (data) => data.rackMeasurementDetail.subscriptionToken,
  //   {
  //     variables: {
  //       authToken: user?.token as string,
  //       batteryId: "0",
  //       rackId,
  //       siteId,
  //     },
  //   }
  // );

  // const selectedModuleData = data?.rackMeasurementDetail.modules.find(
  //   (x) => x.moduleId === moduleId
  // );

  // FIXME: replace '0' with batteryId once multiple batteries are available
  const rackData = data[`rack_0_${rackId}`] || {};
  const selectedModuleData = data[`module_0_${rackId}_${moduleId}`] || {};

  const cellTemps = selectedModuleData?.cell_temp || [];
  const cellVoltages = selectedModuleData?.cell_voltage || [];

  return (
    <Stack direction="column">
      <HStack mb={6}>
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          onClick={() => navigate(`${basePath}/battery`)}
        />

        <Heading as="h3" size="lg">
          Rack {displayId(rackId)}
        </Heading>
      </HStack>

      <Stack direction="row" spacing={8}>
        <Box flex={1}>
          {batteryConf.modules.length ? <Grid
            gap={4}
            gridTemplateColumns="1fr"
            gridTemplateRows={times(
              () => "1fr",
              batteryConf.uiModuleColumnLimit
            ).join(" ")}
            gridAutoColumns="1fr"
            gridAutoFlow="column"
          >
            {batteryConf.modules.map((module) => {
              let previewData = { temp: "N/A", voltage: "N/A" }
              const moduleData = data[`module_0_${rackId}_${module.id}`]

              if (moduleData) {
                previewData = {
                  temp: mean(moduleData.cell_temp).toFixed(1),
                  voltage: mean(moduleData.cell_voltage).toFixed(2)
                }
              }

              return (
                <GridItem>
                  <ModulePreview
                    key={module.id}
                    name={`Module ${displayId(module.id)}`}
                    isSelected={module.id === moduleId}
                    data={previewData}
                    onClick={() =>
                      navigate(`${basePath}/battery/${rackId}/${module.id}`)
                    }
                  />
                </GridItem>
              );
            })}
          </Grid>
          :
          <Image src={rackImageSrc(batteryConf.uiVersion).href} />}
        </Box>

        <Box flex={1}>
          <Heading as="h2" size="md" mb={2}>
            Rack {displayId(rackId)}
          </Heading>

          <StatsTable
            mb={6}
            rows={[
              {
                label: intl.formatMessage({
                  id: "rack__connected",
                  defaultMessage: "Rack connected",
                }),
                // type: "boolean",
                value: rackData?.rack_connected,
              },
              {
                label: intl.formatMessage({
                  id: "rack__soc",
                  defaultMessage: "SoC",
                }),
                // type: "number",
                unit: "%",
                decimals: 2,
                value: rackData?.soc,
              },
              {
                label: intl.formatMessage({
                  id: "rack__soh",
                  defaultMessage: "SoH",
                }),
                unit: "%",
                decimals: 1,
                // type: "number",
                value: rackData?.soh,
              },
              {
                label: intl.formatMessage({
                  id: "rack__energy",
                  defaultMessage: "Energy",
                }),
                unit: "kWh",
                decimals: 2,
                // type: "number",
                value: rackData?.available_kwh,
              },
              {
                label: intl.formatMessage({
                  id: "rack__charging_current_limit",
                  defaultMessage: "Charging current limit",
                }),
                unit: "A",
                decimals: 1,
                // type: "number",
                value: rackData?.charging_current,
              },
              {
                label: intl.formatMessage({
                  id: "rack__discharging_current_limit",
                  defaultMessage: "Discharging current limit",
                }),
                unit: "A",
                decimals: 1,
                // type: "number",
                value: rackData?.discharging_current,
              },
              {
                label: intl.formatMessage({
                  id: "rack__current",
                  defaultMessage: "Current",
                }),
                unit: "A",
                decimals: 1,
                // type: "number",
                value: rackData?.current,
              },
              {
                label: intl.formatMessage({
                  id: "rack__voltage",
                  defaultMessage: "Rack voltage",
                }),
                unit: "V",
                decimals: 1,
                // type: "number",
                value: rackData?.rack_voltage,
              },
              {
                label: intl.formatMessage({
                  id: "rack__avg_rack_tmp",
                  defaultMessage: "Average cell temperature",
                }),
                unit: "°C",
                decimals: 1,
                // type: "number",
                value: rackData?.avg_cell_temp,
              },
            ]}
          />

          { !!batteryConf.modules.length && [

          <Heading as="h2" size="md" mb={6}>
            Module {displayId(moduleId)}
          </Heading>,

          <StatsTable
            mb={6}
            rows={[
              {
                label: intl.formatMessage({
                  id: "battery__rack__max_temp",
                  defaultMessage: "Max temperature",
                }),
                unit: "°C",
                decimals: 2,
                value: Math.max(...cellTemps),
              },
              {
                label: intl.formatMessage({
                  id: "battery__rack__min_temp",
                  defaultMessage: "Min temperature",
                }),
                unit: "°C",
                decimals: 2,
                value: Math.min(...cellTemps),
              },
              {
                label: intl.formatMessage({
                  id: "battery__rack__max_voltage",
                  defaultMessage: "Max voltage",
                }),
                unit: "V",
                decimals: 2,
                value: Math.max(...cellVoltages),
              },
              {
                label: intl.formatMessage({
                  id: "battery__rack__min_voltage",
                  defaultMessage: "Min voltage",
                }),
                unit: "V",
                decimals: 2,
                value: Math.min(...cellVoltages),
              }
            ]}
          />

          ]}

          { !!batteryConf.cells.length && [

          <Table size="sm" w="full" mt={6}>
            <Tbody>
              <Tr>
                <Th>
                  {intl.formatMessage({
                    id: "battery__rack__cell",
                    defaultMessage: "Cell",
                  })}
                </Th>
                <Th>
                  {intl.formatMessage({
                    id: "battery__rack__cell_temp",
                    defaultMessage: "Temperature",
                  })}
                </Th>
                <Th>
                  {intl.formatMessage({
                    id: "battery__rack__cell_volt",
                    defaultMessage: "Voltage",
                  })}
                </Th>
              </Tr>
              {batteryConf.cells.map((cell) => {
                // const cellData = selectedModuleData?.cells?.find(
                //   (x) => x.cellId === cell.id
                // );
                let cellTemp = "N/A"
                let cellVoltage = "N/A"

                if (cell.id < (selectedModuleData?.cell_temp?.length || 0)) {
                  cellTemp = selectedModuleData.cell_temp[cell.id].toFixed(1)
                }

                if (cell.id < (selectedModuleData?.cell_voltage?.length || 0)) {
                  cellVoltage = selectedModuleData.cell_voltage[cell.id].toFixed(1)
                }

                return (
                  <Tr key={cell.id}>
                    <Td>{displayId(cell.id)}</Td>
                    <Td>{cellTemp} °C</Td>
                    <Td>{cellVoltage} V</Td>
                  </Tr>
                );

              })}
            </Tbody>
          </Table>
          ]}

        </Box>
      </Stack>

      <Center>
        <HStack>
          <IconButton
            icon={<ArrowLeftIcon />}
            aria-label="Prev"
            onClick={onPrevClick}
          />
          <IconButton
            icon={<ArrowRightIcon />}
            aria-label="Next"
            onClick={onNextClick}
          />
        </HStack>
      </Center>
    </Stack>
  );
};

export default Rack;
