import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import BaseCard, {cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_windTurbine } from "graphql/generated";

interface IProps {
  // data: DashboardMeasurement_dashboardMeasurement_windTurbine | undefined;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const WindTurbineCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__wind_turbine____title",
        defaultMessage: `{count, plural,
          one {Wind turbine}
          other {Wind turbines}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/vetrnna-elektrarna.svg"}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__wind_turbine__kw",
                defaultMessage: "Active power",
              }),
              unit: "kW",
              decimals: 2,
              // type: "number",
              value: data?.active_power,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__wind_turbine__kvar",
                defaultMessage: "Reactive power",
              }),
              unit: "kVAR",
              decimals: 2,
              // type: "number",
              value: data?.reactive_power,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__wind_turbine__hz",
                defaultMessage: "Frequency",
              }),
              unit: "Hz",
              decimals: 1,
              // type: "number",
              value: data?.frequency,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__wind_turbine__cos",
                defaultMessage: "Power factor",
              }),
              //   unit: "°C",
              decimals: 1,
              // type: "number",
              value: data?.cos_phi,
            },
          ]}
        />
    </BaseCard>
  );
};

export default WindTurbineCard;
