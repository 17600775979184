import React, { FunctionComponent } from "react";

import {
  Button,
  ButtonProps,
} from "@chakra-ui/react";


export interface BaseButtonProps extends ButtonProps {
  index: number;
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
}


const BaseButton: FunctionComponent<BaseButtonProps> = ({
  index,
  isActive,
  isDisabled,
  onClick,
  children,
  ...rest
}) => {

  return (
    <>
      <Button
        opacity={isDisabled ? .4 : 1}
        cursor={isDisabled ? "not-allowed" : isActive ? undefined : "pointer"}
        filter={isDisabled ? "grayscale(100%)" : undefined}
        onClick={isDisabled ? () => void(0) : onClick}
        colorScheme={isActive ? "enposol" : "gray"}
        size="md"
        fontSize="sm"
        m={1}
        {...rest}
      >
        {children}
      </Button>
    </>
  );
};

export default BaseButton;
