import React, { FunctionComponent } from "react";
import {
  Box,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

import Card from "components/card"
import StatsTable from "components/stats-table";
import StatusBadge from "scenes/site/components/status-badge";
import InverterState from "./InverterState"
import { displayId } from "common/stringUtils"

// import { PvInvMeasurementBoard_pvInvMeasurementBoard_inverters } from "graphql/generated";

interface IProps {
  title: string;
  // data: PvInvMeasurementBoard_pvInvMeasurementBoard_inverters | undefined;
  data: any;
  onClick?: () => void;
}

function getImageSrc(data: any) {
  return "/images/svg/fotovoltaicky-stridac.svg";
}

const InverterCard: FunctionComponent<IProps> = ({ title, data, onClick }) => {
  const intl = useIntl();

  return (
    <Card
      title={intl.formatMessage({
        id: "pv__inverter__title",
        defaultMessage: "Inverter {id}"
      }, {
        id: displayId(title),
      })}
      {...(onClick && {
        onClick: onClick,
        cursor: "pointer",
      })}
    >
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Image src={getImageSrc(data)} w="50px" flex={1} />

          <Box flex={2} flexShrink={1}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "pv__inverter_status",
                    defaultMessage: "Inverter status",
                  }),
                  unit: "",
                  value: <InverterState state={data.status} />,
                },
                {
                  label: intl.formatMessage({
                    id: "pv__string_status",
                    defaultMessage: "String status",
                  }),
                  unit: "",
                  value: <StatusBadge state={data.status} />,
                },
                {
                  label: intl.formatMessage({
                    id: "pv__active_power_output",
                    defaultMessage: "Active power output",
                  }),
                  unit: 'kW',
                  value: data.active_power,
                },
                {
                  label: intl.formatMessage({
                    id: "pv__active_power_throttling",
                    defaultMessage: "Power throttling",
                  }),
                  unit: '%',
                  value: data.power_throttling,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default InverterCard;
