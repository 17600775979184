import React, { FunctionComponent, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useIntl } from "react-intl";
import {
  SkeletonText,
  Heading,
  Text,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import "./markdown-guide.scss";

interface IProps {
  id: string;
  uiVersion?: string | null;
}

const MarkdownGuide: FunctionComponent<IProps> = ({
  id,
  uiVersion,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState<string>("");
  const intl = useIntl();

  React.useEffect(() => {
    const fileName = (): string => {
      if (uiVersion === "default") {
        return `${id}.md`
      } else {
        return `${id}-${uiVersion}.md` 
      }
    }

    fetch(`/lang/${intl.locale}/settings/${fileName()}`)
      .then((res) => res.text())
      .then((res) => {
        setLoading(false);
        setState(res);
      })
      .catch(() => setLoading(false));
  }, [id, intl.locale, uiVersion]);

  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <div>
      {isLoading ? (
        <SkeletonText mt={4} noOfLines={6} spacing="6" />
      ) : state ? (
        <ReactMarkdown
          className="markdown-guide"
          components={{
            h1: ({ ...props }) => (
              <Heading
                as="h2"
                size="xl"
                mb={4}
                pb={2}
                borderBottom="1px"
                borderColor={borderColor}
                {...props}
              />
            ),
            h2: ({ ...props }) => (
              <Heading
                as="h3"
                size="md"
                mb={2}
                pt={2}
                pb={2}
                borderBottom="1px"
                borderColor={borderColor}
                {...props}
              />
            ),
            h3: ({ ...props }) => (
              <Heading
                as="h4"
                mt={2}
                size="sm"
                pb={2}
                borderColor={borderColor}
                {...props}
              />
            ),
            p: ({ ...props }) => <Text mb={4} {...props} />,
            blockquote: ({ ...props }) => (
              <Box
                borderWidth="2px"
                borderRadius="lg"
                p={4}
                pb={0}
                mb={4}
                boxShadow="md"
                {...props}
              />
            ),
          }}
        >
          {state}
        </ReactMarkdown>
      ) : (
        "Error"
      )}
    </div>
  );
};

export default MarkdownGuide;
