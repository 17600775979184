import { useEffect, useState, useRef } from "react";
import assert from "assert";

import { useGlobalState } from "common/global-state";
import { envVars } from "common/env";
import { delay } from "lodash"
import { WEBSOCKET_RECONNECT_DELAY, SITE_ID_KEY_NAME } from "common/consts"

interface IResult {
  data: Record<string, any>;
}

export function useLiveMeasurements(siteId: string, context: string): IResult {
  const [data, setData] = useState<Record<string, any>>({});
  const subscriptionRef = useRef<any>(null);
  const { user, setConnected } = useGlobalState();

  assert(user);

  useEffect(() => {
    if (subscriptionRef.current) {
      setData({});
      subscriptionRef.current.close();
    }

    const ws_url = (url: string): string => {
      if (url.search(/^ws:/) >= 0) return url;

      const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
      return `${protocol}//${window.location.host}${url}`;
    }

    const connect = () => {
      if (siteId && siteId !== siteOnDisplay()) {
        cancel();
        return;
      }

      console.log("context", context);
      console.log("[WebSocket] Connecting...");
      const socket = new WebSocket(
        ws_url(`${envVars.METRICS_API_URL}?siteId=${siteId}&context=${context}&token=${user.token}`)
      );

      socket.onopen = function (e) {
        setConnected(true);
        console.log("[WebSocket] Connection established");
      };

      socket.onmessage = function (event) {
        setConnected(true);
        const body = JSON.parse(event.data);

        setData((prevData) => {
          return { ...prevData, [body.bucket]: body.payload };
        });
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log("[WebSocket] Connection closed");
        } else {
          setConnected(false);
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          console.log("[WebSocket] Connection broken");

          setData({});
          delay(connect, WEBSOCKET_RECONNECT_DELAY);
        }
      };

      socket.onerror = function (error: any) {
        setConnected(false);
        console.log(`[WebSocket] Connection error ${error.message}`);
      };

      subscriptionRef.current = socket;
    };

    // Cancels reconnect attempt and dismisses the error toast
    const cancel = () => {
      subscriptionRef.current.close();
      return;
    }

    // Retrieves currently displayed siteId kept in local storage
    // Returns either the siteId or empty string (on home)
    const siteOnDisplay = (): String => {
      return localStorage.getItem(SITE_ID_KEY_NAME) || '';
    }

    connect();

    return () => {
      subscriptionRef.current.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, siteId]);

  return {
    data,
  };
}
