import React, { FunctionComponent } from "react";
import { Grid, GridItem, Heading, HStack, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import times from "ramda/src/times";

import { SiteConfig_siteConfig_pvConf } from "graphql/generated";
import PanelCard from "./PanelCard";
import { useLiveMeasurements } from "scenes/site/hooks";
import { displayId } from "common/stringUtils"
import { useIntl } from "react-intl";
// import {
//   PvInvMeasurementDetail,
//   PvInvMeasurementDetailVariables,
// } from "graphql/generated";
// import { PV_INV_MEASUREMENT_DETAIL_SUBSCRIPTION } from "graphql/subscriptions";

interface IProps {
  siteId: string;
  stringId: string;
  basePath: string;
  pvConf: SiteConfig_siteConfig_pvConf;
}

const StringDetail: FunctionComponent<IProps> = ({
  siteId,
  stringId,
  basePath,
  pvConf,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data } = useLiveMeasurements(siteId, `pv_string_${stringId}`);

  return (
    <div>
      <HStack mb={6}>
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back"
          onClick={() => navigate(`${basePath}/pv`)}
        />

        <Heading size="lg">{intl.formatMessage({
            id: "pv__string__title",
            defaultMessage: "String {id}"
          }, {
            id: displayId(stringId),
          })}
        </Heading>
      </HStack>

      <Grid
        templateColumns={`repeat(${pvConf.stringConf.gridWidth}, 1fr)`}
        templateRows={`repeat(${pvConf.stringConf.gridHeight}, 1fr)`}
        gap={8}
      >
        {times(
          (y) =>
            times((x) => {
              const card = pvConf.stringConf.panels.find(
                (c) => c.x === x && c.y === y
              );
              let content = null;

              if (card) {
                const panelData = data[`string_${card.id}`];

                content = <PanelCard data={panelData} title={card.id} />;
              }

              return <GridItem key={`${x}_${y}`}>{content}</GridItem>;
            }, pvConf.stringConf.gridWidth),
          pvConf.stringConf.gridHeight
        )}
      </Grid>
    </div>
  );
};

export default StringDetail;
