import React, { useState, useRef, useEffect } from "react";
import {
  useColorModeValue,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import { Icon } from "@chakra-ui/react";
import { TbKeyboardShow, TbKeyboardHide } from "react-icons/tb";
import { KeyboardReactInterface, KeyboardInput } from "react-simple-keyboard";

import { useToasts } from "common/toasts";
import { LOGIN_MUTATION } from "graphql/mutations";
import { LoginMutation, LoginMutationVariables } from "graphql/generated";
import { useGlobalState } from "common/global-state";
import { defer } from "lodash"

import OnScreenKeyboard from "components/on-screen-keyboard";


const Login = () => {
  const intl = useIntl();
  const { user, login } = useGlobalState();
  const [loginMutation] = useMutation<LoginMutation, LoginMutationVariables>(
    LOGIN_MUTATION
  );
  const [isInvalidLoginCredentials, setInvalidLoginCredentials] = useState<boolean>(false);
  const { pushErrorToast } = useToasts();

  const keyboard = useRef<KeyboardReactInterface>();
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [inputs, setInputs] = useState<{[key: string]: string}>({});
  const [inputName, setInputName] = useState("username");

  const refocusInput = (inputName: string, restoreCaret: boolean = false) => {
    const input = document.getElementById(inputName) as HTMLInputElement;

    if (input) {
      input.focus();

      if (restoreCaret) {
        const keyboardPosition = keyboard?.current?.getCaretPosition();
        const caretPosition = keyboardPosition || input.value.length;

        input.setSelectionRange(caretPosition, caretPosition);
      }
    }
  }

  const handleKeyboardInput = (inputObj: KeyboardInput) => {
    setInputs({...inputObj});
    defer(refocusInput, inputName, true);
  }

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.id;
    const value = event.currentTarget.value;
    const inputObj: KeyboardInput = {...inputs, [name]: value};

    setInputName(name);
    setInputs(inputObj);
    keyboard.current?.replaceInput(inputObj);
  }

  const getInputValue = (inputName: string): string => {
    return inputs[inputName] || "";
  }

  const toggleKeyboard = () => {
    setShowKeyboard(!showKeyboard);
  }

  useEffect(() => {
    inputName && defer(refocusInput, inputName);
  }, [inputName]);

  const bg1 = useColorModeValue("gray.50", "gray.800");
  const bg2 = useColorModeValue("white", "gray.700");

  if (user) {
    return <Navigate to="/home" replace={true} />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    loginMutation({
      variables: {
        username: inputs.username,
        password: inputs.password,
      },
    })
      .then((response) => {
        if (response.data?.login.token) {
          login(response?.data?.login);
        }
      })
      .catch((error) => {
        if (error.message === "invalid_login_credentials") {
          setInvalidLoginCredentials(true);
        } else {
          pushErrorToast(error.message);
        }
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex minH={"100vh"} align={"center"} direction={"column"} justify={"center"} bg={bg1}>
      <Flex align={"center"} direction={"column"} justify={"center"} grow={1}>
        <Stack spacing={8} maxW={"lg"}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>ENPOSOL cloud portal</Heading>
          </Stack>
          <Box rounded={"lg"} bg={bg2} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  id={"username"}
                  value={getInputValue("username")}
                  isInvalid={isInvalidLoginCredentials}
                  onFocus={() => setInputName("username")}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  id={"password"}
                  value={getInputValue("password")}
                  isInvalid={isInvalidLoginCredentials}
                  onFocus={() => setInputName("password")}
                  onChange={handleInputChange}
                />
              </FormControl>
              {isInvalidLoginCredentials && (
                <Text color="red">
                  {intl.formatMessage({
                    id: "login__invalid_credentials",
                    defaultMessage: "Invalid login credentials",
                  })}
                </Text>
              )}
              <Stack spacing={10}>
                {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack> */}
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
        <Button
          size="lg"
          onClick={toggleKeyboard}
          title={intl.formatMessage({
            id: "ui__on_screen_keyboard",
            defaultMessage: "On-Screen Keyboard",
          })
        }>
          <Icon as={showKeyboard ? TbKeyboardShow : TbKeyboardHide} />
        </Button>
        <OnScreenKeyboard
          showWhen={showKeyboard}
          keyboardRef={keyboard}
          onChangeAll={handleKeyboardInput}
          inputName={inputName}
        />
      </Flex>
    </form>
  );
};

export default Login;
