import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_battery } from "graphql/generated";

interface IProps {
  baseUrl: string;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

function getBatteryImageSrc(soc: number | null | undefined) {
  if (soc !== undefined && soc !== null) {
    if (soc > 95) {
      return "/images/svg/baterie100.svg";
    } else if (soc > 75) {
      return "/images/svg/baterie75.svg";
    } else if (soc > 50) {
      return "/images/svg/baterie50.svg";
    } else if (soc > 25) {
      return "/images/svg/baterie25.svg";
    } else {
      return "/images/svg/baterie0.svg";
    }
  } else {
    return "/images/svg/baterie0.svg";
  }
}

const BatteryCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__battery____title",
        defaultMessage: `{count, plural,
          one {Battery}
          other {Batteries}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={getBatteryImageSrc(data?.soc)}
      onClick={() => {
        navigate(`${baseUrl}/battery${config.id ? `/${config.id}` : ''}`);
      }}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__battery__soc",
                defaultMessage: "SoC",
              }),
              unit: "%",
              decimals: 2,
              // type: "number",
              value: data?.soc,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__battery__soh",
                defaultMessage: "SoH",
              }),
              unit: "%",
              decimals: 2,
              // type: "number",
              value: data?.soh,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__battery__energy",
                defaultMessage: "Energy",
              }),
              unit: "kWh",
              decimals: 1,
              // type: "number",
              value: data?.energy,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__battery__temp",
                defaultMessage: "Temperature",
              }),
              unit: "°C",
              decimals: 1,
              // type: "number",
              value: data?.avg_batt_rack_temp,
            },
          ]}
        />
    </BaseCard>
  );
};

export default BatteryCard;
