import React, { FunctionComponent } from "react";
import { Badge, Box, Skeleton, useColorModeValue } from "@chakra-ui/react";


interface IProps {
  state?: boolean;
  color?: string;
}


const OnOffStatusBadge: FunctionComponent<IProps> = ({
  state,
  color = "enposol.500",
}) => {
  const onColor = color;
  const offColor = useColorModeValue("gray.300", "gray.700");

  switch (state) {
    case false:
      return (
        <Badge variant="solid" bg={offColor}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    case true:
      return (
        <Badge variant="solid" bg={onColor}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default OnOffStatusBadge;
