import React, { FunctionComponent, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { Stack, Button, Box, Text, Tabs, TabList, Tab, Badge } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import {
  SiteConfig_siteConfig_notificationsConf,
  SiteNotifications,
  SiteNotificationsVariables,
} from "graphql/generated";
import { SITE_NOTIFICATIONS_QUERY } from "graphql/queries";
import { ACK_ALARMS_MUTATION } from "graphql/mutations";
import NotificationsTable from "./NotificationsTable";
import { useToasts } from "common/toasts";

interface IProps {
  siteId: string;
  notificationsConf: SiteConfig_siteConfig_notificationsConf;
  timezone: string;
}

const Notifications: FunctionComponent<IProps> = ({
  notificationsConf,
  siteId,
  timezone,
}) => {
  const intl = useIntl();
  const [ onlyActive, setOnlyActive ] = useState(true);
  const { data, loading, refetch } = useQuery<
    SiteNotifications,
    SiteNotificationsVariables
  >(SITE_NOTIFICATIONS_QUERY, { variables: { siteId, onlyActive } });
  const { data: countData, refetch: countRefetch } = useQuery<
    SiteNotifications,
    SiteNotificationsVariables
  >(SITE_NOTIFICATIONS_QUERY, { variables: { siteId, onlyActive: true } });
  const activeCount = countData?.siteNotifications?.length || 0;
  const [ackAlarms, { loading: isAckingAlarms }] = useMutation(ACK_ALARMS_MUTATION);
  const { pushSuccessToast, pushErrorToast } = useToasts();

  return (
    <div>
      <Tabs variant='enclosed'
        onChange={(index) => {
          setOnlyActive(index === 0)
          if (onlyActive) countRefetch();
        }}
      >
        <TabList mb={4}>
          <Tab>
            {intl.formatMessage({
              id: "notifications__group__active",
              defaultMessage: "Active",
            })}
            <Badge variant="solid" borderRadius="xl" ml={2} px={2} bg='red.500'>{activeCount}</Badge>
          </Tab>
          <Tab>
            {intl.formatMessage({
              id: "notifications__group__history",
              defaultMessage: "History",
            })}
          </Tab>
        </TabList>
      </Tabs>

      {loading || (data && data?.siteNotifications.length > 0) ? (
        <NotificationsTable
          items={data?.siteNotifications}
          onlyActive={onlyActive}
          timezone={timezone}
        />
      ) : (
        <Box textAlign="center" py={10} px={6}>
          <InfoIcon boxSize={"50px"} color={"blue.500"} />
          <Text color={"gray.500"} mt={6}>
            {intl.formatMessage({
              id: "notifications__no_items",
              defaultMessage: "No notifications have been found"
            })}
          </Text>
        </Box>
      )}

      {onlyActive && <Stack spacing={4} direction="row" align="center" mt={8}>
        <Button
          isDisabled={isAckingAlarms}
          isLoading={isAckingAlarms}
          onClick={() => {
            ackAlarms({ variables: { siteId } })
              .then((res) => {
                refetch();
                countRefetch();
                pushSuccessToast(
                  intl.formatMessage({
                    defaultMessage: "Successfully acknowledged.",
                    id: "site__alarms__ack_success",
                  })
                );
              })
              .catch((reason) => {
                pushErrorToast(String(reason), null);
              });
          }}
        >
          {intl.formatMessage({
            id: "notifications__acknowledge_button",
            defaultMessage: "Acknowledge",
          })}
        </Button>
      </Stack>}
    </div>
  );
};

export default Notifications;
