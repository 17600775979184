import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";

import { GlobalStateProvider } from "common/global-state";
import Login from "./scenes/login";
import Home from "./scenes/home";
import Site from "./scenes/site";

import { theme } from "./theme"
import AppVersion from "components/app-version";


export const App = () => (
  <ChakraProvider theme={theme}>
    <GlobalStateProvider>
      <Routes>
        <Route index element={<Login />} />
        <Route path="home" element={<Home />} />
        {/* <Route path="site/:siteId" element={<Site />} /> */}
        <Route path="site/:siteId/*" element={<Site />}>
          {/* <Route index element={<SiteDashboard />} />*/}
          {/* <Route path="battery" element={<SiteBattery />} />  */}
          {/* <Route path="converter" element={<SiteConverter />} />
          <Route path="grid" element={<SiteGrid />} />
          <Route path="pv" element={<SitePv />} />
          <Route path="supercharger" element={<SiteSupercharger />} /> */}
        </Route>
      </Routes>
    </GlobalStateProvider>
    <AppVersion />
  </ChakraProvider>
);
