import React, { FunctionComponent, useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Text,
} from "@chakra-ui/react";

import BaseButton, { BaseButtonProps } from "./BaseButton";


interface IProps extends BaseButtonProps {
  value: string;
}


const TimePeriodButton: FunctionComponent<IProps> = ({
  value,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const cardLabel = useCallback((id: string): string => {
    switch (id) {
      case "this week":
        return intl.formatMessage({
          id: "export__time_period__this_week",
          defaultMessage: "this week",
        });
      case "this month":
        return intl.formatMessage({
          id: "export__time_period__this_month",
          defaultMessage: "this month",
        });
      case "this quarter":
        return intl.formatMessage({
          id: "export__time_period__this_quarter",
          defaultMessage: "this quarter",
        });
      case "this year":
        return intl.formatMessage({
          id: "export__time_period__this_year",
          defaultMessage: "this year",
        });
      case "previous week":
        return intl.formatMessage({
          id: "export__time_period__previous_week",
          defaultMessage: "previous week",
        });
      case "previous month":
        return intl.formatMessage({
          id: "export__time_period__previous_month",
          defaultMessage: "previous month",
        });
      case "previous quarter":
        return intl.formatMessage({
          id: "export__time_period__previous_quarter",
          defaultMessage: "previous quarter",
        });
      case "previous year":
        return intl.formatMessage({
          id: "export__time_period__previous_year",
          defaultMessage: "previous year",
        });
      default:
        return id;
    }
  }, [intl]);

  return (
    <>
      <BaseButton
        {...rest}
      >
        <Text>
          {cardLabel(value)}
        </Text>
        {children}
      </BaseButton>
    </>
  );
};

export default TimePeriodButton;
