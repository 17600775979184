import React, { FunctionComponent, useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Text,
} from "@chakra-ui/react";

import BaseButton, { BaseButtonProps } from "./BaseButton";


interface IProps extends BaseButtonProps {
  value: string;
}


const ResolutionButton: FunctionComponent<IProps> = ({
  value,
  isDisabled,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const cardLabel = useCallback((id: string): string => {
    switch (id) {
      case "minutes":
        return intl.formatMessage({
          id: "export__time_resolution__minutes",
          defaultMessage: "minutes",
        });
      case "15minutes":
        return intl.formatMessage({
          id: "export__time_resolution__15minutes",
          defaultMessage: "quarter-hours",
        });
      case "hours":
        return intl.formatMessage({
          id: "export__time_resolution__hours",
          defaultMessage: "hours",
        });
      case "days":
        return intl.formatMessage({
          id: "export__time_resolution__days",
          defaultMessage: "days",
        });
      case "weeks":
        return intl.formatMessage({
          id: "export__time_resolution__weeks",
          defaultMessage: "weeks",
        });
      case "months":
        return intl.formatMessage({
          id: "export__time_resolution__months",
          defaultMessage: "months",
        });
      default:
        return id;
    }
  }, [intl]);

  return (
    <>
      <BaseButton
        isDisabled={isDisabled}
        title={isDisabled ? intl.formatMessage({
          id: "export__resolution___disabled",
          defaultMessage: "This resolution is not suitable for chosen time period.",
        }) : undefined}
        {...rest}
      >
        <Text>
          {cardLabel(value)}
        </Text>
        {children}
      </BaseButton>
    </>
  );
};

export default ResolutionButton;
