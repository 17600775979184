import React, { FunctionComponent } from "react";
import { Badge, Skeleton } from "@chakra-ui/react";
import { useIntl } from "react-intl";

interface IProps {
  state?: number;
}

const UPS_STATE = {
  NONE: 0,
  NORMAL: 1,
  OVER_VOLTAGE: 2,
  UNDER_VOLTAGE: 3,
  FREQUENCY_FAILURE: 4,
  BLACKOUT: 5,
  POWER_FAILURE: 6,
}

const UpsState: FunctionComponent<IProps> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case UPS_STATE.NONE:
      return (
        <Badge colorScheme={"gray"}>
          {intl.formatMessage({
            id: "ups__state__none",
            defaultMessage: "-",
          })}
        </Badge>
      )
    case UPS_STATE.NORMAL:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          {intl.formatMessage({
            id: "ups__state__normal",
            defaultMessage: "ON GRID",
          })}
        </Badge>
      )
    case UPS_STATE.OVER_VOLTAGE:
      return (
        <Badge variant="solid" colorScheme={"yellow"}>
          {intl.formatMessage({
            id: "ups__state__overvoltage",
            defaultMessage: "OVERVOLTAGE",
          })}
        </Badge>
      )
    case UPS_STATE.UNDER_VOLTAGE:
      return (
        <Badge variant="solid" colorScheme={"yellow"}>
          {intl.formatMessage({
            id: "ups__state__undervoltage",
            defaultMessage: "UNDERVOLTAGE",
          })}
        </Badge>
      )
    case UPS_STATE.FREQUENCY_FAILURE:
      return (
        <Badge variant="solid" colorScheme={"red"}>
          {intl.formatMessage({
            id: "ups__state__frequency_failure",
            defaultMessage: "FREQUENCY FAILURE",
          })}
        </Badge>
      )
    case UPS_STATE.BLACKOUT:
      return (
        <Badge variant="solid" colorScheme={"blue"}>
          {intl.formatMessage({
            id: "ups__state__blackout",
            defaultMessage: "OFF GRID",
          })}
        </Badge>
      )
    case UPS_STATE.POWER_FAILURE:
      return (
        <Badge variant="solid" colorScheme={"red"}>
          {intl.formatMessage({
            id: "ups__state__power_failure",
            defaultMessage: "POWER FAILURE",
          })}
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default UpsState;
