import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import {
  GetPowerFactorSettings,
  GetPowerFactorSettingsVariables,
} from "graphql/generated";
import { GET_POWER_FACTOR_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";
interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const PowerFactor: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetPowerFactorSettings, GetPowerFactorSettingsVariables>(
    GET_POWER_FACTOR_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      guideId="power-factor"
      uiVersion={uiVersion}
      settingsId="powerFactor"
      settings={data?.getPowerFactorSettings}
      isLoading={loading}
      items={[
        {
          id: "minReactivePower",
          name: intl.formatMessage({
            id: "settings__power_factor__min_reactive_power",
            defaultMessage: "Min reactive power",
          }),
          type: "float",
          unit: "kVAR",
        },
        {
          id: "maxReactivePower",
          name: intl.formatMessage({
            id: "settings__power_factor__max_reactive_power",
            defaultMessage: "Max reactive power",
          }),
          type: "float",
          unit: "kVAR",
        },
        {
          id: "socMin",
          name: intl.formatMessage({
            id: "settings__power_factor__soc_min",
            defaultMessage: "SoC min",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "cosPhiRC",
          name: intl.formatMessage({
            id: "settings__power_factor__cos_phi_plus",
            defaultMessage: "Power factor +",
          }),
          type: "float",
          unit: "",
        },
        {
          id: "cosPhiRL",
          name: intl.formatMessage({
            id: "settings__power_factor__cos_phi_minus",
            defaultMessage: "Power factor -",
          }),
          type: "float",
          unit: "",
        },
      ]}
    />
  );
};

export default PowerFactor;
