import React, { FunctionComponent } from "react";

import { Box } from "@chakra-ui/react";


interface IProps {
  code: string;
  size?: number;
  bg?: string;
}

interface SpriteSlot {
  [index: string]: number;
}


const CountryFlag: FunctionComponent<IProps> = ({ code, size, bg }) => {
  const slotsTotal = 4;
  const flagWidth = size || 40;
  const flagHeight = flagWidth * 0.75;
  const slots: SpriteSlot = {
    "en": 1,
    "cs": 2,
    "de": 3,
  };

  return (
    <Box
      w={`${flagWidth}px`}
      h={`${flagHeight}px`}
      bg={bg}
      sx={{
        backgroundImage: "url(/images/svg/languages.svg)",
        backgroundSize: flagWidth * slotsTotal,
        backgroundPosition: -(slots[code] * flagWidth),
      }}
    />
  );
};

export default CountryFlag;
