import React, { FunctionComponent, useMemo } from "react";
import { Stack, Box, VStack, Heading, Image, Center } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import GensetPreview from "./GensetPreview";
import GensetStats from "./GensetStats";
import {
  SiteConfig_siteConfig_gensetsConf,
} from "graphql/generated";
import { useLiveMeasurements } from "scenes/site/hooks";
import { displayId } from "common/stringUtils"


interface IProps {
  siteId: string;
  basePath: string;
  gensetConf: SiteConfig_siteConfig_gensetsConf;
}


const gensetImageSrc = (uiVersion: string): URL => {
  switch (uiVersion) {
    default:
      return new URL("/images/svg/genset.svg", document.baseURI);
  }
}


const Genset: FunctionComponent<IProps> = ({
  siteId,
  basePath,
  gensetConf,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const gensetId = useMemo(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/genset\/([\w-]+)/);

    if (!m) {
      return gensetConf.gensets[0].id;
    }

    return m[1];
  }, [location, basePath, gensetConf]);

  const { data } = useLiveMeasurements(siteId, `genset_${gensetId}`);

  return (
    <Box>
      <Stack direction="row" spacing={12} h={360}>
        <Box flex={1}>
          <VStack spacing={2} mb={6}>
            {gensetConf.gensets.map((conf) => {
              const previewData = data[`genset_${conf.id}`];

              return (
                <GensetPreview
                  key={conf.id}
                  name={conf.id}
                  state={previewData?.status}
                  isSelected={conf.id === gensetId}
                  onClick={() => {
                    navigate(`${basePath}/genset/${conf.id}`, {
                      replace: true,
                    });
                  }}
                />
              );
            })}
          </VStack>
        </Box>

        <Box flex={2}>
          <Heading as="h3" size="lg" mb={8}>
            {intl.formatMessage({
              id: "genset___title",
              defaultMessage: "Genset {id}",
            }, {
              id: displayId(gensetId),
            })}
          </Heading>

          <Box width="100%">
            <GensetStats data={data[`genset_${gensetId}`] || {}} />
          </Box>
        </Box>

        <Center flex={1}>
          <Image src={gensetImageSrc(gensetConf.uiVersion).href} />
        </Center>
      </Stack>
    </Box>
  );
};

export default Genset;
