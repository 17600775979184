import React, { FunctionComponent } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import {
  GetGeneralSettings,
  GetGeneralSettingsVariables,
} from "graphql/generated";
import { GET_GENERAL_SETTINGS_QUERY } from "graphql/queries";
import { PURGE_ALARMS_MUTATION } from "graphql/mutations";

import SettingsFormPage from "../../components/SettingsFormPage";


interface IProps {
  siteId: string;
  uiVersion?: string | null;
}


const General: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const [purgeAlarms, { loading: isPurgingAlarms }] = useMutation(PURGE_ALARMS_MUTATION);
  const { pushSuccessToast, pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetGeneralSettings, GetGeneralSettingsVariables>(
    GET_GENERAL_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <>
    <SettingsFormPage
      siteId={siteId}
      settingsId="generalSettings"
      settings={data?.getGeneralSettings}
      isLoading={loading}
      guideId="general"
      uiVersion={uiVersion}
      items={[
        {
          id: "socMin",
          name: intl.formatMessage({
            id: "settings__general__soc_min",
            defaultMessage: "SoC min",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "socMax",
          name: intl.formatMessage({
            id: "settings__general__soc_max",
            defaultMessage: "SoC max",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "cellMinV",
          name: intl.formatMessage({
            id: "settings__general__cell_min_voltage",
            defaultMessage: "Cell min voltage",
          }),
          type: "float",
          unit: "V",
        },
        {
          id: "cellMaxV",
          name: intl.formatMessage({
            id: "settings__general__cell_max_voltage",
            defaultMessage: "Cell max voltage",
          }),
          type: "float",
          unit: "V",
        },
        {
          id: "maxTempTFRoom",
          name: intl.formatMessage({
            id: "settings__general__max_temp_transformer_room",
            defaultMessage: "Max temperature of transformer",
          }),
          type: "float",
          unit: "°C",
        },
        {
          id: "maxTempTF",
          name: intl.formatMessage({
            id: "settings__general__max_temp_transformer_widing",
            defaultMessage: "Max temperature transformer winding",
          }),
          type: "float",
          unit: "°C",
        },
        {
          id: "convertorRamp",
          name: intl.formatMessage({
            id: "settings__general__convertor_ramp_of_change",
            defaultMessage: "Convertor ramp of change",
          }),
          type: "float",
          unit: "kW/s",
        },
      ]}
    />
    <Stack spacing={4} direction="row" justify="right" mt={8}>
      <Button
        leftIcon={<DeleteIcon />}
        variant={"outline"}
        colorScheme={"red"}
        isDisabled={isPurgingAlarms}
        isLoading={isPurgingAlarms}
        onClick={() => {
          purgeAlarms({ variables: { siteId } })
            .then((res) => {
              pushSuccessToast(
                intl.formatMessage({
                  defaultMessage: "Alarms successfully purged from the database.",
                  id: "site__alarms__purge_success",
                })
              );
            })
            .catch((reason) => {
              pushErrorToast(String(reason), null);
            });
        }}
      >
        {intl.formatMessage({
          id: "settings__general__purge_alarms_button",
          defaultMessage: "Purge Alarms",
        })}
      </Button>
    </Stack>
    </>
  );
};

export default General;
