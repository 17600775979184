import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Collapse,
  IconButton,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

// import { HvacMeasurementBoard_hvacMeasurementBoard_hvac } from "graphql/generated";
import Card from "components/card";
import CardTitle from "components/card-title";
import StatsTable from "components/stats-table";
import HVACUnitControls from "./HVACUnitControls";
import { displayId } from "common/stringUtils"


interface IProps {
  siteId: string;
  hvacId: number;
  title: string;
  data: any;
}


function getImageSrc(data: any) {
  if (data?.hvac_on_off) {
    return "/images/svg/klimatizace-zapnuta.svg";
  } else {
    return "/images/svg/klimatizace-vypnuta.svg";
  }
//   if (!data?.hvac_state || data.hvac_state <= 1) {
//     return "/images/svg/klimatizace-vypnuta.svg";
//   } else if (data.hvacState > 6) {
//     return "/images/svg/klimatizace-ochlazuje.svg";
//   } else {
//     return "/images/svg/klimatizace-zahriva.svg";
//   }
}


const HVACUnitPreview: FunctionComponent<IProps> = ({
  siteId,
  hvacId,
  title,
  data,
}) => {
  const intl = useIntl();
  const { isOpen: controlsOpen, onToggle: toggleControls } = useDisclosure();

  return (
    <Card>
      <CardTitle
        title={intl.formatMessage({
          id: "hvac__title",
          defaultMessage: "HVAC {id}"
        }, {
          id: displayId(title),
        })}
      >
        <IconButton
          aria-label="Settings"
          bg={useColorModeValue("white", "gray.800")}
          size="sm"
          m={1}
          onClick={toggleControls}
          icon={<SettingsIcon />}
          title={intl.formatMessage({
            id: "hvac__settings",
            defaultMessage: "Settings",
          })}
        />
      </CardTitle>
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Box position="relative">
            <Image src={getImageSrc(data)} boxSize="130px" flex={1} />
          </Box>

          <Box flex={2}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "hvac__current_temp",
                    defaultMessage: "Current temperature",
                  }),
                  unit: "°C",
                  decimals: 1,
                  value: data?.current_temp,
                },
                {
                  label: intl.formatMessage({
                    id: "hvac__hum_curr",
                    defaultMessage: "Current humidity",
                  }),
                  unit: "%",
                  value: data?.current_humidity,
                },
                {
                  label: intl.formatMessage({
                    id: "hvac__on_off",
                    defaultMessage: "On/Off",
                  }),
                  value: data?.hvac_on_off,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
      <Collapse in={controlsOpen} unmountOnExit>
        <HVACUnitControls
          siteId={siteId}
          hvacId={hvacId}
          toggle={toggleControls}
        />
      </Collapse>
    </Card>
  );
};

export default HVACUnitPreview;
