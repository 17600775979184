import React, { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import { useGlobalState } from "common/global-state";
import { LIST_SITES_QUERY } from "graphql/queries";
import { SITE_ID_KEY_NAME } from "common/consts";
import { ListSites } from "graphql/generated";

import { Layout } from "components/layout";
import SitesOverview from "./SitesOverview"

import "./honeycomb.scss";


const Home: FunctionComponent<{}> = () => {
  const { user } = useGlobalState();
  const { data } = useQuery<ListSites>(LIST_SITES_QUERY);

  if (!user) {
    return <Navigate to="/" replace={true} />;
  }

  localStorage.setItem(SITE_ID_KEY_NAME, String());

  return (
    <Layout>
      <Box mt={8}>
        { data?.listSites && (
          <SitesOverview
            sites={data?.listSites}
          />
        ) }
      </Box>
    </Layout>
  );
};

export default Home;
