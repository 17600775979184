import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_factory } from "graphql/generated";

interface IProps {
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const FactoryCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__factory____title",
        defaultMessage: `{count, plural,
          one {Factory}
          other {Factories}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/tovarna.svg"}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__factory__kw",
                defaultMessage: "Active power",
              }),
              unit: "kW",
              decimals: 2,
              // type: "number",
              value: data?.active_power,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__factory__kwh_24h",
                defaultMessage: "Day consumption",
              }),
              unit: "kWh/d",
              decimals: 0,
              // type: "number",
              value: data?.day_consumption_active_power,
            },
          ]}
        />
    </BaseCard>
  );
};

export default FactoryCard;
