import React, { FunctionComponent } from "react";
import {
  Stat,
  Flex,
  Box,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";

interface IBatteryRackModuleData {
  temp: number | string;
  voltage: number | string;
}

interface IProps {
  name: string;
  data: IBatteryRackModuleData;
  isSelected: boolean;
  onClick: () => void;
}

const ModulePreview: FunctionComponent<IProps> = ({
  name,
  data,
  isSelected,
  onClick,
}) => {
  return (
    <Stat
      w="full"
      px={{ base: 2, md: 4 }}
      py={"3"}
      shadow={"xl"}
      border={"8px solid"}
      borderColor={useColorModeValue(
        isSelected ? "enposol.500" : "gray.400",
        isSelected ? "enposol.500" : "gray.600"
      )}
      rounded={"lg"}
      cursor="pointer"
      onClick={onClick}
    >
      <Flex justify={"space-between"}>
        <Box>
          <StatLabel fontWeight={"medium"} isTruncated>
            {name}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {data.temp} °C, {data.voltage} V
          </StatNumber>
        </Box>
      </Flex>
    </Stat>
  );
};

export default ModulePreview;
