import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import { IItem } from "components/settings-table/types";

import {
  GetPeakShavingAlgorithmSettings,
  GetPeakShavingAlgorithmSettingsVariables,
} from "graphql/generated";
import { GET_PEAK_SHAVING_ALGORITHM_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const PeakShavingAlgorithm: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetPeakShavingAlgorithmSettings, GetPeakShavingAlgorithmSettingsVariables>(
    GET_PEAK_SHAVING_ALGORITHM_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  const settingsItems = useMemo<IItem[]>(() => {
    switch(uiVersion) {
      default:
        return [
          {
            id: "socMin",
            name: intl.formatMessage({
              id: "settings__peak_shaving__soc_min",
              defaultMessage: "SoC min",
            }),
            type: "float",
            unit: "%",
          },
          {
            id: "socMax",
            name: intl.formatMessage({
              id: "settings__peak_shaving__soc_max",
              defaultMessage: "SoC max",
            }),
            type: "float",
            unit: "%",
          },
          {
            id: "reservedPower",
            name: intl.formatMessage({
              id: "settings__peak_shaving__reserved_consumption_from_grid",
              defaultMessage: "Reserved power from grid",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "gridOverflowLimit",
            name: intl.formatMessage({
              id: "settings__peak_shaving__limit_production_to_grid",
              defaultMessage: "Limit production to grid",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "peakShavingMinUnderproduction",
            name: intl.formatMessage({
              id: "settings__peak_shaving__min_underproduction",
              defaultMessage: "Minimal underproduction before peak shaving",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "peakShavingMinOverproduction",
            name: intl.formatMessage({
              id: "settings__peak_shaving__min_overproduction",
              defaultMessage: "Minimal overproduction before peak shaving",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "cyclePrice",
            name: intl.formatMessage({
              id: "settings__peak_shaving__cycle_price",
              defaultMessage: "Cycle price",
            }),
            type: "float",
            unit: "€",
          },
          {
            id: "chpEngineHourCost",
            name: intl.formatMessage({
              id: "settings__peak_shaving__chp_engine_hour_cost",
              defaultMessage: "CHP engine hour cost",
            }),
            type: "float",
            unit: "€/h",
          },
          {
            id: "accumulationTempMin",
            name: intl.formatMessage({
              id: "settings__peak_shaving__temp_min",
              defaultMessage: "Temperature min",
            }),
            type: "float",
            unit: "°C",
          },
          {
            id: "accumulationTempMax",
            name: intl.formatMessage({
              id: "settings__peak_shaving__temp_max",
              defaultMessage: "Temperature max",
            }),
            type: "float",
            unit: "°C",
          },
        ]
    }
  }, [uiVersion, intl]);

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      guideId="peak-shaving-algorithm"
      uiVersion={uiVersion}
      settingsId="peakShavingAlgorithm"
      settings={data?.getPeakShavingAlgorithmSettings}
      isLoading={loading}
      items={settingsItems}
    />
  );
};

export default PeakShavingAlgorithm;
