import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

import BaseCard from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";


interface IProps {
  config: SiteConfig_siteConfig_dashboardConf_components;
}


const UnsupportedCard: FunctionComponent<IProps> = ({
  config,
}) => {
  const intl = useIntl();

  return (
    <BaseCard
      bgColor={useColorModeValue("gray.200", "gray.800")}
      fontSize={"xs"}
    >
      <Flex
        w={"full"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {intl.formatMessage({
          id: "dashboard___unsupported_card",
          defaultMessage: "Unsupported component",
        })}
        <Text
          px={1}
          mx={1}
          bgColor={useColorModeValue("gray.300", "gray.700")}
        >
          {config.cardId}
        </Text>
      </Flex>
    </BaseCard>
  );
};

export default UnsupportedCard;
