import React, { FunctionComponent } from "react";
import { Badge, Skeleton, Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";


interface IProps {
  state?: number;
}

const CHP_STATE = {
  NONE: 0,
  OK: 1,
  WARNING: 2,
  OFF: 3,
  ERROR: 4,
}


const CHPState: FunctionComponent<IProps> = ({ state }) => {
  const intl = useIntl();

  switch (state) {
    case CHP_STATE.NONE:
      return (
        <Badge variant={"outline"} colorScheme={"gray"} title={intl.formatMessage({
            id: "chp__state__none",
            defaultMessage: "No status",
          })}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    case CHP_STATE.OK:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          {intl.formatMessage({
            id: "chp__state__ok",
            defaultMessage: "OK",
          })}
        </Badge>
      )
    case CHP_STATE.WARNING:
      return (
        <Badge variant="solid" colorScheme={"yellow"}>
          {intl.formatMessage({
            id: "chp__state__warning",
            defaultMessage: "WARNING",
          })}
        </Badge>
      )
    case CHP_STATE.OFF:
      return (
        <Badge variant="solid" colorScheme={"gray"}>
          {intl.formatMessage({
            id: "chp__state__off",
            defaultMessage: "OFF",
          })}
        </Badge>
      )
    case CHP_STATE.ERROR:
      return (
        <Badge variant="solid" colorScheme={"red"}>
          {intl.formatMessage({
            id: "chp__state__error",
            defaultMessage: "ERROR",
          })}
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default CHPState;
