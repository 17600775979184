import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_grid } from "graphql/generated";

interface IProps {
  baseUrl: string;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const GridCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__grid____title",
        defaultMessage: `{count, plural,
          one {Grid}
          other {Grids}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/grid.svg"}
      onClick={() => {
        navigate(`${baseUrl}/grid${config.id ? `/${config.id}` : ''}`);
      }}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__grid__kw",
                defaultMessage: "Active power",
              }),
              unit: "kW",
              decimals: 2,
              // type: "number",
              value: data?.active_power_consumption,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__grid__kvar",
                defaultMessage: "Reactive power",
              }),
              unit: "kVAR",
              decimals: 2,
              // type: "number",
              value: data?.reactive_power_consumption,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__grid__hz",
                defaultMessage: "Frequency",
              }),
              unit: "Hz",
              decimals: 1,
              // type: "number",
              value: data?.frequency,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__grid__cos",
                defaultMessage: "Power factor",
              }),
              decimals: 2,
              // type: "number",
              value: data?.power_factor,
            },
          ]}
        />
    </BaseCard>
  );
};

export default GridCard;
