import React, { FunctionComponent, useMemo } from "react";
import { Stack, VStack, Box, Image, Center } from "@chakra-ui/react";
// import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import UnitPreview from "./UnitPreview";
import {
  SiteConfig_siteConfig_upsConf,
  // UpsMeasurementBoard,
  // UpsMeasurementBoardVariables,
} from "graphql/generated";
// import { UPS_MEASUREMENT_BOARD_SUBSCRIPTION } from "graphql/subscriptions";
import { useLiveMeasurements } from "scenes/site/hooks";
import UpsStats from "./UpsStats";
import { displayId } from "common/stringUtils"

interface IProps {
  siteId: string;
  basePath: string;
  upsConf: SiteConfig_siteConfig_upsConf;
}

const Ups: FunctionComponent<IProps> = ({ siteId, basePath, upsConf }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const upsId = useMemo(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/ups\/([\w-]+)/);

    if (!m) {
      return upsConf.ups[0].id;
    }

    return m[1];
  }, [location, basePath, upsConf]);

  const { data } = useLiveMeasurements(siteId, `ups_${upsId}`);

  // const { data } = useLiveMeasurements<
  //   UpsMeasurementBoard,
  //   UpsMeasurementBoardVariables
  // >(
  //   UPS_MEASUREMENT_BOARD_SUBSCRIPTION,
  //   (data) => data.upsMeasurementBoard.subscriptionToken,
  //   {
  //     variables: {
  //       authToken: user?.token as string,
  //       upsId,
  //       siteId,
  //     },
  //   }
  // );

  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <VStack spacing={2} mb={6}>
          {upsConf.ups.map((conf) => {
            const upsData = data[`ups_${conf.id}`];

            return (
              <UnitPreview
                key={conf.id}
                id={conf.id}
                name={`UPS ${displayId(conf.id)}`}
                isSelected={upsId === conf.id}
                state={upsData?.status}
                basePath={basePath}
                onClick={() => {
                  navigate(`${basePath}/ups/${conf.id}`, { replace: true });
                }}
              />
            );
          })}
        </VStack>
      </Box>
      <Box flex={1}>
        <UpsStats data={data[`ups_${upsId}`] || {}} />
      </Box>
      <Center flex={1}>
        <Image src="/images/svg/ups.svg" />
      </Center>
    </Stack>
  );
};

export default Ups;
