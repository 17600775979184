import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_transformer } from "graphql/generated";

interface IProps {
  // data: DashboardMeasurement_dashboardMeasurement_transformer | undefined;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const TransformerCard: FunctionComponent<IProps> = ({ data, config }) => {
  const intl = useIntl();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__transformer____title",
        defaultMessage: `{count, plural,
          one {Transformer}
          other {Transformers}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/transformator.svg"}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__transformer__t1",
                defaultMessage: "Temperature 1",
              }),
              //   unit: "%",
              decimals: 1,
              unit: "°C",
              // type: "number",
              value: data?.t1,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__transformer__t2",
                defaultMessage: "Temperature 2",
              }),
              //   unit: "%",
              decimals: 1,
              unit: "°C",
              // type: "number",
              value: data?.t2,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__transformer__t3",
                defaultMessage: "Temperature 3",
              }),
              //   unit: "kWh",
              unit: "°C",
              decimals: 1,
              // type: "number",
              value: data?.t3,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__transformer__t4",
                defaultMessage: "Temperature 4",
              }),
              unit: "°C",
              decimals: 1,
              // type: "number",
              value: data?.t4,
            },
          ]}
        />
    </BaseCard>
  );
};

export default TransformerCard;
