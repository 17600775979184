import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import { IItem } from "components/settings-table/types";
import {
  GetPrimaryRegulationSettings,
  GetPrimaryRegulationSettingsVariables,
} from "graphql/generated";
import { GET_PRIMARY_REGULATION_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";
// import { SET_PRIMARY_REGULATION_SETTINGS_MUTATION } from "graphql/mutations";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const Primary: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetPrimaryRegulationSettings, GetPrimaryRegulationSettingsVariables>(
    GET_PRIMARY_REGULATION_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  const settingsItems = (uiVersion: string | null | undefined): Array<IItem> => {
    switch (uiVersion) {
      case "aeg": return [
        {
          id: "powerMin",
          name: intl.formatMessage({
            id: "settings__primary__power_min_value",
            defaultMessage: "Power min value",
          }),
          type: "float",
          unit: "kW",
        },
        {
          id: "powerMax",
          name: intl.formatMessage({
            id: "settings__primary__power_max_value",
            defaultMessage: "Power max value",
          }),
          type: "float",
          unit: "kW",
        },
        {
          id: "fpDroop",
          name: intl.formatMessage({
            id: "settings__primary__fp_droop",
            defaultMessage: "Power and frequency droop",
          }),
          type: "float",
          step: 0.1,
          unit: "%",
        },
        {
          id: "frequencySetpoint",
          name: intl.formatMessage({
            id: "settings__primary__frequency_setpoint",
            defaultMessage: "Frequency setpoint",
          }),
          type: "float",
          unit: "Hz",
        },
        {
          id: "truePowerSetpoint",
          name: intl.formatMessage({
            id: "settings__primary__true_power_setpoint",
            defaultMessage: "True power setpoint",
          }),
          type: "float",
          unit: "kW",
        },
      ]
      case "universal":
      default: return [
        {
          id: "powerMin",
          name: intl.formatMessage({
            id: "settings__primary__power_min_value",
            defaultMessage: "Power min value",
          }),
          // defaultValue: -240,
          // defaultValue: values?.powerMin,
          type: "float",
          unit: "kW",
        },
        {
          id: "powerMax",
          name: intl.formatMessage({
            id: "settings__primary__power_max_value",
            defaultMessage: "Power max value",
          }),
          // defaultValue: 240,
          // defaultValue: values?.powerMax,
          type: "float",
          unit: "kW",
        },
        {
          id: "minDeltaF",
          name: intl.formatMessage({
            id: "settings__primary__min_delta_frequency",
            defaultMessage: "Min delta frequency",
          }),
          // defaultValue: -6,
          // defaultValue: values?.minDeltaF,
          type: "float",
          unit: "Hz",
        },
        {
          id: "maxDeltaF",
          name: intl.formatMessage({
            id: "settings__primary__max_delta_frequency",
            defaultMessage: "Max delta frequency",
          }),
          // defaultValue: 5,
          // defaultValue: values?.maxDeltaF,
          type: "float",
          unit: "Hz",
        },
        {
          id: "posDeadBandF",
          name: intl.formatMessage({
            id: "settings__primary__plus_dead_band_frequency",
            defaultMessage: "Plus dead band frequency",
          }),
          // defaultValue: 2,
          // defaultValue: values?.posDeadBandF,
          type: "float",
          unit: "Hz",
        },
        {
          id: "negDeadBandF",
          name: intl.formatMessage({
            id: "settings__primary__minus_dead_band_frequency",
            defaultMessage: "Minus dead band frequency",
          }),
          // defaultValue: -2,
          // defaultValue: values?.negDeadBandF,
          type: "float",
          unit: "Hz",
        },
        {
          id: "frequency",
          name: intl.formatMessage({
            id: "settings__primary__frequency",
            defaultMessage: "Frequency",
          }),
          // defaultValue: 50,
          // defaultValue: values?.frequency,
          type: "float",
          unit: "Hz",
        },
      ]
    }
  }


  return (
    <SettingsFormPage
      siteId={siteId}
      settingsId="primaryRegulation"
      settings={data?.getPrimaryRegulationSettings}
      isLoading={loading}
      guideId="primary"
      uiVersion={uiVersion}
      // saveMutation={SET_PRIMARY_REGULATION_SETTINGS_MUTATION}
      items={settingsItems(uiVersion)}
    />
  );

  // const intl = useIntl();
  // const { pushSuccessToast, pushErrorToast } = useToasts();

  // const { data, loading } = useQuery<GetSettings, GetSettingsVariables>(
  //   GET_SETTINGS_QUERY,
  //   { variables: { siteId } }
  // );

  // const { state, tableProps } = useSettingsTable(
  //   [
  //     {
  //       id: "powerMax",
  //       name: intl.formatMessage({
  //         id: "settings__primary__power_max_value",
  //         defaultMessage: "Power max value",
  //       }),
  //       // defaultValue: 240,
  //       // defaultValue: values?.powerMax,
  //       type: "float",
  //       unit: "kW",
  //     },
  //     {
  //       id: "powerMin",
  //       name: intl.formatMessage({
  //         id: "settings__primary__power_min_value",
  //         defaultMessage: "Power min value",
  //       }),
  //       // defaultValue: -240,
  //       // defaultValue: values?.powerMin,
  //       type: "float",
  //       unit: "kW",
  //     },
  //     {
  //       id: "maxDeltaF",
  //       name: intl.formatMessage({
  //         id: "settings__primary__max_delta_frequency",
  //         defaultMessage: "Max delta frequency",
  //       }),
  //       // defaultValue: 5,
  //       // defaultValue: values?.maxDeltaF,
  //       type: "float",
  //       unit: "Hz",
  //     },
  //     {
  //       id: "minDeltaF",
  //       name: intl.formatMessage({
  //         id: "settings__primary__min_delta_frequency",
  //         defaultMessage: "Min delta frequency",
  //       }),
  //       // defaultValue: -6,
  //       // defaultValue: values?.minDeltaF,
  //       type: "float",
  //       unit: "Hz",
  //     },
  //     {
  //       id: "posDeadBandF",
  //       name: intl.formatMessage({
  //         id: "settings__primary__plus_dead_band_frequency",
  //         defaultMessage: "Plus dead band frequency",
  //       }),
  //       // defaultValue: 2,
  //       // defaultValue: values?.posDeadBandF,
  //       type: "float",
  //       unit: "Hz",
  //     },
  //     {
  //       id: "negDeadBandF",
  //       name: intl.formatMessage({
  //         id: "settings__primary__minus_dead_band_frequency",
  //         defaultMessage: "Minus dead band frequency",
  //       }),
  //       // defaultValue: -2,
  //       // defaultValue: values?.negDeadBandF,
  //       type: "float",
  //       unit: "Hz",
  //     },
  //     {
  //       id: "frequency",
  //       name: intl.formatMessage({
  //         id: "settings__primary__frequency",
  //         defaultMessage: "Frequency",
  //       }),
  //       // defaultValue: 50,
  //       // defaultValue: values?.frequency,
  //       type: "float",
  //       unit: "Hz",
  //     },
  //   ],
  //   omit(["__typename"], data?.getSettings.primaryRegulation)
  // );

  // const [setSettings, { loading: isSaving }] = useMutation<
  //   SetSettings,
  //   SetSettingsVariables
  // >(SET_SETTINGS_MUTATION);

  // return (
  //   <Stack direction="row" spacing={8}>
  //     <Box flex={1}>
  //       <MarkdownGuide id="primary" />
  //     </Box>
  //     <Box flex={1}>
  //       <SettingsTable {...tableProps} isDisabled={isSaving} />

  //       <Box display="flex" mt={8}>
  //         <Box flex={2}></Box>
  //         <Button
  //           size="md"
  //           colorScheme="yellow"
  //           flex={1}
  //           isLoading={loading || isSaving}
  //           onClick={() => {
  //             setSettings({
  //               variables: {
  //                 siteId,
  //                 input: state as any,
  //               },
  //             })
  //               .then(() => {
  //                 pushSuccessToast(
  //                   intl.formatMessage({
  //                     defaultMessage: "Successfully saved",
  //                     id: "site__settings__save_success",
  //                   })
  //                 );
  //               })
  //               .catch((reason) => {
  //                 pushErrorToast(String(reason));
  //               });
  //           }}
  //         >
  //           {intl.formatMessage({
  //             id: "settings___apply",
  //             defaultMessage: "Apply",
  //           })}
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Stack>
  // );
};

export default Primary;
