import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import BaseCard, { cardLabel } from "./BaseCard";
import GensetIndicators from "../../genset/GensetIndicators";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";


interface IProps {
  baseUrl: string;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}


const GensetCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__gensets____title",
        defaultMessage: `{count, plural,
          one {Genset}
          other {Gensets}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/genset.svg"}
      onClick={() => {
        navigate(`${baseUrl}/genset${config.id ? `/${config.id}` : ''}`);
      }}
    >
      <GensetIndicators data={data} inline />
    </BaseCard>
  );
};

export default GensetCard;
