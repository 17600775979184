import React, { FunctionComponent } from "react";
import { Image, Flex } from "@chakra-ui/react";

import BaseCard from "./BaseCard";

interface IProps {}

const LogoCard: FunctionComponent<IProps> = () => {
  return (
    <BaseCard>
      <Flex w={"full"} alignItems={"center"} justifyContent={"center"}>
        <Image src="/images/logo-enposol-symbol.svg" boxSize="180px" />
      </Flex>
    </BaseCard>
  );
};

export default LogoCard;
