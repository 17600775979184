import React, { FunctionComponent } from "react";
import { Stack, Flex, Box, Image, StyleProps } from "@chakra-ui/react";

import Card from "components/card"
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";

interface IProps extends StyleProps {
  title?: string;
  image?: string;
  onClick?: () => void;
  isWrappedInside?: boolean;
}

export interface ICardLabel {
  label: string | null;
  count: number;
}

export const cardLabel = (config: SiteConfig_siteConfig_dashboardConf_components): ICardLabel => {
  const cardLabelOrId = (): string | null => {
    if (config.label !== null) return config.label;
    if (config.id !== null) return config.id;
    return null;
  }

  return {
    label: cardLabelOrId(),
    count: config.grouping ? 2 : 1,
  }
}


const BaseCard: FunctionComponent<IProps> = ({
  title,
  image,
  onClick,
  children,
  isWrappedInside = true,
  ...rest
}) => {
  return (
    <Card
      title={title}
      w={"full"}
      h={230}
      cursor={onClick ? "pointer" : "auto"}
      onClick={onClick}
      {...rest}
    >
      {isWrappedInside ? (
        <Stack
          p={4}
          pt={title ? 2 : 4}
          flex={1}
          alignItems={"center"}
          justifyContent={title ? "flex-start" : "center"}
        >
          <Stack direction={"row"} w={"full"}>
            { image && (
              <Box flex={1} minW={"40px"}>
                <Image src={image} boxSize={"130px"} />
              </Box>
            ) }
            <Flex flex={2} alignItems={"center"}>
              {children}
            </Flex>
          </Stack>
        </Stack>
      ) : (
        children
      )}
    </Card>
  );
};

export default BaseCard;
