import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Stack, Button, Box, Table, Tbody, Tr, Td, Thead, Th, HStack } from "@chakra-ui/react";

import { ITradingConsumer } from ".";
import SettingsValue from "./components/SettingsValue";
import { IItem, TValue, ResettableValue } from "./types";
import { DeleteIcon } from "@chakra-ui/icons";
import ResetButton from "components/settings-table/ResetButton";
import { displayNumberDecimals } from "components/settings-table/NumberInput";


interface IProps {
  // siteId: string;
  uiVersion?: string | null;
  data: ITradingConsumer;
  setConsumer: (index: number, consumers: ITradingConsumer) => void;
}



const Consumer: FunctionComponent<IProps> = ({
  // siteId,
  uiVersion,
  setConsumer,
  data,
}) => {
  const intl = useIntl();

  const items = useMemo<IItem[]>(() => [
    {
      id: "title",
      value: new ResettableValue<string>(data.title),
      name: intl.formatMessage({
        id: "settings__trading__consumer_title",
        defaultMessage: "Title",
      }),
      type: "string",
      // unit: "%",
    },
    {
      id: "price_electro",
      value: new ResettableValue<number>(data.price_electro),
      name: intl.formatMessage({
        id: "settings__trading__consumer_price_electro",
        defaultMessage: "Electricity price",
      }),
      type: "float",
      unit: "€",
    },
    {
      id: "price_heat",
      value: new ResettableValue<number>(data.price_heat),
      name: intl.formatMessage({
        id: "settings__trading__consumer_price_heat",
        defaultMessage: "Heat price",
      }),
      type: "float",
      unit: "€",
    },
  ], [intl, data])

  const setValue = useCallback((item: IItem, index: number) => (value: TValue) => {
    console.log("SET", value, data, item)
    item.value.val = value;
    // data.title = item.value.val;
    // data[index].value.val = value
    setConsumer(index, data)
  }, [data, setConsumer]);

  const resetValue = useCallback((item: IItem) => () => {
    item.value.reset();
    console.log(item.value.val)
  }, []);

  return (
    <>
      <Tr>
        {/* <Td>{data.id}</Td> */}
        {items.map((item, index) => (
          <Td>
            <HStack>
              <SettingsValue
                item={item}
                value={item.value}
                originalValue={item.value.val}
                setValue={setValue(item, index)}
              />
              <ResetButton
                size="xs"
                ariaLabel="Reset"
                title={intl.formatMessage({
                  id: "settings__reset_value",
                  defaultMessage: "Restore `{value}`"
                }, {
                  value: item.type === "float" ? displayNumberDecimals(item.value.val as number) : item.value?.toString(),
                })}
                showIf={item.value.unoriginal}
                onClick={resetValue(item)}
              />
            </HStack>
          </Td>
        ))}
        {/* <Td>
          <Button>
            <DeleteIcon/>
          </Button>
        </Td> */}
      </Tr>
    </>
  );
};

export default Consumer;
