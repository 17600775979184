import { useToast } from "@chakra-ui/react";

interface IToasts {
  pushSuccessToast: (message: string, duration?: number | null) => string | number | undefined;
  pushWarningToast: (message: string, duration?: number | null) => string | number | undefined;
  pushErrorToast: (message: string, duration?: number | null) => string | number | undefined;
  pushInfoToast: (message: string, duration?: number | null) => string | number | undefined;
  closeToast: (toastId: string | number) => null;
}

export function useToasts(): IToasts {
  const toast = useToast({
    isClosable: true,
    position: "top",
  });

  return {
    pushSuccessToast: (message, duration = 5000) => {
      return toast({
        description: message,
        status: "success",
        duration,
      });
    },
    pushWarningToast: (message, duration = 5000) => {
      return toast({
        description: message,
        status: "warning",
        duration,
      });
    },
    pushErrorToast: (message, duration = null) => {
      return toast({
        description: message,
        status: "error",
        duration,
      });
    },
    pushInfoToast: (message, duration = 5000) => {
      return toast({
        description: message,
        status: "info",
        duration,
      });
    },
    closeToast: (toastId) => {
      toast.close(toastId);
      return null;
    },
  };
}
