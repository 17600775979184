import React, { FunctionComponent, useEffect } from "react";
import {
  useColorMode,
  Button,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

import { useUserPreference } from "common/user-preferences";


interface IProps {
  bg: string;
  hoverBg: string;
}


const DarkModeSwitch: FunctionComponent<IProps> = ({
  bg,
  hoverBg,
}) => {
  const [ darkMode, setDarkMode ] = useUserPreference<boolean>("ui__dark_mode");
  const { colorMode, setColorMode } = useColorMode();

  const switchColorMode = () => {
    setDarkMode(!darkMode);
  }

  useEffect(() => {
    setColorMode(darkMode ? "dark" : "light");
  }, [darkMode, setColorMode]);

  return (
    <Button
      onClick={switchColorMode}
      bg={ bg }
      _hover={{ bg: hoverBg }}
    >
      {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
    </Button>
  );
};

export default DarkModeSwitch;
