import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import { IItem } from "components/settings-table/types";

import {
  GetPeakShavingSettings,
  GetPeakShavingSettingsVariables,
} from "graphql/generated";
import { GET_PEAK_SHAVING_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const PeakShaving: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetPeakShavingSettings, GetPeakShavingSettingsVariables>(
    GET_PEAK_SHAVING_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  const settingsItems = useMemo<IItem[]>(() => {
    switch(uiVersion) {
      default:
        return [
          {
            id: "socMin",
            name: intl.formatMessage({
              id: "settings__peak_shaving__soc_min",
              defaultMessage: "SoC min",
            }),
            type: "float",
            unit: "%",
          },
          {
            id: "socMax",
            name: intl.formatMessage({
              id: "settings__peak_shaving__soc_max",
              defaultMessage: "SoC max",
            }),
            type: "float",
            unit: "%",
          },
          {
            id: "reservedPower",
            name: intl.formatMessage({
              id: "settings__peak_shaving__reserved_consumption_from_grid",
              defaultMessage: "Reserved power from grid",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "gridChargingLimit",
            name: intl.formatMessage({
              id: "settings__peak_shaving__limit_cons_from_grid",
              defaultMessage: "Limit charging from grid",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "gridOverflowLimit",
            name: intl.formatMessage({
              id: "settings__peak_shaving__limit_production_to_grid",
              defaultMessage: "Limit production to grid",
            }),
            type: "float",
            unit: "kW",
          },
          {
            id: "gridCharging",
            name: intl.formatMessage({
              id: "settings__peak_shaving__charging_from_grid",
              defaultMessage: "Charging from grid",
            }),
            type: "bool",
          },
          {
            id: "pvThrottling",
            name: intl.formatMessage({
              id: "settings__peak_shaving__switching_off",
              defaultMessage: "Switch off / Power throttling PVE",
            }),
            type: "bool",
          },
        ]
    }
  }, [uiVersion, intl]);

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      guideId="peak-shaving"
      uiVersion={uiVersion}
      settingsId="peakShaving"
      settings={data?.getPeakShavingSettings}
      isLoading={loading}
      items={settingsItems}
    />
  );
};

export default PeakShaving;
