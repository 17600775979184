import React, { FunctionComponent, useRef, SVGAttributes } from "react";
import { Area, Line, select, ScaleContinuousNumeric } from "d3";

import { IPlotData } from "../types"


interface IProps extends SVGAttributes<SVGPathElement> {
  data: IPlotData[];
  scaleX: ScaleContinuousNumeric<number, number>;
  scaleY: ScaleContinuousNumeric<number, number>;
  generator: Line<IPlotData> | Area<IPlotData>;
}


const PathBase: FunctionComponent<IProps> = ({
  generator,
  data,
  scaleX,
  scaleY,
  className = "path",
  stroke = null,
  strokeWidth = 1,
  fill = "none",
  ...rest
}) => {
  const svgRef = useRef<SVGGElement>(null);
  const svgEl = select(svgRef.current);

  svgEl
    .attr("stroke", stroke)
    .attr("stroke-width", strokeWidth)
    .attr("fill", fill)
    .attr("class", className)

    .selectAll<SVGPathElement, IPlotData>('.trace')
    .data([data])
    .join("path")
      .attr("class", 'trace')
      // .transition()
      //   .duration(1000)
      //   .ease(easeCubicInOut)
        .attr("d", generator)
      ;

  return (
    <>
      <g ref={svgRef} {...rest} />
    </>
  )
}

export default PathBase;
