import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import assert from "assert";

import General from "./scenes/general";
import Primary from "./scenes/primary";
import Secondary from "./scenes/secondary";
import PeakShaving from "./scenes/peak-shaving";
import PeakShavingAlgorithm from "./scenes/peak-shaving-algorithm";
import TimedPeak from "./scenes/timed-peak";
import Offgrid from "./scenes/offgrid";
import PowerFactor from "./scenes/power-factor";
import LoadLeveling from "./scenes/load-leveling";
import Consumption from "./scenes/consumption";
import Trading from "./scenes/trading";
import { SiteConfig_siteConfig_settingsConf } from "graphql/generated";

interface IProps {
  siteId: string;
  basePath: string;
  settingsConf: SiteConfig_siteConfig_settingsConf;
}

interface ITab {
  id: string;
  slug: string;
  label: string;
  render: () => ReactNode;
}

const Settings: FunctionComponent<IProps> = ({
  siteId,
  basePath,
  settingsConf,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = useMemo<Array<ITab>>(() => {
    const availableTabs: Array<ITab> = [];

    if (settingsConf.tabPriority.includes("general")) {
      availableTabs.push({
        id: "general",
        slug: "general",
        label: intl.formatMessage({
          id: "settings_nav__general",
          defaultMessage: "General settings",
        }),
        render: () => <General siteId={siteId} uiVersion={settingsConf.generalTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("primary")) {
      availableTabs.push({
        id: "primary",
        slug: "primary",
        label: intl.formatMessage({
          id: "settings_nav__primary",
          defaultMessage: "Primary regulation",
        }),
        render: () => <Primary siteId={siteId} uiVersion={settingsConf.primaryTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("secondary")) {
      availableTabs.push({
        id: "secondary",
        slug: "secondary",
        label: intl.formatMessage({
          id: "settings_nav__secondary",
          defaultMessage: "Secondary regulation",
        }),
        render: () => <Secondary siteId={siteId} uiVersion={settingsConf.secondaryTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("peak-shaving")) {
      availableTabs.push({
        id: "peak-shaving",
        slug: "peak-shaving",
        label: intl.formatMessage({
          id: "settings_nav__peak_shaving",
          defaultMessage: "Peak shaving",
        }),
        render: () => <PeakShaving siteId={siteId} uiVersion={settingsConf.peakShavingTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("peak-shaving-algorithm")) {
      availableTabs.push({
        id: "peak-shaving-algorithm",
        slug: "peak-shaving-algorithm",
        label: intl.formatMessage({
          id: "settings_nav__peak_shaving_algorithm",
          defaultMessage: "Peak shaving",
        }),
        render: () => <PeakShavingAlgorithm siteId={siteId} uiVersion={settingsConf.peakShavingAlgorithmTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("timed-peak")) {
      availableTabs.push({
        id: "timed-peak",
        slug: "timed-peak",
        label: intl.formatMessage({
          id: "settings_nav__timed_peak",
          defaultMessage: "Timed peak shaving",
        }),
        render: () => <TimedPeak siteId={siteId} uiVersion={settingsConf.timedPeakTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("offgrid")) {
      availableTabs.push({
        id: "offgrid",
        slug: "offgrid",
        label: intl.formatMessage({
          id: "settings_nav__offgrid",
          defaultMessage: "Off-grid",
        }),
        render: () => <Offgrid siteId={siteId} uiVersion={settingsConf.offgridTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("power-factor")) {
      availableTabs.push({
        id: "power-factor",
        slug: "power-factor",
        label: intl.formatMessage({
          id: "settings_nav__power_factor",
          defaultMessage: "Power factor mode",
        }),
        render: () => <PowerFactor siteId={siteId} uiVersion={settingsConf.powerFactorTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("load-leveling")) {
      availableTabs.push({
        id: "load-leveling",
        slug: "load-leveling",
        label: intl.formatMessage({
          id: "settings_nav__load_leveling",
          defaultMessage: "Load leveling",
        }),
        render: () => <LoadLeveling siteId={siteId} uiVersion={settingsConf.loadLevelingTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("consumption")) {
      availableTabs.push({
        id: "consumption",
        slug: "consumption",
        label: intl.formatMessage({
          id: "settings_nav__consumption",
          defaultMessage: "Consumption plan",
        }),
        render: () => <Consumption siteId={siteId} uiVersion={settingsConf.consumptionTabUiVersion} />,
      });
    }

    if (settingsConf.tabPriority.includes("trading")) {
      availableTabs.push({
        id: "trading",
        slug: "trading",
        label: intl.formatMessage({
          id: "settings_nav__trading",
          defaultMessage: "Trading",
        }),
        render: () => <Trading siteId={siteId} uiVersion={settingsConf.tradingTabUiVersion} />,
      });
    }

    const priorityTabs = settingsConf.tabPriority.reduce((acc, tabId) => {
      const tabIndex = availableTabs.findIndex((x) => x.id === tabId);

      if (tabIndex >= 0) {
        acc.push(availableTabs.splice(tabIndex, 1)[0]);
      }

      return acc;
    }, [] as Array<ITab>);

    return [...priorityTabs, ...availableTabs];
  }, [settingsConf, intl, siteId]);

  // const currentSlug = useMemo(() => {
  //   const slug = location.pathname.substring(basePath.length);
  //   const m = slug.match(/settings\/(\w+)/);

  //   if (!m) {
  //     return null;
  //   }

  //   return m[1];
  // }, [location, basePath]);

  const [tabIndex, setTabIndex] = React.useState(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/settings\/([\w-]+)/);

    if (!m) {
      return 0;
    }

    return tabs.findIndex((tab) => tab.slug === m[1]);
  });

  const handleTabsChange = (index: number) => {
    const desiredTab = tabs[index];
    assert(desiredTab);
    navigate(`${basePath}/settings/${desiredTab.slug}`, { replace: true });
    setTabIndex(index);
  };

  return (
    <div>
      <Tabs
        size="md"
        variant="enclosed"
        index={tabIndex}
        onChange={handleTabsChange}
        isLazy
      >
        <TabList>
          {tabs.map((tab) => {
            return <Tab key={tab.slug}>{tab.label}</Tab>;
          })}
        </TabList>

        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.slug}>{tab.render()}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Settings;
