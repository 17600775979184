import React, { FunctionComponent, useMemo } from "react";

import {
  TableRowProps,
  Th,
  Tr,
  Td,
  useColorModeValue,
} from "@chakra-ui/react";

import { JSONArray, JSONValue } from "./types";

import { extent, scaleLinear } from "d3";


interface IProps extends TableRowProps {
  values: JSONArray;
  colors: string[];
}


const ValuesHeatmapRow: FunctionComponent<IProps> = ({
  values,
  colors,
  ...rest
}) => {
  const dataDomain = useMemo<number[]>(() => extent<number>(values.map(d => d as number)) as number[], [values]);
  const colorScale = scaleLinear<JSONValue, string>().domain(dataDomain).range(colors);

  return (
    <>
      <Tr {...rest}>
        <Th pt={0} position="sticky" left={0} bg={useColorModeValue("white", "gray.950")}/>
        {values.map((value, index) => <Td background={colorScale(value as number)} pt={.5} pb={.5} borderRadius={0} /> )}
      </Tr>
    </>
  )
}

export default ValuesHeatmapRow;
