import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import {
  GetOffgridSettings,
  GetOffgridSettingsVariables,
} from "graphql/generated";
import { GET_OFFGRID_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";


interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const Offgrid: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetOffgridSettings, GetOffgridSettingsVariables>(
    GET_OFFGRID_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      guideId="offgrid"
      uiVersion={uiVersion}
      settingsId="offgrid"
      settings={data?.getOffgridSettings}
      isLoading={loading}
      items={[
        {
          id: "droopOnOff",
          name: intl.formatMessage({
            id: "settings__offgrid__droop_on_off",
            defaultMessage: "Droop on/off",
          }),
          type: "bool",
        },
        {
          id: "droopLevel",
          name: intl.formatMessage({
            id: "settings__offgrid__droop_level",
            defaultMessage: "Droop level",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "nominalFrequency",
          name: intl.formatMessage({
            id: "settings__offgrid__nominal_frequency",
            defaultMessage: "Nominal frequency",
          }),
          type: "float",
          unit: "Hz",
        },
        {
          id: "minFrequency",
          name: intl.formatMessage({
            id: "settings__offgrid__min_frequency",
            defaultMessage: "Min frequency",
          }),
          type: "float",
          unit: "Hz",
        },
        {
          id: "maxFrequency",
          name: intl.formatMessage({
            id: "settings__offgrid__max_frequency",
            defaultMessage: "Max frequency",
          }),
          type: "float",
          unit: "Hz",
        },
        {
          id: "socMin",
          name: intl.formatMessage({
            id: "settings__offgrid__soc_min",
            defaultMessage: "SoC min",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "socMax",
          name: intl.formatMessage({
            id: "settings__offgrid__soc_max",
            defaultMessage: "SoC max",
          }),
          type: "float",
          unit: "%",
        },
      ]}
    />
  );
};

export default Offgrid;
