import React, { FunctionComponent } from "react";
import {
  Box,
  Image,
  Stack,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

import Card from "components/card"
import StatsTable from "components/stats-table";
import { displayId } from "common/stringUtils"
// import { PvInvMeasurementDetail_pvInvMeasurementDetail_panels } from "graphql/generated";

interface IProps {
  title: string;
  data: any;
}

function getImageSrc(data: any) {
  return "/images/svg/fotovoltaicky-panel.svg";
}

const PanelCard: FunctionComponent<IProps> = ({ title, data }) => {
  const intl = useIntl();

  return (
    <Card
      title={intl.formatMessage({
        id: "pv__panel__title",
        defaultMessage: "Panel {id}"
      }, {
        id: displayId(title),
      })}
    >
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Image src={getImageSrc(data)} w="50px" flex={1} />

          <Box flex={2} flexShrink={1}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "pv__panel_status",
                    defaultMessage: "Status",
                  }),
                  value: data?.panel_status,
                },
                {
                  label: intl.formatMessage({
                    id: "pv__panel_voltage",
                    defaultMessage: "Voltage",
                  }),
                  unit: "V",
                  value: data?.panel_v,
                },
                {
                  label: intl.formatMessage({
                    id: "pv__panel_current",
                    defaultMessage: "Current",
                  }),
                  unit: "A",
                  value: data?.panel_a,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PanelCard;
