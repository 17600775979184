import React, { FunctionComponent } from "react";
import {
  Stack,
  Flex,
  StyleProps,
  useColorModeValue,
} from "@chakra-ui/react";

import CardTitle from "components/card-title"

interface IProps extends StyleProps {
  title?: string;
  onClick?: () => void;
}

const Card: FunctionComponent<IProps> = ({
  title,
  children,
  ...rest
}) => {
  return (
    <Flex
      direction={"column"}
      align={"stretch"}
      boxShadow={"xl"}
      rounded={"md"}
      overflow={"hidden"}
      border="3px solid"
      borderColor={ useColorModeValue("gray.200", "gray.800") }
      position="relative"
      {...rest}
    >
      {title && (
        <CardTitle
          title={title}
        />
      )}
      <Stack
        flexGrow={1}
      >
        {children}
      </Stack>
    </Flex>
  );
};

export default Card;
