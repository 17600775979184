import { IPlotData } from "scenes/site/components/chart/types";

import { IScheduleMoment } from "./types";


export const fillData = (data: IPlotData[]): IPlotData[] => {
  data = data.flat();
  const firstMinute = 0;
  const lastMinute = 24 * 60 - 1;
  const firstMoment = data.slice(0, 1).pop();
  const lastMoment = data.slice(-1).pop();

  if (!firstMoment || firstMoment.x !== firstMinute) data.unshift({ x: firstMinute, y: 0 });
  if (!lastMoment || lastMoment.x !== lastMinute) data.push({ x: lastMinute, y: lastMoment ? lastMoment.y : 0 });

  return data
}

export const fullData = (data: IPlotData[]): IPlotData[] => {
  data = data.flat();
  let step = 0;
  let y = 0;

  return Array.from({ length: 24 * 60 }).map((_, i) => {
    if (i === data[step].x) step = step + 1;

    y = data[step - 1].y;

    return { x: i, y }
  })
}

export const sortedMomentsIndex = (array: IScheduleMoment[], value: IScheduleMoment) => {
  let low = 0;
  let high = array.length;

  while (low < high) {
    let mid = (low + high) >>> 1;
    let val = array[mid];
    if (val.hour * 60 + val.minute < value.hour * 60 + value.minute) low = mid + 1;
    else high = mid;
  }
  return low;
}
