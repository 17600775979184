import React, { FunctionComponent } from "react";
import {
  Stack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import times from "ramda/src/times";

import HVACUnitPreview from "./HVACUnitPreview";
import { SiteConfig_siteConfig_hvacConf } from "graphql/generated";
import { useLiveMeasurements } from "scenes/site/hooks";


interface IProps {
  siteId: string;
  hvacConf: SiteConfig_siteConfig_hvacConf;
}

const HVAC: FunctionComponent<IProps> = ({ siteId, hvacConf }) => {
  const { data } = useLiveMeasurements(siteId, "hvac");

  return (
    <Stack direction="column">
      <Grid
        templateColumns={`repeat(${hvacConf.gridWidth}, 1fr)`}
        templateRows={`repeat(${hvacConf.gridHeight}, 1fr)`}
        gap={8}
      >
        {times(
          (y) =>
            times((x) => {
              const card = hvacConf.hvac.find(
                (rec) => rec.x === x && rec.y === y
              );
              let content = null;

              if (card) {
                const hvacData = data[`hvac_${card.id}`];

                content = <HVACUnitPreview
                  title={card.id}
                  data={hvacData}
                  siteId={siteId}
                  hvacId={+card.id}
                />;
              }

              return <GridItem key={`${x}_${y}`}>{content}</GridItem>;
            }, hvacConf.gridWidth),
          hvacConf.gridHeight
        )}
      </Grid>
    </Stack>
  );
};

export default HVAC;
