import React, { FunctionComponent } from "react";
import { Box } from "@chakra-ui/react";
import "./dashboard.scss";

import Measurements from "./Measurements";
import Controls from "./Controls";
import {
  SiteConfig_siteConfig_dashboardConf,
  SiteConfig_siteConfig_manualControlConf,
  SiteConfig_siteConfig_modesConf,
} from "graphql/generated";
import { useLiveMeasurements } from "scenes/site/hooks";

interface IProps {
  siteId: string;
  dashboardConf: SiteConfig_siteConfig_dashboardConf;
  modesConf: SiteConfig_siteConfig_modesConf;
  manualControlConf: SiteConfig_siteConfig_manualControlConf;
}

const Dashboard: FunctionComponent<IProps> = ({
  siteId,
  manualControlConf,
  modesConf,
  dashboardConf,
}) => {
  const { data } = useLiveMeasurements(siteId, "dashboard");

  return (
    <div>
      <Measurements
        siteId={siteId}
        gridWidth={dashboardConf.gridWidth}
        gridHeight={dashboardConf.gridHeight}
        components={dashboardConf.components}
        connections={dashboardConf.connections}
        data={data}
        flowsData={data?.flows?.flows}
      />

      <Box mt={10}>
        <Controls
          siteId={siteId}
          manualControlConf={manualControlConf}
          modesConf={modesConf}
          state={data?.modes || {}}
        />
      </Box>
    </div>
  );
};

export default Dashboard;
