import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Image,
  Stack,
} from "@chakra-ui/react";

import Card from "components/card";
import StatsTable from "components/stats-table";


interface IProps {
  title: string;
  data: any;
}


function getImageSrc(data: any) {
  return "/images/svg/rychlonabijeci-stanice.svg";
}


const ChargerPreview: FunctionComponent<IProps> = ({
  title,
  data,
}) => {
  const intl = useIntl();

  return (
    <Card
      title={title}
    >
      <Stack
        p={6}
        pt={0}
      >
        <Stack direction="row" w="full">
          <Box position="relative">
            <Image src={getImageSrc(data)} boxSize="150px" flex={1} mt={2} />
          </Box>

          <Box flex={2}>
            <StatsTable
              inline
              rows={[
                {
                  label: intl.formatMessage({
                    id: "supercharger__charged_capacity_today",
                    defaultMessage: "Charged capacity today",
                  }),
                  unit: "kWh",
                  decimals: 2,
                  value: data.charged_capacity_today,
                },
                {
                  label: intl.formatMessage({
                    id: "supercharger__power_consumption_today",
                    defaultMessage: "Power consumption today",
                  }),
                  unit: "kWh",
                  decimals: 2,
                  value: data.power_consumption_today,
                },
                {
                  label: intl.formatMessage({
                    id: "supercharger__power_output_now_ac",
                    defaultMessage: "AC power output",
                  }),
                  decimals: 2,
                  value: data.ac_power_output,
                  unit: "kW",
                },
                {
                  label: intl.formatMessage({
                    id: "supercharger__power_output_now_dc",
                    defaultMessage: "DC power output",
                  }),
                  decimals: 2,
                  value: data.dc_power_output,
                  unit: "kW",
                },
                {
                  label: intl.formatMessage({
                    id: "supercharger__num_of_transactions",
                    defaultMessage: "Number of transactions",
                  }),
                  value: data.number_of_transaction,
                },
                {
                  label: intl.formatMessage({
                    id: "supercharger__num_of_chargers_in_use",
                    defaultMessage: "Number of chargers in use",
                  }),
                  value: data.number_of_chargers_in_use,
                },
              ]}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ChargerPreview;
