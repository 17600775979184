import React, { FunctionComponent, useCallback } from "react";

import {
  TableCellProps,
  Td,
  Box,
} from "@chakra-ui/react";

import { JSONValue, SharedProps } from "./types";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { format } from "d3";


interface IProps extends TableCellProps, SharedProps {
  value: JSONValue;
  label: string;
  units?: string;
  isCurrentHour?: boolean;
}


const RowValue: FunctionComponent<IProps> = ({
  value,
  label,
  units = "",
  cellWidth,
  cellPaddingX,
  isCurrentHour,
  ...rest
}) => {
  const valueColor = useCallback((value: JSONValue): string | undefined => {
    if (value !== null && value < 0) return "red.500";
  }, []);

  const valueWeight = useCallback((value: JSONValue): string | undefined => {
    return isCurrentHour ? "bold" : "regular";
  }, [isCurrentHour]);

  const reasonablePrecision = useCallback((value: number): number | string => {
    const abs = Math.abs(value);

    if (abs < 10) return format(".1f")(value);
    else if (abs > 1000000) return `${ reasonablePrecision(value / 1000000)}M`;
    else if (abs > 1000) return `${ reasonablePrecision(value / 1000)}k`;
    else return Math.round(value);
  }, []);

  const tooltip = useCallback((): string => {
    if (value === null) return "";

    const base = `${label} ${value?.toString()}`

    return units ? `${base} ${units}` : base
  }, [label, value, units]);

  const booleanValue = useCallback((value: boolean) => {
    return value ? <CheckIcon color="enposol.500"/> : <CloseIcon color="red.500"/>;
  }, []);

  return (
    <>
      <Td
        title={tooltip()}
        textAlign="right"
        color={valueColor(value)}
        fontWeight={valueWeight(value)}
        fontSize={"xs"}
        px={`${cellPaddingX}px`}
        pt={0}
        verticalAlign={"top"}
        {...rest}
      >
        <Box width={cellWidth} whiteSpace="pre" overflow="hidden">
          {value && typeof(value) == "number" && reasonablePrecision(value) }
          {value !== undefined && typeof(value) == "boolean" && booleanValue(value) }
        </Box>
      </Td>
    </>
  )
}

export default RowValue;
