import React, { FunctionComponent } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Spinner, Center } from "@chakra-ui/react";

import { Layout } from "components/layout";
import { useGlobalState } from "common/global-state";
import { SITE_CONFIG_QUERY } from "graphql/queries";
import { SITE_ID_KEY_NAME } from "common/consts"
import { SiteConfig, SiteConfigVariables } from "graphql/generated";
import SiteDetailComponent from "./SiteDetail";

const Site: FunctionComponent<{}> = () => {
  const { user } = useGlobalState();
  const { siteId } = useParams();
  const { data, loading } = useQuery<
    SiteConfig,
    SiteConfigVariables
  >(SITE_CONFIG_QUERY, { variables: { siteId: siteId as string } });

  if (!user) {
    return <Navigate to="/" replace={true} />;
  }

  localStorage.setItem(SITE_ID_KEY_NAME, String(siteId));

  return (
    <Layout>
      {loading || !data ? (
        <Center mt={10}>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SiteDetailComponent
          siteId={siteId as string}
          siteConfig={data.siteConfig}
        />
      )}
    </Layout>
  );
};

export default Site;
