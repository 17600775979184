import React, { FunctionComponent } from "react";

import WeekSchedule from "./WeekSchedule";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const Consumption: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  return (
    <div>
      <WeekSchedule siteId={siteId} uiVersion={uiVersion} />
    </div>
  );
};

export default Consumption;
