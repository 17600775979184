import React, { FunctionComponent, useRef, SVGAttributes, useCallback } from "react";
import { select, ScaleContinuousNumeric, timeFormat } from "d3";

import { IPlotData } from "../types"


interface IProps extends SVGAttributes<SVGPathElement> {
  data: IPlotData[];
  scaleX: ScaleContinuousNumeric<number, number>;
  scaleY: ScaleContinuousNumeric<number, number>;
}


const Label: FunctionComponent<IProps> = ({
  data,
  scaleX,
  scaleY,
  className = "label",
  stroke = null,
  strokeWidth = 1,
  fill = "none",
  color = "none",
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const svgEl = select(svgRef.current);

  const labelEls = svgEl
    .attr("class", className)
    .selectAll<SVGRectElement, IPlotData>(`.${className}`)
    .data(data.filter(d => d.y !== null))
    .join("g")
      .attr("class", className)
      .attr("transform", d => `translate(${scaleX(d.x as number)}, ${scaleY(d.y as number) - 10})`)
      ;

  labelEls
    .selectAll<SVGRectElement, IPlotData>(".background")
    .data(d => [{ width: 70, height: 33 }])
    .join("rect")
      .attr("class", "background")
      .attr("fill", fill)
      .attr("width", d => d.width)
      .attr("height", d => d.height)
      .attr("x", 0)
      .attr("y", d => -d.height)
      ;

  const timeFromMinutes = useCallback((minutes: number): string => {
    const time = new Date();

    time.setHours(0);
    time.setMinutes(minutes);

    return timeFormat("%H:%M")(time);
  }, []);

  labelEls
    .selectAll<SVGTextElement, IPlotData>(".power")
    .data(d => [{ power: `${d.y} kW` }])
    .join("text")
      .attr("class", "power")
      .attr("fill", color)
      .attr("font-size", 16)
      .attr("font-weight", "bold")
      .attr("x", 4)
      .attr("y", -4)
      .text(d => d.power)
      ;

  labelEls
    .selectAll<SVGTextElement, IPlotData>(".time")
    .data(d => [{ time: `${timeFromMinutes(d.x)}` }])
    .join("text")
      .attr("class", "time")
      .attr("fill", color)
      .attr("font-size", 12)
      .attr("x", 4)
      .attr("y", -19)
      .text(d => d.time)
      ;

  return (
    <>
      <g ref={svgRef} {...rest} />
    </>
  )
}

export default Label;
