import React, { FunctionComponent } from "react";
import { Badge, Box, Skeleton } from "@chakra-ui/react";

interface IProps {
  state?: number;
}

const STATUS = {
  NONE: 0,
  OK: 1,
}

const StatusBadge: FunctionComponent<IProps> = ({ state }) => {
  switch (state) {
    case STATUS.NONE:
      return (
        <Badge variant="solid" colorScheme={"yellow"}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    case STATUS.OK:
      return (
        <Badge variant="solid" colorScheme={"green"}>
          <Box display="inline-block" w="2" h="3" />
        </Badge>
      )
    default:
      return (
        <Skeleton minW={5} w={10} h={3} />
      )
  }
};

export default StatusBadge;
