import React, { FunctionComponent } from "react";
import { Stack, Box, IconButton, Text, Skeleton } from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

import { IDayDef, TDayState } from "./types";
import { MAX_TIMED_PEAK_DAY_RECORDS } from "common/consts";

interface IProps {
  isLoading: boolean;
  dayDef: IDayDef;
  dayState: TDayState;
  onEdit: (dayId: string, frameIndex: number) => void;
  onAdd: (dayId: string) => void;
}

const DayLine: FunctionComponent<IProps> = ({
  isLoading,
  dayDef,
  dayState,
  onEdit,
  onAdd,
}) => {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      borderBottom="1px solid"
      borderColor="gray.200"
      pb={2}
    >
      <Text
        w={20}
        h={20}
        textAlign="center"
        fontWeight="bold"
        borderRight="1px solid"
        borderColor="gray.200"
      >
        {dayDef.abbr}
      </Text>
      <Stack direction="row">
        {isLoading ? (
          <Skeleton w={40} h={20} />
        ) : (
          dayState.map((ts, i) => {
            return (
              <Stack
                w={40}
                direction="row"
                // key={`${ts.hour}_${ts.minute}_${ts.limit}`}
                key={i}
                alignItems="center"
              >
                <Stack direction="column" mr={2}>
                  <Text>
                    {ts.hour}:{String(ts.minute).padStart(2, "0")}
                  </Text>
                  <Text>{ts.energy} kWh</Text>
                </Stack>
                <Box>
                  <IconButton
                    size="sm"
                    icon={<EditIcon />}
                    aria-label="Edit"
                    onClick={() => {
                      onEdit(dayDef.id, i);
                    }}
                  />
                </Box>
              </Stack>
            );
          })
        )}
      </Stack>
      <Box>
        <IconButton
          icon={<AddIcon />}
          aria-label="Add"
          colorScheme="blue"
          onClick={() => {
            onAdd(dayDef.id);
          }}
          isDisabled={dayState.length >= MAX_TIMED_PEAK_DAY_RECORDS}
        />
      </Box>
    </Stack>
  );
};

export default DayLine;
