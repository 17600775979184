import React, { FunctionComponent } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import times from "ramda/src/times";

import { SiteConfig_siteConfig_pvConf } from "graphql/generated";
import InverterCard from "./InverterCard";
// import { useGlobalState } from "common/global-state";
import { useLiveMeasurements } from "scenes/site/hooks";
// import {
//   PvInvMeasurementBoard,
//   PvInvMeasurementBoardVariables,
// } from "graphql/generated";
// import { PV_INV_MEASUREMENT_BOARD_SUBSCRIPTION } from "graphql/subscriptions";

interface IProps {
  siteId: string;
  pvConf: SiteConfig_siteConfig_pvConf;
  basePath: string;
}

const InverterList: FunctionComponent<IProps> = ({
  siteId,
  pvConf,
  basePath,
}) => {
  const navigate = useNavigate();
  const { data } = useLiveMeasurements(siteId, "pv");

  return (
    <div>
      <Grid
        templateColumns={`repeat(${pvConf.gridWidth}, 1fr)`}
        templateRows={`repeat(${pvConf.gridHeight}, 1fr)`}
        gap={8}
      >
        {times(
          (y) =>
            times((x) => {
              const card = pvConf.inverters.find(
                (card) => card.x === x && card.y === y
              );

              let content = null;

              if (card) {
                const invData = data[`pv_conv_${card.id}`] || {};

                content = (
                  <InverterCard
                    title={card.id}
                    data={invData}
                    {...(pvConf.stringConf.panels.length && {
                      onClick: () => {
                        navigate(`${basePath}/pv/${card.id}`);
                      },
                    })}
                  />
                );
              }

              return <GridItem key={`${x}_${y}`}>{content}</GridItem>;
            }, pvConf.gridWidth),
          pvConf.gridHeight
        )}
      </Grid>
    </div>
  );
};

export default InverterList;
