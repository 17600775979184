import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import StatsTable from "components/stats-table";
import OnOffStatusBadge from "scenes/site/components/on-off-status-badge";

import { GENSET_STATE } from "./GensetState"


interface IProps {
  data: any;
  inline?: boolean;
}


const GensetIndicators: FunctionComponent<IProps> = ({
  data,
  inline,
}) => {
  const intl = useIntl();

  return (
    <StatsTable
      mb={8}
      inline={inline}
      rows={[
        {
          label: intl.formatMessage({
            id: "genset__running",
            defaultMessage: "Ready",
          }),
          unit: "",
          value: <OnOffStatusBadge state={data?.status === GENSET_STATE.RUNNING} />,
        },
        {
          label: intl.formatMessage({
            id: "genset__starting",
            defaultMessage: "Starting",
          }),
          unit: "",
          value: <OnOffStatusBadge state={data?.status === GENSET_STATE.STARTING} color={"yellow.500"} />,
        },
        {
          label: intl.formatMessage({
            id: "genset__error",
            defaultMessage: "Error",
          }),
          unit: "",
          value: <OnOffStatusBadge state={data?.status === GENSET_STATE.ERROR} color={"red.500"} />,
        },
        {
          label: intl.formatMessage({
            id: "genset__off",
            defaultMessage: "Off",
          }),
          unit: "",
          value: <OnOffStatusBadge state={data?.status === GENSET_STATE.OFF} color={"gray.500"} />,
        },
      ]}
    />
  );
};

export default GensetIndicators;
