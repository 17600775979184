import React, { FunctionComponent, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Box,
  Select,
  Text,
  Input,
  InputGroup,
  InputRightAddon,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import times from "ramda/src/times";
import sortWith from "ramda/src/sortWith";
import ascend from "ramda/src/ascend";

import { IDayDef, TDayState, IFrameState } from "./types";

interface IProps {
  frameIndex: number;
  dayDef: IDayDef;
  dayState: TDayState;
  onClose: () => void;
  onSave: (dayId: string, newFrames: Array<IFrameState>) => void;
}

const EditModal: FunctionComponent<IProps> = ({
  dayDef,
  dayState,
  frameIndex,
  onClose,
  onSave,
}) => {
  const intl = useIntl();
  const [frame, setFrame] = useState<IFrameState>(() =>
    frameIndex < 0
      ? { hour: 12, minute: 0, energy: 100, ds: false }
      : dayState[frameIndex]
  );
  const [isTimeCollision, setTimeCollision] = useState<boolean>(false);

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{dayDef.label}</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl id="time" isRequired isInvalid={isTimeCollision}>
            <FormLabel>
              {intl.formatMessage({
                id: "settings__timed_peak__modal__time",
                defaultMessage: "Time",
              })}
            </FormLabel>

            <HStack>
              <Box flex={1}>
                <Select
                  value={frame.hour}
                  onChange={(event) => {
                    setTimeCollision(false);
                    setFrame((s) => ({
                      ...s,
                      hour: Number.parseInt(event.target.value),
                    }));
                  }}
                >
                  {times(
                    (x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ),
                    24
                  )}
                </Select>
              </Box>
              <Text mx={2} fontSize="xl">
                :
              </Text>
              <Box flex={1}>
                <Select
                  value={frame.minute}
                  onChange={(event) => {
                    setTimeCollision(false);
                    setFrame((s) => ({
                      ...s,
                      minute: Number.parseInt(event.target.value),
                    }));
                  }}
                >
                  {times(
                    (x) => (
                      <option key={x} value={x}>
                        {String(x).padStart(2, "0")}
                      </option>
                    ),
                    60
                  )}
                </Select>
              </Box>
            </HStack>

            <FormErrorMessage>
              {intl.formatMessage({
                id: "settings__timed_peak__modal__time_collision",
                defaultMessage: "Time collision",
              })}
            </FormErrorMessage>
          </FormControl>

          <FormControl id="power" isRequired mt={4}>
            <FormLabel>
              {intl.formatMessage({
                id: "settings__timed_peak__modal__power",
                defaultMessage: "Energy",
              })}
            </FormLabel>

            <InputGroup>
              <Input
                type="number"
                value={frame.energy}
                onChange={(event) => {
                  setFrame((s) => ({
                    ...s,
                    energy: Number.parseInt(event.target.value),
                  }));
                }}
              />
              <InputRightAddon children="kWh" />
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter display="flex">
          {frameIndex >= 0 && (
            <React.Fragment>
              <Button
                variant="solid"
                colorScheme="red"
                mr={3}
                onClick={() => {
                  const newFrames = dayState.slice();

                  newFrames.splice(frameIndex, 1);

                  onSave(dayDef.id, newFrames);
                }}
              >
                {intl.formatMessage({
                  id: "settings__timed_peak__modal__delete",
                  defaultMessage: "Delete",
                })}
              </Button>

              <Box flex={1}></Box>
            </React.Fragment>
          )}

          <Button variant="ghost" mr={3} onClick={onClose}>
            {intl.formatMessage({
              id: "settings__timed_peak__modal___close",
              defaultMessage: "Close",
            })}
          </Button>

          <Button
            colorScheme="blue"
            onClick={() => {
              const frames = dayState.slice();
              const hasCollision = dayState.some(
                (ds, i) =>
                  ds.hour === frame.hour &&
                  ds.minute === frame.minute &&
                  frameIndex !== i
              );

              if (hasCollision) {
                setTimeCollision(true);
                return;
              }

              if (frameIndex < 0) {
                frames.push(frame);
              } else {
                frames[frameIndex] = frame;
              }

              onSave(
                dayDef.id,
                sortWith(
                  [ascend((x) => x.hour), ascend((x) => x.minute)],
                  frames
                )
              );
            }}
          >
            {frameIndex >= 0
              ? intl.formatMessage({
                  id: "settings__timed_peak__modal__edit",
                  defaultMessage: "Edit",
                })
              : intl.formatMessage({
                  id: "settings__timed_peak__modal__add",
                  defaultMessage: "Add",
                })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
