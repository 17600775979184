import React, { FunctionComponent, useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Text,
} from "@chakra-ui/react";

import BaseButton, { BaseButtonProps } from "./BaseButton";


interface IProps extends BaseButtonProps {
  value: string;
}


const FormatButton: FunctionComponent<IProps> = ({
  value,
  isDisabled,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const cardLabel = useCallback((id: string): string => {
    switch (id) {
      case "xls":
        return intl.formatMessage({
          id: "export__format__xls",
          defaultMessage: "XLS",
        });
      case "csv":
        return intl.formatMessage({
          id: "export__format__csv",
          defaultMessage: "CSV",
        });
      default:
        return id;
    }
  }, [intl]);

  return (
    <>
      <BaseButton
        isDisabled={isDisabled}
        title={isDisabled ? intl.formatMessage({
          id: "export__format___disabled",
          defaultMessage: "No more than one subject can be exported into CSV format.",
        }) : undefined}
        {...rest}
      >
        <Text>
          {cardLabel(value)}
        </Text>
        {children}
      </BaseButton>
    </>
  );
};

export default FormatButton;
