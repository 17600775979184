import React, { FunctionComponent, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { SiteConfig_siteConfig_pvConf } from "graphql/generated";
import InverterList from "./InverterList";
import StringDetail from "./StringDetail";

interface IProps {
  siteId: string;
  basePath: string;
  pvConf: SiteConfig_siteConfig_pvConf;
}

const Pv: FunctionComponent<IProps> = ({ siteId, pvConf, basePath }) => {
  const location = useLocation();
  const stringId = useMemo<string | null>(() => {
    const slug = location.pathname.substring(basePath.length);
    const m = slug.match(/pv\/(\w+)/);

    if (m) {
      return m[1];
    }

    return null;
  }, [location, basePath]);

  console.log("stringId", stringId);

  return (
    <div>
      {stringId ? (
        <StringDetail
          siteId={siteId}
          pvConf={pvConf}
          basePath={basePath}
          stringId={stringId}
        />
      ) : (
        <InverterList siteId={siteId} pvConf={pvConf} basePath={basePath} />
      )}
    </div>
  );
};

export default Pv;
