// import * as React from "react";
import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";


export const theme = extendTheme({
  colors: {
    gray: {
      950: "#0f1117",
    },
    enposol: {
      50: "#effff4",
      100: "#c1ecce",
      200: "#8ad9a1",
      300: "#61ce81",
      400: "#3ab860",
      500: "#29984B",
      600: "#237d3e",
      700: "#206c37",
      800: "#1b542c",
      900: "#174525",
      950: "#0d2915",
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode("white", "gray.950")(props),
        color: mode("gray.800", "gray.200")(props),
      },
    }),
  },
});
