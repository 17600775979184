import React, { useEffect, useState } from "react";
import { Table, Tbody } from "@chakra-ui/react";

import isEmpty from "ramda/src/isEmpty";
import equals from "ramda/src/equals";

import { IItem, TValue } from "./types";
import SettingsRow from "./SettingsRow";
import { usePrevious } from "common/hooks";


interface SettingsTableProps {
  items: Array<IItem>;
  currentState: Record<string, TValue>;
  originalState: Record<string, TValue> | null;
  setValue: (itemId: string) => <T>(value: T | ((value: T) => T)) => void;
}

interface SettingsTableExtendedProps extends SettingsTableProps {
  isDisabled?: boolean;
  variant?: "compact" | undefined;
}

interface SettingsTableHookResult {
  state: Record<string, TValue>;
  tableProps: SettingsTableProps;
  renewState: Function;
}


export const useSettingsTable = (
  items: Array<IItem>,
  values: any
): SettingsTableHookResult => {
  const [currentState, setCurrentState] = useState<Record<string, TValue>>(() =>
    Object.fromEntries(items.map((x) => [x.id, undefined]))
  );
  const [originalState, setOriginalState] = useState<Record<string, TValue> | null>(null);

  const prevValues = usePrevious(values);

  useEffect(() => {
    if (!values || isEmpty(values)) {
      return;
    }

    if (equals(values, prevValues)) {
      return;
    }

    const newState = Object.fromEntries(
      Object.entries(values).map(([key, val]) => {
        return [
          key,
          typeof currentState[key] === "undefined" ? val : currentState[key],
        ];
      })
    ) as Record<string, TValue>;

    setCurrentState(newState);

    if (!originalState) {
      setOriginalState(newState);
    }
  }, [values, currentState, originalState, prevValues]);

  const setValue = (itemId: string) => {
    return (value: any | ((value: any) => any)) => {
      if (typeof value === "function") {
        setCurrentState((s) => ({ ...s, [itemId]: value(s[itemId]) }));
      } else {
        setCurrentState((s) => ({ ...s, [itemId]: value }));
      }
    };
  };

  return {
    state: currentState,
    tableProps: { items, currentState, originalState, setValue },
    renewState: setOriginalState,
  };
};


export const SettingsTable: React.FC<SettingsTableExtendedProps> = ({
  items,
  currentState,
  originalState,
  setValue,
  variant,
  isDisabled = false,
}) => {
  return (
    <Table size="sm">
      <Tbody>
        {items.map((item) => (
          <SettingsRow
            key={item.id}
            item={item}
            variant={variant}
            currentValue={currentState[item.id]}
            originalValue={originalState ? originalState[item.id] : null}
            setValue={setValue(item.id)}
          />
        ))}
      </Tbody>
    </Table>
  );
};
