import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useToasts } from "common/toasts";
import { useGlobalState } from "common/global-state";

import { useMutation } from "@apollo/client";
import { USER_PREFERENCE_MUTATION } from "graphql/mutations";
import { USER_PREFERENCE_DEFAULTS } from "common/user-preferences/defaults";


type UserPreferenceValue = string | number | boolean | null | undefined;
type SetValue = (value: UserPreferenceValue) => Promise<void>;


export function useUserPreference<T extends UserPreferenceValue>(name: string): [ T, SetValue ] {
  const intl = useIntl();
  const { user } = useGlobalState();
  const value = (user?.preferences as unknown as Record<string, UserPreferenceValue>)[name] as T;
  const defaultValue = (USER_PREFERENCE_DEFAULTS as unknown as Record<string, UserPreferenceValue>)[name] as T;
  const [ state, setState ] = useState<T>(defaultValue);
  const [ storeUserPreference ] = useMutation(USER_PREFERENCE_MUTATION);
  const { pushWarningToast } = useToasts();

  const setUserPreference = useCallback((name: string, value: UserPreferenceValue) => {
    storeUserPreference({
      variables: {
        name: name,
        value: value,
      },
    })
      .catch(() => {
        pushWarningToast(
          intl.formatMessage({
            id: "user_preference__save_error",
            defaultMessage: "There was a problem saving your user preference",
          })
        );
      })
      ;
  }, [intl, pushWarningToast, storeUserPreference]);

  const setValue: SetValue = useCallback(
    async (value) => {
      setState(value as T);
      setUserPreference(name, value);
    },
    [name, setUserPreference]
  );

  useEffect(() => {
    setState(value);
  }, [name, value])

  return [ state, setValue ];
}
