import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  FormControl,
  HStack,
  FormLabel,
  Switch,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";

interface IProps {
  id: string;
  title: string;
  isChecked: boolean;
  isEnabled: boolean;
  isAllowed: boolean;
  isLoading: boolean;
  onSwitch: (checked: boolean) => void;
}

const ControlSwitch: FunctionComponent<IProps> = ({
  id,
  title,
  isChecked,
  isEnabled,
  isAllowed,
  isLoading,
  children,
  onSwitch,
}) => {
  isEnabled = isEnabled && isAllowed;

  const intl = useIntl();
  const bg = useColorModeValue(
    isEnabled ? "gray.200" : "gray.100",
    isEnabled ? "gray.800" : "gray.900"
  );
  const fg = useColorModeValue(
    isEnabled ? "gray.800" : "gray.400",
    isEnabled ? "gray.200" : "gray.600"
  );

  return (
    <Box
      w={"full"}
      p={6}
      bg={bg}
      boxShadow={"md"}
      rounded={"full"}
      overflow={"hidden"}
      title={isAllowed ? undefined : intl.formatMessage({
        defaultMessage: "Insufficient permissions",
        id: "site__dashboard__controls___not-permitted",
      })}
    >
      <FormControl display="flex" alignItems="center">
        <HStack justifyContent={"space-between"} w="full">
          <FormLabel
            htmlFor={id}
            color={fg}
            mb={0}
          >
            {title}
          </FormLabel>

          {isEnabled && <div>{children}</div>}
          {isLoading ? (
            <Spinner />
          ) : (
            <Switch
              id={id}
              size="lg"
              isChecked={isChecked}
              isDisabled={!isEnabled}
              onChange={(event) => {
                onSwitch(event.target.checked);
              }}
            />
          )}
        </HStack>
      </FormControl>
    </Box>
  );
};

export default ControlSwitch;
