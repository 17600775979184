import React from "react";

import {
  IconButton,
  ButtonProps,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";


interface ResetButtonProps extends ButtonProps {
  showIf: boolean;
  title: string;
  ariaLabel: string;
  onClick: () => void;
}


const ResetButton: React.FC<ResetButtonProps> = ({
  showIf,
  title,
  ariaLabel,
  onClick,
  ...rest
}) => {
  return showIf ? (
    <IconButton
      icon={<RepeatIcon />}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      variant="ghost"
      {...rest}
    />
  ) : (
    <IconButton
      isDisabled={true}
      sx={{ cursor: 'initial !important' }}
      title=""
      aria-label={ariaLabel}
      variant="ghost"
      {...rest}
    />
  );
}

export default ResetButton;
