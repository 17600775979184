import React, { FunctionComponent } from "react";

import {
  Box,
  Stack,
} from "@chakra-ui/react";

import GensetIndicators from "./GensetIndicators"


interface IProps {
  data: any;
}


const GensetStats: FunctionComponent<IProps> = ({ data }) => {
  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <GensetIndicators data={data} />
      </Box>
    </Stack>
  );
};

export default GensetStats;
