import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
// import { DashboardMeasurement_dashboardMeasurement_converter } from "graphql/generated";

interface IProps {
  baseUrl: string;
  data: any;
  config: SiteConfig_siteConfig_dashboardConf_components;
}

const ConverterCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__converter____title",
        defaultMessage: `{count, plural,
          one {Converter}
          other {Converters}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      image={"/images/svg/menic.svg"}
      onClick={() => {
        navigate(`${baseUrl}/converter${config.id ? `/${config.id}` : ''}`);
      }}
    >
        <StatsTable
          inline
          rows={[
            {
              label: intl.formatMessage({
                id: "dashboard__converter__power_output",
                defaultMessage: "Power output",
              }),
              unit: "kW",
              decimals: 2,
              value: data?.power_output,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__converter__cos",
                defaultMessage: "Power factor",
              }),
              unit: "",
              decimals: 2,
              value: data?.cos_phi,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__converter__reactive_power",
                defaultMessage: "Reactive power",
              }),
              unit: "kVAR",
              decimals: 1,
              value: data?.reactive_power,
            },
            {
              label: intl.formatMessage({
                id: "dashboard__converter__frequency",
                defaultMessage: "Frequency",
              }),
              unit: "Hz",
              decimals: 1,
              value: data?.frequency,
            },
          ]}
        />
    </BaseCard>
  );
};

export default ConverterCard;
