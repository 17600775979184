import React, { FunctionComponent } from "react";
import { Flex, useColorMode } from "@chakra-ui/react";

import { envVars } from "common/env";
import { SiteConfig_siteConfig_chartsConf } from "graphql/generated";

interface IProps {
  chartsConf: SiteConfig_siteConfig_chartsConf;
}

const Charts: FunctionComponent<IProps> = ({ chartsConf }) => {
  const { colorMode } = useColorMode();

  const firstDashboard = chartsConf.dashboard.sources[0];

  return (
    <Flex flexDirection={"column"} flexGrow={1}>
      <iframe
        title="charts"
        width="100%"
        src={`${envVars.CHARTS_API_URL}${firstDashboard?.uri}&theme=${colorMode}&kiosk`}
        style={{ flexGrow: 1 }}
      />
    </Flex>
  );
};

export default Charts;
