import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Table, Tbody, Tr, Thead, Th } from "@chakra-ui/react";

import Consumer from "./Customer";
import { ITradingConsumer } from ".";


interface IProps {
  // siteId: string;
  uiVersion?: string | null;
  data: ITradingConsumer[];
  setConsumer: (index: number, consumers: ITradingConsumer) => void;
}


const Consumers: FunctionComponent<IProps> = ({
  // siteId,
  uiVersion,
  setConsumer,
  data,
}) => {
  const intl = useIntl();
  // const [consumers, setConsumers] = useState<ITradingConsumer[]>();

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            {/* <Th>#</Th> */}
            <Th w="80%">
              {/* {intl.formatMessage({
                id: "settings__trading__consumer_name",
                defaultMessage: "Name",
              })} */}
            </Th>
            <Th>
              {intl.formatMessage({
                id: "settings__trading__consumer_price_electro",
                defaultMessage: "Electricity price",
              })}
            </Th>
            <Th>
              {intl.formatMessage({
                id: "settings__trading__consumer_price_heat",
                defaultMessage: "Heat price",
              })}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          { data.map(consumer => (
            <Consumer data={consumer} setConsumer={setConsumer} uiVersion={uiVersion} />
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default Consumers;
