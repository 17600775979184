import React, { FunctionComponent, useContext, useMemo } from "react";
import { axisTop, extent, axisBottom, axisLeft, ScaleContinuousNumeric, timeFormat } from "d3";

import { ChartContext, makeScale } from "..";
import { AxisBase } from "../base"
import { IDataProps, IPlotData, ISvgStyleProps } from "../types"


interface IFunnyAxisProps extends ISvgStyleProps, IDataProps {
  data: IPlotData[];
  tickPadding?: number;
  tickSize?: number;
}

interface IAxisXProps extends ISvgStyleProps, IDataProps {
  y?: number;
  ticks?: number[];
  tickPadding?: number;
  tickSize?: number;
  scale: ScaleContinuousNumeric<number, number>;
}

interface IAxisYProps extends ISvgStyleProps, IDataProps {
  x?: number;
  ticks?: number[];
  tickPadding?: number;
  tickSize?: number;
  scale: ScaleContinuousNumeric<number, number>;
}


export const FunnyAxis: FunctionComponent<IFunnyAxisProps> = ({
  data,
  domainX = extent<number>(data.map(d => d.x)) as number[],
  rangeX,
  offsetX,
  tickPadding = -20,
  tickSize = 5,
  ...rest
}) => {
  const chart = useContext(ChartContext);
  const scaleX = useMemo(() => makeScale("x", chart, domainX, rangeX, offsetX), [chart, domainX, rangeX, offsetX]);
  const generator = useMemo(() => axisTop<number>(scaleX)
                                  .ticks(data.length)
                                  .tickPadding(tickPadding)
                                  .tickSize(tickSize)
                            , [scaleX, data.length, tickPadding, tickSize]);
  return (
    <>
      <AxisBase
        className="axis"
        transform={`translate(0,${chart.height + 4})`}
        {...{
          generator,
          // data,
        }}
        {...rest}
      />
    </>
  )
}


export const AxisX: FunctionComponent<IAxisXProps> = ({
  y = 0,
  ticks = [],
  domainX = extent<number>(ticks) as number[],
  rangeX,
  offsetX,
  scale: scaleX,
  tickPadding = 0,
  tickSize = 10,
  ...rest
}) => {
  const chart = useContext(ChartContext);
  const computedScaleX = useMemo(() => makeScale("x", chart, domainX, rangeX, offsetX), [chart, domainX, rangeX, offsetX]);
  const generator = useMemo(() => axisBottom<number>(scaleX || computedScaleX)
                                  // .ticks(5)
                                  .tickValues(ticks)
                                  .tickFormat((d) => timeFormat("%H:%M")(new Date(2000, 0, 1, 0, d)))
                                  .tickPadding(tickPadding)
                                  .tickSize(tickSize)
                            , [scaleX, computedScaleX, ticks, tickPadding, tickSize]);

  return (
    <>
      <AxisBase
        className = "axis"
        transform={`translate(0,${y})`}
        {...{
          generator,
          // data,
        }}
        {...rest}
      />
    </>
  )
}


export const AxisY: FunctionComponent<IAxisYProps> = ({
  x = 0,
  ticks = [],
  domainY = extent<number>(ticks) as number[],
  rangeY,
  offsetY,
  scale: scaleY,
  tickPadding = 5,
  tickSize = 10,
  ...rest
}) => {
  const chart = useContext(ChartContext);
  const computedScaleX = useMemo(() => makeScale("x", chart, domainY, rangeY, offsetY), [chart, domainY, rangeY, offsetY]);
  const generator = useMemo(() => axisLeft<number>(scaleY || computedScaleX)
                                  .tickPadding(tickPadding)
                                  .tickSize(tickSize)
                            , [scaleY, computedScaleX, tickPadding, tickSize]);

  return (
    <>
      <AxisBase
        className = "axis"
        transform={`translate(${x},0)`}
        {...{
          generator,
          // data,
        }}
        {...rest}
      />
    </>
  )
}
