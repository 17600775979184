import React, { FunctionComponent } from "react";
import { Stack, Box, Grid, GridItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import times from "ramda/src/times";

import StatsTable from "components/stats-table";
import RackPreview from "./RackPreview";
// import { BATTERY_MEASUREMENT_DETAIL_SUBSCRIPTION } from "graphql/subscriptions";
// import {
//   BatteryMeasurementDetail,
//   BatteryMeasurementDetailVariables,
// } from "graphql/generated";
import { useLiveMeasurements } from "scenes/site/hooks";
import { SiteConfig_siteConfig_batteryConf } from "graphql/generated";
import IframeChart from "components/iframe-chart";
import { displayId } from "common/stringUtils"

interface IProps {
  siteId: string;
  basePath: string;
  batteryConf: SiteConfig_siteConfig_batteryConf;
}

const Summary: FunctionComponent<IProps> = ({
  siteId,
  basePath,
  batteryConf,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  // FIXME: allow more batteries in the future
  const batteryId = "0";

  const { data } = useLiveMeasurements(siteId, "battery");

  return (
    <Stack direction="row" spacing={8}>
      <Box flex={1}>
        <Grid
          gap={4}
          gridTemplateColumns="1fr"
          gridTemplateRows={times(
            () => "1fr",
            batteryConf.uiRackColumnLimit
          ).join(" ")}
          gridAutoColumns="1fr"
          gridAutoFlow="column"
        >
          {batteryConf.racks.map((rack) => {
            const rackData = data[`rack_${batteryId}_${rack.id}`]

            return (
              <GridItem
                key={rack.id}
              >
                <RackPreview
                  name={`Rack ${displayId(rack.id)}`}
                  isConnected={rackData?.rack_connected || false}
                  onClick={() => navigate(`${basePath}/battery/${rack.id}`)}
                />
              </GridItem>
            );
          })}
        </Grid>
      </Box>

      <Box flex={1}>
        <StatsTable
          rows={[
            {
              label: intl.formatMessage({
                id: "battery__batt_connected",
                defaultMessage: "Battery connected",
              }),
              // type: "boolean",
              value: data.battery?.battery_connected,
            },
            {
              label: intl.formatMessage({
                id: "battery__soc",
                defaultMessage: "SoC",
              }),
              // type: "number",
              unit: "%",
              decimals: 2,
              value: data.battery?.soc,
            },
            {
              label: intl.formatMessage({
                id: "battery__actual_kwh",
                defaultMessage: "Current charge of the battery",
              }),
              unit: "kWh",
              decimals: 1,
              // type: "number",
              value: data.battery?.energy,
            },
            {
              label: intl.formatMessage({
                id: "battery__soh",
                defaultMessage: "SoH",
              }),
              unit: "%",
              decimals: 2,
              // type: "number",
              value: data.battery?.soh,
            },
            {
              label: intl.formatMessage({
                id: "battery__charge_curr_limit",
                defaultMessage: "Charging current limit",
              }),
              unit: "A",
              decimals: 1,
              // type: "number",
              value: data.battery?.charge_current_limit,
            },
            {
              label: intl.formatMessage({
                id: "battery__discharge_curr_limit",
                defaultMessage: "Discharging current limit",
              }),
              unit: "A",
              decimals: 1,
              // type: "number",
              value: data.battery?.discharge_current_limit,
            },
            {
              label: intl.formatMessage({
                id: "battery__current",
                defaultMessage: "Battery current",
              }),
              unit: "A",
              decimals: 1,
              // type: "number",
              value: data.battery?.current,
            },
            {
              label: intl.formatMessage({
                id: "battery__voltage",
                defaultMessage: "Battery voltage",
              }),
              unit: "V",
              decimals: 1,
              // type: "number",
              value: data.battery?.voltage,
            },
            {
              label: intl.formatMessage({
                id: "battery__avg_rack_tmp",
                defaultMessage: "Average battery rack temperature",
              }),
              unit: "°C",
              decimals: 1,
              // type: "number",
              value: data.battery?.avg_batt_rack_temp,
            },
          ]}
        />
      </Box>

      <Box flex={1}>
        <IframeChart
          title={intl.formatMessage({
            id: "battery__chart__avg_tmp_racks",
            defaultMessage: "Average racks temperature chart",
          })}
          chart={batteryConf.avgTmpChart}
        />
        <IframeChart
          title={intl.formatMessage({
            id: "battery__chart__soc",
            defaultMessage: "SoC chart",
          })}
          chart={batteryConf.socChart}
        />
      </Box>
    </Stack>
  );
};

export default Summary;
