import React, { FunctionComponent } from "react";
import {
  HStack,
  Stat,
  Flex,
  Box,
  StatLabel,
  Button,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

interface IProps {
  name: string;
  isConnected: boolean;
  onClick: () => void;
}

const RackPreview: FunctionComponent<IProps> = ({
  name,
  isConnected,
  onClick,
}) => {
  const intl = useIntl();

  return (
    <Stat
      w="full"
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"8px solid"}
      borderColor={useColorModeValue("gray.400", "gray.600")}
      rounded={"lg"}
      width={300}
      cursor="pointer"
      onClick={onClick}
    >
      <Flex justify={"space-between"} align={"center"}>
        <HStack>
          <StatLabel fontWeight={"medium"} isTruncated>
            {name}
          </StatLabel>
          {/* <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            hodnota?
          </StatNumber> */}
          <Button size="sm">
            {intl.formatMessage({
              id: "battery__rack_detail",
              defaultMessage: "Detail",
            })}
          </Button>
        </HStack>

        <Box>
          {isConnected ? (
            <Badge variant="solid" colorScheme="green">
              {intl.formatMessage({
                id: "battery__rack_connected",
                defaultMessage: "Connected",
              })}
            </Badge>
          ) : (
            <Badge variant="solid" colorScheme="yellow">
              {intl.formatMessage({
                id: "battery__rack_disconnected",
                defaultMessage: "Disconnected",
              })}
            </Badge>
          )}
        </Box>
      </Flex>
    </Stat>
  );
};

export default RackPreview;
