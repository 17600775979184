import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useToasts } from "common/toasts";

import {
  GetLoadLevelingSettings,
  GetLoadLevelingSettingsVariables,
} from "graphql/generated";
import { GET_LOAD_LEVELLING_SETTINGS_QUERY } from "graphql/queries";

import SettingsFormPage from "../../components/SettingsFormPage";

interface IProps {
  siteId: string;
  uiVersion?: string | null;
}

const LoadLeveling: FunctionComponent<IProps> = ({
  siteId,
  uiVersion,
}) => {
  const intl = useIntl();
  const { pushErrorToast } = useToasts();

  const { data, loading, error } = useQuery<GetLoadLevelingSettings, GetLoadLevelingSettingsVariables>(
    GET_LOAD_LEVELLING_SETTINGS_QUERY,
    {
      variables: { siteId },
      errorPolicy: "all",
    }
  );

  React.useEffect(() => {
    if (error) {
      pushErrorToast(String(error));
    }
  }, [error, pushErrorToast])

  return (
    <SettingsFormPage
      siteId={siteId}
      settingsId="loadLeveling"
      settings={data?.getLoadLevelingSettings}
      isLoading={loading}
      guideId="load-leveling"
      uiVersion={uiVersion}
      items={[
        {
          id: "socMaxLL",
          name: intl.formatMessage({
            id: "settings__load_leveling__soc_max_for_ll",
            defaultMessage: "SoC max for Load leveling",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "socMinLL",
          name: intl.formatMessage({
            id: "settings__load_leveling__soc_min_for_ll",
            defaultMessage: "SoC min for Load leveling",
          }),
          type: "float",
          unit: "%",
        },
        {
          id: "reservedPower",
          name: intl.formatMessage({
            id: "settings__load_leveling__reserve_consumption",
            defaultMessage: "Reserved power",
          }),
          type: "float",
          unit: "kW",
        },
      ]}
    />
  );
};

export default LoadLeveling;
